import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "react-bootstrap";
import { createColumnHelper } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBox, faCloudDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import PagedFilterTable from "../../components/table";
import { toGuid } from "../../app/common/helper/Guid";
import IPackageVersionInfo from "../../models/PackageDetail";
import { ServiceManager } from "../../services/ServiceManager";
import { format } from "date-fns";
import { APIStatusCode } from "../../app/enums";
import { SOMETHING_WENT_WRONG } from "../../app/constants";

export default function PackageDetail(props: any) {
    const navigate = useNavigate();
    const { PackageName, PackageId } = useParams();
    const [productID, setProductID] = useState<string>();
    const [productName, setProductName] = useState<string>();
    const [tableJobs, setTableJobs] = useState([]);
    const packageIdGUID = toGuid(PackageId ?? "");
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [downloadVersionNumber, setDownloadVersionNumber] = useState("");

    const columnHelper = createColumnHelper<IPackageVersionInfo>();
    const columns = [
        columnHelper.accessor('versionNumber', {
            header: () => <span>Versions</span>,
            size: 60
        }),
        columnHelper.accessor('dateReleased', {
            header: () => <span>Release Date</span>,
            size: 30,
            cell: ({ row }) => (
                format(new Date(row.original.dateReleased), "dd-MM-yyyy")
            )
        }),
        columnHelper.accessor("download", {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                <div className="m-auto">
                    <FontAwesomeIcon
                        className={"cursor-pointer" + ((downloadVersionNumber === row.original.versionNumber && downloadInProgress) ? " fa-spin" : "")}
                        icon={(downloadVersionNumber === row.original.versionNumber && downloadInProgress) ? faSpinner : faCloudDownload}
                        title="Download package file"
                        size="lg"
                        onClick={() => {
                            downloadPackage(row.original.versionNumber);
                        }}
                    />
                </div>
            ),
            size: 10,
        }),
    ];

    async function fetchData(options: { searchValue: string }) {
        const packagesResponse = await ServiceManager.PackageService.GetPackageInfo(packageIdGUID, true, options.searchValue);
        setProductID(packagesResponse.data.productID)
        setProductName(packagesResponse.data.productName)
        let versions = packagesResponse.data.versions;
        setTableJobs(versions);
        return versions;
    }

    async function downloadPackage(versionNumber: string) {
        setDownloadVersionNumber(versionNumber);
        setDownloadInProgress(true);
        const packagesResponse = await ServiceManager.PackageService.GetDownloadLinkAsync(packageIdGUID, versionNumber);
        if (packagesResponse && packagesResponse.response) {
            let error = ""
            if (packagesResponse.response.status === APIStatusCode.NotFound) {
                error = "The file with the supplied package ID does not exist.";
            }
            else if (packagesResponse.response.status === APIStatusCode.Forbidden) {
                error = "The establishment does not exist or is disabled/inactive or you are not eligible to download the file.";
            }
            else if (packagesResponse.message) {
                error = packagesResponse.message
            }
            else {
                error = SOMETHING_WENT_WRONG;
            }
            ServiceManager.ToastServer.showError(error);
        }
        else {
            try {
                window.open(packagesResponse.data.link, "_self");
                window.URL.revokeObjectURL(packagesResponse.data.link);
                setDownloadInProgress(false);
            } catch (error: any) {
                ServiceManager.ToastServer.showError(error);

            }
        }

    }

    return (
        <Layout
            title={PackageName ?? ""}
            subTitle="Versions"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }>
            <React.Fragment>
                <div className="margin-left10 b-2 margin-bottom20">
                    <Stack direction="horizontal" gap={3} className="align-items-start">
                        <div className="d-flex justify-content-center align-items-center establishment-icon blue-bg rounded-4 mt-3">
                            <div className="d-flex justify-content-center align-items-center establishment-icon-inner crbc-icon white-background-shadow rounded-3">
                                <FontAwesomeIcon icon={faBox} className="bi" size="lg" />
                            </div>
                        </div>
                        <Stack className="mt-4">
                            <h4 className="font-heading mb-1 fw-bold">{productName}</h4>
                            <span className="font-primary font-family14">
                                ID#{productID}
                            </span>
                        </Stack>
                    </Stack>

                    <div className="mt-4">
                        <Stack direction="horizontal" gap={5} >
                            <Stack direction="horizontal" className="fw-bold">Package ID</Stack><Stack>{PackageId}</Stack>
                        </Stack>
                        <Stack direction="horizontal" gap={3} className="mt-3">
                            <Stack direction="horizontal" className="fw-bold me-1">Package Name</Stack><Stack>{PackageName}</Stack>
                        </Stack>
                    </div>

                </div>
                <div id="packageVersionDetail">
                    <PagedFilterTable
                        tableKey={"packageID-" + packageIdGUID}
                        columns={columns}
                        data={tableJobs}
                        usePagination={false}
                        useSearch={true}
                        pageRecords={20}
                        fnFetchData={fetchData}
                    />
                </div>
            </React.Fragment>
        </Layout>
    );
}