import { timeUnit } from "../../enums";


 export function RemoveUrlFilter(filter: string) {
        let queryparams = window.location.search.split('&')
        var regExe = filter.replace(new RegExp("/^" + filter + ".*$/"), "replacement");
        let newURl = window.location.origin + window.location.pathname + "?";
        let value = queryparams.filter((current) => !current.match(regExe));
        for (var index = 0; index < value.length; index++) {
            if (index === 0) {
                newURl += value[index].replace("?", "");
            } else {
                newURl += "&" + value[index];
            }
        }
        window.history.pushState({ path: newURl }, '', newURl);
    }

     export function AddUrlFilter(filter: string, Value:any) {
        let queryparams = window.location.search.split('&')
        var regExe = filter.replace(new RegExp("/^" + filter + ".*$/"), "replacement");
        let newURl = window.location.origin + window.location.pathname + "?";
        let value = queryparams.filter((current) => !current.match(regExe));
        for (var index = 0; index < value.length; index++) {
            if (index === 0) {
                newURl += value[index].replace("?", "");
            } else {
                newURl += "&" + value[index]
            }
        }
        if(newURl === window.location.origin+"/message?"){
            newURl += filter+"="+Value;
        }else{
            newURl += "&"+filter+"="+Value;
        }
        
        window.history.pushState({ path: newURl }, '', newURl);
    }

 export function GetDateTimer(time: number, timerUnit: any) {
  let description ;
    if(time === 12 && timerUnit === timeUnit.hour){
     description = "Last 12 hours" ;
    }
    else if(time === 1 && timerUnit ===  timeUnit.day){
     description = "Last day" ;
    }else if(time === 3 &&  timerUnit ===  timeUnit.day){
     description = "Last 3 days" ;
    }else if(time === 7 &&  timerUnit ===  timeUnit.day){
     description = "Last week" ;
    }else if(time === 14 && timerUnit ===  timeUnit.day){
     description = "Last 2 weeks" ;
    }else if(time === 1 && timerUnit ===  timeUnit.month){
     description = "Last month" ;
    }
    else if(time === 30 &&  timerUnit === timeUnit.minute){
     description = "Last 30 minutes" ;
    }
    else if(time === 1 && timerUnit ===  timeUnit.hour){
     description = "Last hour" ;
    }
    else if(time === 4 && timerUnit ===  timeUnit.hour){
     description = "Last 4 hours" ;
    }
    else if(time === 30 && timerUnit ===  timeUnit.year){
     description = "Custom" ;
    }
    return description;
  }