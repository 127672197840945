import React from "react";
import { ServiceManager } from "../../services/ServiceManager";
import { useQuery } from "@tanstack/react-query";
import Card from 'react-bootstrap/Card';
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import { format, differenceInHours, isToday } from 'date-fns';
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function EstablishmentDevicePackages(props: any) {

    const { isLoading, data } = useQuery(["establishmentDevicePackages-" + props.deviceId], () =>
        ServiceManager.EstablishmentService.GetInstalledPackagesByIds(props.establishmentId, props.deviceId), {
            refetchOnWindowFocus: false
        }
    );
    
    return (
        <React.Fragment>
            {!isLoading && data != null && data.data != null &&
                <div className="container-fluid">
                    <div className="row pb-3">
                    {data.data.map((devicePackage: any) => (
                        <div className="col-sm col-md col-xl-4 col-xxl-3 pt-3">
                            <Card key={devicePackage.packageID}>
                                <Card.Body>
                                    <Stack direction="horizontal" gap={2}>
                                        <Stack className="w-50">
                                            <Stack direction="horizontal">
                                                <Card.Title as="h6" title={devicePackage.packageName} className="mt-1">
                                                    <NavLink style={{ fontWeight: "600" }}
                                                        to={`/product/package/${devicePackage.packageID}/${devicePackage.packageName}`}>
                                                        {devicePackage.packageName}
                                                    </NavLink>
                                                </Card.Title>
                                                {devicePackage.availableVersionNumber && devicePackage.versionNumber !== devicePackage.availableVersionNumber && <FontAwesomeIcon className="ms-3" title="This app has a new version ready to be installed" icon={faCircleExclamation} color="#ECBD00" size="lg" />}
                                            </Stack>
                                            <Card.Subtitle title={`Version ${devicePackage.versionNumber}`}
                                                className="text-muted" style={{ fontSize: "14px", marginTop: "5px" }}>

                                                <Stack direction="horizontal">
                                                    <span>Installed</span>
                                                    <span className="ellipsis ps-5" title={devicePackage.versionNumber}>{devicePackage.versionNumber}</span>
                                                </Stack>
                                                <Stack direction="horizontal">
                                                    <span>Downloaded</span>
                                                    <span className="ellipsis ps-3" title={devicePackage.availableVersionNumber}>&nbsp;{devicePackage.availableVersionNumber}</span>
                                                </Stack>
                                            </Card.Subtitle>
                                        </Stack>

                                        {(() => {
                                            let className = "green";
                                            let bgClassName = "light-green-bg";

                                            if (isToday(new Date(devicePackage.dateHeartbeat))) {
                                                let hoursDifference = differenceInHours(new Date(), new Date(devicePackage.dateHeartbeat));
                                                if(hoursDifference >= 1) {
                                                    className = "amber";
                                                    bgClassName = "amber-bg";
                                                }
                                            }
                                            else {
                                                bgClassName = "red-bg";
                                                className = "red";
                                            }
                                            
                                            return <div className={`d-flex justify-content-center align-items-center heartbeat-icon ${bgClassName} rounded-5`}>
                                                <FontAwesomeIcon icon={faHeartPulse} size="lg" className={className} title={format(new Date(devicePackage.dateHeartbeat), "'Last heartbeat:' MMM do, yyyy @ HH:mm")} />
                                            </div>
                                        })()}
                                    </Stack>
                                </Card.Body>

                                <Card.Footer>
                                    <span className="text-muted">Last Update Attempt:&nbsp;{devicePackage.lastUpdateAttempt ? format(new Date(devicePackage.lastUpdateAttempt), "MMM do, yyyy @ HH:mm") : 'N/A'}</span>
                                </Card.Footer>
                            </Card>
                        </div>
                    ))
                    }
                </div>
                </div>
            }
            {!isLoading && data === undefined &&
                <div className="text-center text-muted"><p>No packages found</p></div>
            }
            {isLoading &&
                <React.Fragment>
                    <div className="w-25 ms-5 mt-2 mb-2">
                        <Skeleton count={3} />
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
}