import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HubButton from "../../components/button";
import Layout from "../layout";
import { faAdd, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PagedFilterTable from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import IAlert from "../../models/Alert";
import { useState } from "react";
import { ServiceManager } from "../../services/ServiceManager";
import { APIStatusCode, AlertFieldType, AlertType } from "../../app/enums";
import CustomModal from "../../components/modal";
import IconElement from "../../components/icon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Stack } from "react-bootstrap";
import HUBSpinner from "../../components/spinner";
import { useNavigate } from "react-router-dom";

export default function Alert() {
    let navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [alertId, setAlertId] = useState(0);
    const [tableJobs, setTableJobs] = useState([]);
    const columnHelper = createColumnHelper<IAlert>();
    const columns = [
        columnHelper.accessor('alertName', {
            header: () => <span>Name</span>
        }),
        columnHelper.accessor('alertType', {
            header: () => <span>Alert Type</span>,
            cell: ({ row }) => (
                <span> {AlertType[row.original.alertType]} </span>
            ),
        }),
        columnHelper.accessor('alertFields', {
            id: "establishment",
            header: () => <span>Establishment(s)</span>,
            cell: ({ row }) => (
                <span> {row.original.alertFields.filter(x => x.fieldType === AlertFieldType.Establishment).map(x => x.fieldValue)} </span>
            ),
        }),
        columnHelper.accessor('alertFields', {
            id: "count",
            header: () => <span>Count</span>,
            cell: ({ row }) => (
                <span> {row.original.alertFields.filter(x => x.fieldType === AlertFieldType.Count).map(x => x.fieldValue)} </span>
            ),
        }),
        columnHelper.accessor('route', {
            header: () => <span>Route</span>,
            cell: ({ row }) => (
                <span> Email </span>
            ),
        }),
        columnHelper.accessor('alertID', {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                <>
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        size="lg"
                        className="me-5 cursor-pointer"
                        onClick={() => {
                            setShowDeleteModal(true); setAlertId(row.original.alertID);
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faPen}
                        className="cursor-pointer"
                        size="lg"
                        onClick={() => { navigate(`/alert/${row.original.alertID}/edit`); }}
                    />
                </>
            ),
        })
    ];

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const alertsResponse = await ServiceManager.AlertService.GetAll(options.pageIndex + 1, options.pageSize);
        setTableJobs(alertsResponse.data);
        return alertsResponse.data;
    }

    const DeleteAlertConfirmationModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.AlertService.DeleteAlert(alertId);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastServer.showError("Unable to delete Alert.");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastServer.showSuccess("Alert deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["alerts"] });
                        setShowDeleteModal(false);
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });

        return (
            <Stack>
                <p className="heading text-center">Delete Alert</p>
                <p className="text-center">Are you sure? This means that the Alert will no longer trigger a notification.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <HubButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={() => setShowDeleteModal(false)} />
                            <HubButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <HUBSpinner />
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout
            title="Alerts"
            button={<HubButton text="Create New Alert" className="btn btn-primary crbc-bg-color" FnOnClick={() => navigate('/alert/create')} icon={<FontAwesomeIcon icon={faAdd} />}></HubButton>}>
            <div className="alert-detail">
                <PagedFilterTable
                    tableKey={"alerts"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={true}
                    useSearch={false}
                    pageRecords={15}
                    fnFetchData={fetchData}
                />
            </div>
            <CustomModal isShow={showDeleteModal} handleClose={() => setShowDeleteModal(false)} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteAlertConfirmationModal />
            </CustomModal>
        </Layout>
    );
};