import React, { useState, useContext } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { ServiceManager } from "../../services/ServiceManager";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTvAlt, faHeartPulse, faChevronDown, faPen, faWarning, faChevronUp, faEdit, faExchange, faCircleInfo, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import EstablishmentDevicePackages from "./device-packages";
import { Button, Stack, Spinner, Container, Row, Col } from "react-bootstrap";
import { format, differenceInHours, isToday } from 'date-fns'
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useForm } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";
import IEstablishment from "../../models/Establishment";
import SimpleTable from "../../components/simpletable";
import CustomModal from "../../components/modal";
import AccordionContext from 'react-bootstrap/AccordionContext';
import { APIStatusCode } from "../../app/enums";
import IconElement from "../../components/icon";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import { Permissions } from "../../app/enums";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../app/constants";
import SearchBar from "../../components/search";
import HUBSpinner from "../../components/spinner";
import IEstablishmentDevice from "../../models/EstablishmentDevice";
import DeviceFilter from "./device-filters";

const ToggleButton = ({ eventKey }: any) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className="d-flex justify-content-center align-items-center toggle-icon gray-bg rounded-5" onClick={decoratedOnClick}>
            {!isCurrentEventKey && <FontAwesomeIcon icon={faChevronDown} size="lg" />}
            {isCurrentEventKey && <FontAwesomeIcon icon={faChevronUp} size="lg" />}
        </div>
    );
};

const DeviceUpdateForm = ({ establishmentId, deviceId, deviceName, handleClose }: any) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (device: object) => {
            return ServiceManager.EstablishmentService.UpdateDevice(establishmentId, deviceId, device);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastServer.showError("Unable to update device");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastServer.showSuccess("Device updated successfully");
                    queryClient.invalidateQueries({ queryKey: [`establishmentDevices-${establishmentId}`] });
                    handleClose();
                }
                else {
                    let errorMessage = data.response.data.errors.errors
                        ? data.response.data.errors.errors
                        : "Unable to update device";
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                }
            }
        }
    });

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => {
        var device = { establishmentId: establishmentId, deviceName: data.deviceName };
        mutation.mutate(device);
    };

    return (
        <Stack>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <p className="heading">Edit Device Name</p>
                <p className="text-muted">Please enter a name for this Device</p>
                <label className="mt-1">Device Name</label>
                <input {...register("deviceName", { required: true })} defaultValue={deviceName} className={`form-control mt-1 ms-0 w-100 ${(errors.deviceName) ? 'is-invalid' : ''}`} />
                {errors.deviceName && <div className="invalid-feedback">
                    Device Name field is required
                </div>}

                {!mutation.isLoading
                    ? (
                        <Stack direction="horizontal" className="mt-3">
                            <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
                                Update
                            </Button>
                        </Stack>
                    ) :
                    (
                        <div className="d-flex justify-content-center mt-3">
                            <Spinner animation="border" variant="info" role="status" ></Spinner>
                        </div>
                    )
                }
            </form>
        </Stack>
    )
}

const EstablishmentList = ({ establishmentData, showDeviceMoveConfirmationModal }: any) => {
    const columnHelper = createColumnHelper<IEstablishment>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => <span>Name</span>,
            size: 25,
            cell: ({ row }) => (
                <a href="/#" onClick={(e) => { e.preventDefault(); showDeviceMoveConfirmationModal(row.original.establishmentID, row.original.name) }}>
                    {row.original.name}
                </a>
            ),
        })
    ];

    let establishments: IEstablishment[] = [];

    if (establishmentData !== null) {
        if (establishmentData.parent !== null) {
            establishments.push(establishmentData.parent);
        }

        if (establishmentData.children !== null) {
            establishmentData.children.forEach(function (establishment: IEstablishment) {
                establishments.push(establishment);
            });
        }
    }

    return (
        <div>
            <SimpleTable
                tableKey={"establishments"}
                columns={columns}
                data={establishments}
            />
        </div>
    )
}

const EstablishmentListHeader = () => {
    return (
        <Stack>
            <p className="heading">Establishments</p>
            <p className="text-muted">Please select an establishment.</p>
        </Stack>
    )
}

const DeviceMoveForm = ({ deviceName, establishmentId, deviceId, newEstablishmentName, newEstablishmentId, handleClose, handleCloseMoveModal }: any) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (device: object) => {
            return ServiceManager.EstablishmentService.UpdateDevice(establishmentId, deviceId, device);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastServer.showError("Unable to move device");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastServer.showSuccess("Device moved successfully");
                    queryClient.invalidateQueries({ queryKey: [`establishmentDevices-${establishmentId}`] });
                    handleClose();
                    handleCloseMoveModal();
                }
                else {
                    let errorMessage = data.response.data.errors.errors
                        ? data.response.data.errors.errors
                        : "Unable to move device";
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                }
            }
        }
    });

    const { handleSubmit } = useForm();
    const onSubmit = (data: any) => {
        var device = { establishmentId: newEstablishmentId, deviceName: deviceName };
        mutation.mutate(device);
    };

    return (
        <Stack>
            <form onSubmit={handleSubmit(onSubmit)} className="form text-center">
                <p className="heading">Move Device</p>
                <p className="text-muted">Are you sure, you want to move {deviceName} to {newEstablishmentName}</p>

                {!mutation.isLoading
                    ? (
                        <Stack direction="horizontal" className="mt-3">
                            <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
                                Proceed
                            </Button>
                        </Stack>
                    )
                    : (
                        <div className="d-flex justify-content-center mt-3">
                            <Spinner animation="border" variant="info" role="status"></Spinner>
                        </div>
                    )
                }
            </form>
        </Stack>
    )
}

interface IEstablishmentDevices {
    establishmentId?: string,
    establishmentData: any,
    startDateTime: Date,
    endDateTime: Date,
    selectedTimer: number;
    timeUnit: any

}

export default function EstablishmentDevices(props: IEstablishmentDevices) {
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showMoveConfirmationModal, setShowMoveConfirmationModal] = useState(false);
    const [currentEstablishmentId, setCurrentEstablishmentId] = useState('');
    const [currentDeviceId, setCurrentDeviceId] = useState('');
    const [currentDeviceName, setCurrentDeviceName] = useState('');
    const [newEstablishmentId, setNewEstablishmentId] = useState('');
    const [newEstablishmentName, setNewEstablishmentName] = useState('');
    const [currentSelectedDevice, setCurrentDevice] = useState<IEstablishmentDevice>(Object);
    const [expandedKey, setExpandedKey] = useState('');
    const [searchValue, setSearchValue] = useState<string>('');

    const [showDeviceDetailModal, setShowDeviceDetailModal] = useState(false);
    const handleCloseDeviceModal = () => setShowDeviceDetailModal(false);
    const [productId, setProductId] = useState('');
    const [installedVersion, setInstalledVersion] = useState('');
    const [availableVersion, setAvailableVersion] = useState('');
    const [dateHeartbeat, setDateHeartbeat] = useState('');
    const [dateLastUpdateAttempt, setDateLastUpdateAttempt] = useState('');

    const handleCloseRenameModal = () => setShowRenameModal(false);
    const handleCloseMoveModal = () => setShowMoveModal(false);
    const handleCloseMoveConfirmationModal = () => setShowMoveConfirmationModal(false);
    const setUpState = (device: IEstablishmentDevice) => {
        setCurrentDeviceId(device.deviceID);
        setCurrentEstablishmentId(device.installedEstablishmentID);
        setCurrentDeviceName(device.deviceName);
        setCurrentDevice(device);
    }
    let navigate = useNavigate();

    async function showDeviceMoveConfirmationModal(establishmentId: any, establishmentName: string) {
        setNewEstablishmentId(establishmentId);
        setNewEstablishmentName(establishmentName);
        setShowMoveConfirmationModal(true);
    }

    function callbackProduct(productId: any) {
        setProductId(productId);
    }

    function callbackAvailableVersion(availableVersion: any) {
        setAvailableVersion(availableVersion);
    }

    function callbackInstalledVersion(installedVersion: any) {
        setInstalledVersion(installedVersion);
    }

    function callbackHeartbeat(dateHeartbeat: any) {
        setDateHeartbeat(dateHeartbeat);
    }

    function callbackLastUpdateAttempt(dateLastUpdateAttempt: any) {
        setDateLastUpdateAttempt(dateLastUpdateAttempt);
    }

    const { isLoading, data } = useQuery([`establishmentDevices-${props.establishmentId}`, searchValue, productId, installedVersion, availableVersion, dateHeartbeat, dateLastUpdateAttempt], () =>
        ServiceManager.EstablishmentService.GetDevicesById(props.establishmentId, searchValue, productId, installedVersion, availableVersion, dateHeartbeat, dateLastUpdateAttempt), {
        refetchOnWindowFocus: false
    }
    );

    const DeviceDetail = () => {
        return (
            <Container fluid>
                <div className="horizontal-separator"></div>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Device ID </Col>
                    <Col md={8}>{currentSelectedDevice.deviceID}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Device Name </Col>
                    <Col md={8}>{currentSelectedDevice.deviceName}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Machine Name </Col>
                    <Col md={8}>{currentSelectedDevice.machineName}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">IP Address </Col>
                    <Col md={8}>{currentSelectedDevice.ipAddress}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Public IP Address </Col>
                    <Col md={8}>{currentSelectedDevice.publicIpAddress}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Device Heartbeat </Col>
                    <Col md={8}>{new Date(currentSelectedDevice.dateHeartbeat).toUTCString()}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Device Created Date </Col>
                    <Col md={8}>{new Date(currentSelectedDevice.dateCreated).toDateString()}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">Device Updated Date </Col>
                    <Col md={8}>{new Date(currentSelectedDevice.dateModified).toDateString()}</Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">.Net Framework Version </Col>
                    <Col md={8}></Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">OS </Col>
                    <Col md={8}></Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="device-detail-labels">OS Version </Col>
                    <Col md={8}></Col>
                </Row>
            </Container>
        )
    }

    const DeviceDetailHeader = () => {
        return (
            <p className="device-detail-heading mt-3">{currentSelectedDevice.deviceName} Detail</p>
        )
    }

    function goToDashboard(deviceId: any, logLevel: any, device: any) {
        navigate(`/message?startDate=${props.startDateTime.toISOString()}&finishDate=${props.endDateTime.toISOString()}&establishmentId=${props.establishmentId}&deviceId=${deviceId}&logLevel=${logLevel}&establishmentName=${props.establishmentData.name}&deviceName=${device.deviceName}&timer=${props.selectedTimer}&unit=${props.timeUnit}`)
    }

    const RenderDeviceStatistics = ({ device, deviceId, installedEstablishmentID }: any) => {
        let startDate = props.startDateTime?.toISOString();
        let endDate = props.endDateTime?.toISOString();
        const { isLoading, data } = useQuery([startDate + endDate + deviceId], () =>
            ServiceManager.MessageService.GetStatistics(startDate, endDate, undefined, installedEstablishmentID, deviceId), {
            refetchOnWindowFocus: false
        }
        );
        if (isLoading) {
            return (<section className="ms-auto w-10"><Skeleton height={30} /></section>)
        } else {
            if (data?.data && data?.data.length > 0) {
                return (<section className="ms-auto d-flex">
                    {
                        data.data.map((stats: any, index: any) => (
                            stats.logLevel === 5 ?
                                <span key={index}
                                    className="badge rounded-pill cursor-pointer device-stats-icon d-flex justify-content-center align-items-center me-2 bg-danger"
                                    title={stats.logLevelName} onClick={() => goToDashboard(deviceId, stats.logLevel, device)}>{stats.count}</span> :
                                stats.logLevel === 4 ?
                                    <span key={index} className="badge rounded-pill cursor-pointer device-stats-icon d-flex justify-content-center align-items-center me-2 bg-msg-error"
                                        title={stats.logLevelName} onClick={() => goToDashboard(deviceId, stats.logLevel, device)}>{stats.count}</span> :
                                    stats.logLevel === 3 ?
                                        <span key={index} className="badge rounded-pill cursor-pointer device-stats-icon d-flex justify-content-center align-items-center me-2 bg-warning"
                                            title={stats.logLevelName} onClick={() => goToDashboard(deviceId, stats.logLevel, device)}>{stats.count}</span> :
                                        <span key={index}></span>
                        ))
                    }
                </section>)
            }
            else {
                return (<section className="ms-auto"></section>)
            }
        }
    }

    const RenameButton = ({ device }: any) => {
        const canManageDevices = usePermissionCheck(Permissions.CanManageDevices);
        return (
            <>
                {canManageDevices
                    ?
                    <>
                        <Button variant="light" className="font-primary d-none d-md-none d-lg-flex" onClick={() => {
                            setUpState(device);
                            setShowRenameModal(true);
                        }}>Rename</Button>
                        <Button variant="light" className="font-primary d-flex justify-content-center align-items-center toggle-icon gray-bg rounded-5 d-none d-lg-none d-md-flex" onClick={() => {
                            setUpState(device);
                            setShowRenameModal(true);
                        }} >
                            <FontAwesomeIcon icon={faEdit} className="bi" />
                        </Button>
                    </>
                    :
                    <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div>
                            <Button variant="light" className="font-primary d-none d-md-none d-lg-flex" disabled>Rename</Button>
                            <Button variant="light" className="font-primary d-flex justify-content-center align-items-center toggle-icon gray-bg rounded-5 d-none d-lg-none d-md-flex" disabled>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </div>
                    </HUBTooltip>
                }
            </>
        )
    }

    const MoveButton = ({ device, establishmentData }: any) => {
        const canManageDevices = usePermissionCheck(Permissions.CanManageDevices);
        return (
            <>
                {canManageDevices
                    ?
                    <>
                        <Button variant="light" className="font-primary d-none d-md-none d-lg-flex" disabled={establishmentData.parent === null && (establishmentData.children === null || establishmentData.children.length === 0)} onClick={() => {
                            setUpState(device);
                            setShowMoveModal(true);
                        }}>Move Device</Button>
                        <Button variant="light" disabled={establishmentData.parent === null && (establishmentData.children === null || establishmentData.children.length === 0)}
                            className="font-primary d-flex justify-content-center align-items-center toggle-icon gray-bg rounded-5 d-none d-lg-none d-md-flex" onClick={() => {
                                setUpState(device);
                                setShowMoveModal(true);
                            }}>
                            <FontAwesomeIcon icon={faExchange} />
                        </Button>
                    </>
                    :
                    <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div>
                            <Button variant="light" className="font-primary d-none d-md-none d-lg-flex" disabled>Move Device</Button>
                            <Button variant="light" disabled
                                className="font-primary d-flex justify-content-center align-items-center toggle-icon gray-bg rounded-5 d-none d-lg-none d-md-flex">
                                <FontAwesomeIcon icon={faExchange} className="" />
                            </Button>
                        </div>
                    </HUBTooltip>
                }
            </>
        )
    }

    return (
        <React.Fragment>
            <Stack direction="horizontal">
                <SearchBar placeholder="Search" className="mt-3" onChange={(searchText: string) => setSearchValue(searchText)} />
                <DeviceFilter callbackProduct={callbackProduct} callbackAvailableVersion={callbackAvailableVersion} callbackInstalledVersion={callbackInstalledVersion} callbackHeartbeat={callbackHeartbeat}
                    callbackLastUpdateAttempt={callbackLastUpdateAttempt} />
            </Stack>
            {!isLoading && data.data !== null && data.data.length > 0 &&
                <div className="devices">
                    <Accordion flush>
                        {data?.data?.map((device: any) => (
                            <Card className="mt-3 card-border no-shadow" key={device.deviceID}>
                                <Card.Header className="white-background no-border">
                                    <Stack direction="horizontal" gap={3} className="w-100">
                                        <div
                                            className="device-icon d-flex justify-content-center align-items-center crbc-icon">
                                            <FontAwesomeIcon icon={faTvAlt} className="bi" />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span title={device.deviceName}
                                                className="blue-font ellipsis">{device.deviceName} {device.isNewPackageVersionAvailable && <FontAwesomeIcon className="ms-2" title="One or more apps on this device have new versions ready to be installed" icon={faCircleExclamation} color="#ECBD00" size="lg" />}</span>
                                            <span title={device.ipAddress}
                                                className="text-muted ellipsis">{device.ipAddress}</span>
                                            {device.deviceName !== device.machineName &&
                                                <span title={device.machineName}
                                                    className="text-muted ellipsis">{"Machine Name: " + device.machineName}</span>}
                                        </div>
                                        <div
                                            className={`d-flex justify-content-center align-items-center rounded-5`}>
                                            <FontAwesomeIcon className="cursor-pointer" icon={faCircleInfo} color="#0047AB" size="lg" onClick={
                                                () => {
                                                    setUpState(device);
                                                    setShowDeviceDetailModal(true)
                                                }
                                            } />
                                        </div>
                                        {(() => {
                                            let className = "green";
                                            let bgClassName = "light-green-bg";

                                            if (isToday(new Date(device.dateHeartbeat))) {
                                                let hoursDifference = differenceInHours(new Date(), new Date(device.dateHeartbeat));
                                                if (hoursDifference >= 1) {
                                                    className = "amber";
                                                    bgClassName = "amber-bg";
                                                }
                                            } else {
                                                bgClassName = "red-bg";
                                                className = "red";
                                            }

                                            return <div
                                                className={`d-flex justify-content-center align-items-center heartbeat-icon ${bgClassName} rounded-5`}>
                                                <FontAwesomeIcon icon={faHeartPulse} size="lg" className={className}
                                                    title={format(new Date(device.dateHeartbeat), "'Last heartbeat:' MMM do, yyyy @ HH:mm")} />
                                            </div>
                                        })()}

                                        <RenderDeviceStatistics device={device} deviceId={device.deviceID} installedEstablishmentID={device.installedEstablishmentID} />

                                        <MoveButton device={device} establishmentData={props.establishmentData} />
                                        <RenameButton device={device} />
                                        <ToggleButton eventKey={device.deviceID}></ToggleButton>
                                    </Stack>
                                </Card.Header>
                                <Accordion.Collapse eventKey={device.deviceID}
                                    onEnter={() => setExpandedKey(device.deviceID)}>
                                    <React.Fragment>
                                        {expandedKey === device.deviceID && (
                                            <EstablishmentDevicePackages establishmentId={device.installedEstablishmentID} deviceId={device.deviceID} deviceName={currentDeviceName} />
                                        )}
                                    </React.Fragment>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </div>}

            {!isLoading && (data === null || data.data === null || data.data.length === 0) &&
                <div className="text-muted mt-3"><p>No devices found</p></div>
            }
            {isLoading &&
                <div className="d-flex justify-content-center mt-3">
                    <HUBSpinner />
                </div>
            }

            <CustomModal isShow={showRenameModal} handleClose={handleCloseRenameModal} header={<IconElement headerClass="header-icon blue-icon" iconType={faPen} color="#0060DF" />} size="md">
                <DeviceUpdateForm handleClose={handleCloseRenameModal} establishmentId={currentEstablishmentId} deviceId={currentDeviceId} deviceName={currentDeviceName} />
            </CustomModal>

            <CustomModal isShow={showMoveModal} handleClose={handleCloseMoveModal} header={<EstablishmentListHeader />} size="lg">
                <EstablishmentList establishmentData={props.establishmentData} showDeviceMoveConfirmationModal={showDeviceMoveConfirmationModal} />
            </CustomModal>

            <CustomModal isShow={showMoveConfirmationModal} handleClose={handleCloseMoveConfirmationModal} size="md" header={<IconElement headerClass="header-icon orange-icon ms-6" iconType={faWarning} color="#DC6803" />}>
                <DeviceMoveForm establishmentId={currentEstablishmentId} deviceId={currentDeviceId}
                    deviceName={currentDeviceName} newEstablishmentName={newEstablishmentName} newEstablishmentId={newEstablishmentId} handleClose={handleCloseMoveConfirmationModal} handleCloseMoveModal={handleCloseMoveModal} />
            </CustomModal>
            <CustomModal isShow={showDeviceDetailModal} header={<DeviceDetailHeader />} handleClose={handleCloseDeviceModal} size="lg">
                <DeviceDetail></DeviceDetail>
            </CustomModal>

        </React.Fragment>
    );
}