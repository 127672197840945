
import { Button, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface ILoglevelFilterProps {
    handleClose: any;
    field: any;
    StartDate: any;
    EndDate: any;
    pageIndex: number;
    pageSize: number;
    handleCategoryFilter: any;
    handleApplyFilter: any;
    selectedCategory: any;
}

export default function DeviceFilterForm(props: ILoglevelFilterProps) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data: any) => {
        props.handleCategoryFilter(data.categoryName);
        props.handleApplyFilter();
        props.handleClose()
        AddUrlFilter("category", data.categoryName);
    };

    return (
        <Stack>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <label className="mt-1">{props.field}</label>
                <input {...register("categoryName", { required: true })} defaultValue={props.selectedCategory} className={`form-control mt-1 ms-0 w-100 ${(errors.categoryName) ? 'is-invalid' : ''}`} />
                {errors.categoryName && <div className="invalid-feedback">
                    The {props.field} name field value is invalid, it cannot be empty or contain whitespaces
                </div>}
                <Stack direction="horizontal" className="mt-3">
                    <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
                        Apply
                    </Button>
                </Stack>
            </form>
        </Stack>
    )
}