import { Button, ButtonGroup, Dropdown, DropdownButton, Stack } from "react-bootstrap"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV, faRefresh } from "@fortawesome/free-solid-svg-icons"
import HubButton from "../button"
import { useQueryClient } from "@tanstack/react-query"
import { timeUnit } from "../../app/enums"
import { SubmitHandler, useForm } from "react-hook-form"
import CustomModal from "../modal"

interface IDateRangeForm {
  startDate: string;
  endDate: string;
}

export default function TimeFilter({ selectedTimer, selectedTimeUnit, setTimes, isRefresh }: any) {

  const [active, setActive] = useState(selectedTimer + selectedTimeUnit);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<IDateRangeForm>();
  const [customDateFormError, setCustomDateFormError] = useState<string | null>(null);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const queryClient = useQueryClient();

  let timerDropDownButtonsData: { time: number, timeUnit: timeUnit, description: string }[] = [
    { "time": 12, "timeUnit": timeUnit.hour, "description": "Last 12 hours" },
    { "time": 1, "timeUnit": timeUnit.day, "description": "Last day" },
    { "time": 3, "timeUnit": timeUnit.day, "description": "Last 3 days" },
    { "time": 7, "timeUnit": timeUnit.day, "description": "Last week" },
    { "time": 14, "timeUnit": timeUnit.day, "description": "Last 2 weeks" },
    { "time": 1, "timeUnit": timeUnit.month, "description": "Last month" },
    { "time": 30, "timeUnit": timeUnit.year, "description": "Custom" }
  ];
  let timerButtonsData: { time: number, timeUnit: timeUnit, description: string }[] = [
    { "time": 30, "timeUnit": timeUnit.minute, "description": "Last 30 minutes" },
    { "time": 1, "timeUnit": timeUnit.hour, "description": "Last hour" },
    { "time": 4, "timeUnit": timeUnit.hour, "description": "Last 4 hours" }
  ];

  function calculateDateTime(selectedTimer: number, timerUnit: timeUnit, isRefresh?: boolean) {
    var finishDate = new Date();
    var startDate = new Date(finishDate);

    switch (timerUnit) {
      case timeUnit.minute:
        startDate.setMinutes(finishDate.getMinutes() - selectedTimer);
        break;
      case timeUnit.hour:
        startDate.setHours(finishDate.getHours() - selectedTimer);
        break;
      case timeUnit.day:
        startDate.setDate(finishDate.getDate() - selectedTimer);
        break;
      case timeUnit.month:
        startDate.setMonth(finishDate.getMonth() - selectedTimer);
        break;
      case timeUnit.year:
          setShowCustomModal(true);
          break;
      default:
        throw new Error('Time unit invalid!');
    }

    //"timeUnit.year" is for custom date range. we are handling it separately
    if (timerUnit !== timeUnit.year) {
      if (isRefresh) {
        queryClient.invalidateQueries({ queryKey: [selectedTimer + startDate.toTimeString()] });
      }
      setTimes(selectedTimer, timerUnit, startDate, finishDate, isRefresh);
      setActive(selectedTimer + timerUnit);
    }
  }

  const onDateRangeSubmit: SubmitHandler<IDateRangeForm> = (data) => {
    const startDate = new Date(data.startDate);
    const finishDate = new Date(data.endDate);

    if (finishDate < startDate) {
      setCustomDateFormError('End date should not be less than start date.');
    } else {
      const differenceInDays = (finishDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

      if (differenceInDays > 30) {
        setCustomDateFormError('Date range must not exceed 31 days.');
      } else {
        setCustomDateFormError(null);
        if (isRefresh) {
          queryClient.invalidateQueries({ queryKey: [selectedTimer + startDate.toTimeString()] });
        }
        setTimes(30, timeUnit.year, startDate, finishDate, isRefresh);
        setActive(30 + timeUnit.year);
        onDateRangeClose();
      }
    }
  };

  const onDateRangeClose = () => {
    reset();
    setCustomDateFormError(null);
    setShowCustomModal(false);
  };

  const CustomDateRangeModal = () => {
    return (
      <Stack>
        <form className="date-range-container" onSubmit={handleSubmit(onDateRangeSubmit)}>

          {customDateFormError && <p className="form-error alert alert-danger">{customDateFormError}</p>}

          <label htmlFor="startDate" className="mt-1">Choose a Start Date:</label>
          <input type="date" id="startDate" {...register('startDate', { required: 'Start date is required' })}
            className={`form-control mt-1 ms-0 w-100 ${(errors.startDate) ? 'is-invalid' : ''}`} />
          {errors.startDate && <p className="invalid-feedback m-1">{errors.startDate.message}</p>}

          <label htmlFor="endDate" className="mt-2">Choose an End Date:</label>
          <input type="date" id="endDate" {...register('endDate', { required: 'End date is required' })}
            className={`form-control mt-1 ms-0 w-100 ${(errors.endDate) ? 'is-invalid' : ''}`} />
          {errors.endDate && <p className="invalid-feedback m-1">{errors.endDate.message} </p>}

          <Stack direction="horizontal" className="mt-3">
            <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={onDateRangeClose}>
              Cancel
            </Button>
            <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
              Apply
            </Button>
          </Stack>
          
        </form>
      </Stack>
    );
  }

  
  return (
    <Stack direction="horizontal">
      {
        isRefresh && <HubButton className="btn btn-outline-secondary timer-toolbar-button" text="Refresh" textClass="timer-toolbar-refresh-button"
          icon={<FontAwesomeIcon icon={faRefresh} className="refresh-btn-icon" />}
          FnOnClick={function () { calculateDateTime(selectedTimer, selectedTimeUnit, true) }}></HubButton>
      }
      <ButtonGroup onSelect={setTimes} className="ms-auto">
        {
          timerButtonsData.map((data, index) =>
            <HubButton textClass="toolbar-buttons-text" key={index} text={data.description} className={`btn btn-outline-secondary timer-toolbar-button ${active === data.time.toString() + data.timeUnit ? "active" : ""}`}
              FnOnClick={function () { calculateDateTime(data.time, data.timeUnit) }}></HubButton>
          )
        }
        <DropdownButton as={ButtonGroup} title={<FontAwesomeIcon style={{ border: "none" }} icon={faEllipsisV} />} id="bg-nested-dropdown" className="timer-dropdown">
          {
            timerDropDownButtonsData.map((data, index) =>
              <Dropdown.Item key={index} onClick={function () { calculateDateTime(data.time, data.timeUnit) }} className={`${active === data.time.toString() + data.timeUnit ? "active" : ""}`}>
                {data.description}
              </Dropdown.Item>
            )
          }
        </DropdownButton>
      </ButtonGroup>

      <CustomModal
        isShow={showCustomModal}
        handleClose={onDateRangeClose}
        header={<Stack><p className="heading">Custom</p><p className="text-muted">Please select date range</p></Stack>} size="md">
        <CustomDateRangeModal />
      </CustomModal>
    </Stack>
  )
}