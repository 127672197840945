import { useState } from "react";
import { ServiceManager } from "../../../services/ServiceManager";
import PagedFilterTable from "../../../components/table";
import { Button, Stack } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import IRoles from "../../../models/Roles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../components/modal";
import IconElement from "../../../components/icon";
import { APIStatusCode } from "../../../app/enums";
import { SOMETHING_WENT_WRONG } from "../../../app/constants";
import { useQueryClient } from "@tanstack/react-query";
import Form from 'react-bootstrap/Form';

export default function RolesView() {
    
    const queryClient = useQueryClient();
    const [tableJobs, setTableJobs] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const [currentRoleId, setCurrentRoleId] = useState("");
    
    const columnHelper = createColumnHelper<IRoles>();
    const columns = [
        columnHelper.accessor('roleName', {
            header: () => <span>Role Name</span>,
            size: 30,
            cell: ({ row }) => (
                <NavLink to={`/role/${row.original.roleID}`}>
                    {row.original.roleName}
                </NavLink>
            ),
        }),
        columnHelper.accessor('description', {
            header: () => <span>Description</span>,
            size: 40
        }),
        columnHelper.accessor('defaultForUsers', {
            header: () => <span>Default for User</span>,
            size: 20,
            cell: ({ row }) => (
                <Form.Check
                    type="checkbox"
                    disabled
                    checked={row.original.defaultForUsers}
                />
                ),
        }),
        columnHelper.accessor("roleID", {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                <FontAwesomeIcon
                    className="cursor-pointer me-5"
                    icon={faTrashAlt}
                    size="lg"
                    onClick={() => {
                        processDeleteRole(row.original.roleID)
                    }}
                />
            ),
            size: 10,
        })
    ]

    async function fetchData() {
        const packagesResponse = await ServiceManager.RoleService.GetAll();
        setTableJobs(packagesResponse.data);
        return packagesResponse.data;
    }

    function processDeleteRole(roleId: string){
        setCurrentRoleId(roleId);
        setShowDeleteModal(true);
    }

    async function deleteRole(){
        const deleteResponse = await ServiceManager.RoleService.DeleteById(currentRoleId);
        if (deleteResponse.data === "" && deleteResponse.status === APIStatusCode.NoContent) {
            setShowDeleteModal(false);
            queryClient.invalidateQueries();
            ServiceManager.ToastServer.showSuccess("Role deleted successfully");
        } else {
            setShowDeleteModal(false);
            let errorMessage = "";
            if(deleteResponse.response.data.errors.errors){
                errorMessage = deleteResponse.response.data.errors.errors;
            }
            else if(deleteResponse.message){
                errorMessage = deleteResponse.message;
            }
            else{
                errorMessage = SOMETHING_WENT_WRONG;
            }
            ServiceManager.ToastServer.showError(errorMessage.toString());
        }
    }

    const RoleDeleteConfirmationForm = () => {
        return (
            <Stack>
                <h5 className="text-center">Delete role</h5>
                <p className="text-center">Are you sure you want to delete this role? This action cannot be undone.</p>
                <Stack direction="horizontal" className="mt-3">
                    <Button variant="popup-btn right-margin10 btn-outline-secondary w-50" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="popup-btn right-margin10 btn-danger w-50" onClick={deleteRole}>
                        Delete
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return(
        <>
            {
                <div id="rolesTable" className="mt-4">
                    <PagedFilterTable
                        tableKey={"roles-table"}
                        columns={columns}
                        data={tableJobs}
                        usePagination={false}
                        useSearch={false}
                        pageRecords={50}
                        fnFetchData={fetchData}
                        title={
                            <>
                                <h5 className="ps-3 pt-3"><b>Roles</b></h5>
                                <span style={{fontSize: "14px"}} className="ps-3 pb-3">Depending on the assigned role, you can access particular features. You can view permissions by clicking on roles.</span>
                            </>}
                    />
                </div>
            }

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <RoleDeleteConfirmationForm />
            </CustomModal>
        </>
    )
}