import React, { useState } from "react";
import PagedFilterTable from "../table";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import { Button } from "react-bootstrap";
import IPackage from "../../models/Package";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface IEstablishmentListProps {
    handleClose: any;
    field: any;
    StartDate: any;
    EndDate: any;
    pageIndex: number;
    pageSize: number;
    productId: any;
    handlePackageFilter: any;
    handleApplyFilter: any;
    selectedPackage: any;
}
export default function PackageFilterList(props: IEstablishmentListProps) {
    const [rowSelection, setRowSelection] = useState(props.selectedPackage);
    const [packageName, setPackageName] = useState(null);
    const [tableJobs, setTableJobs] = useState([]);
    const columnHelper = createColumnHelper<IPackage>();
    const columns = [
        columnHelper.display({
            id: "select_packageID",
            cell: props => <input type="checkbox" checked={rowSelection === props.row.original.packageID} onChange={(e) => { onRowClick(props.row); }}></input>
        }),
        columnHelper.accessor('packageName', {
            header: () => <span>Name</span>,
            size: 50
        }),
        columnHelper.accessor('packageID', {
            header: () => <span>ID</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.packageID}</span>
                </div>
            ),
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.packageID;
        setRowSelection((prevValue: any) => (prevValue === selectedValue ? null : selectedValue));
        setPackageName((prevValue) => (prevValue === row.original.packageName ? null : row.original.packageName));
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        const packagesResponse = await ServiceManager.ProductService.GetPackagesById(props.productId);
        setTableJobs(packagesResponse.data);
        return packagesResponse.data;
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            props.handlePackageFilter(props.productId, rowSelection, packageName);
            props.handleClose();
            props.handleApplyFilter();
            AddUrlFilter("packageId", rowSelection);
            AddUrlFilter("packageName", packageName);

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <PagedFilterTable
                tableKey={"packages-filter"}
                columns={columns}
                data={tableJobs}
                usePagination={false}
                useSearch={false}
                pageRecords={10}
                fnFetchData={fetchData}
            />
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color"
                    className={` ${rowSelection === null ? "disabled" : ""}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )
};