import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../layout";
import { faArrowLeft, faLayerGroup, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import PagedFilterTable from "../../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import IRingProduct from "../../../models/Ring";
import { ServiceManager } from "../../../services/ServiceManager";
import { Col, Nav, Row, Spinner, Stack, Tab } from "react-bootstrap";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";
import { APIStatusCode, Permissions } from "../../../app/enums";
import HubButton from "../../../components/button";
import HUBTooltip from "../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../app/constants";
import CustomModal from "../../../components/modal";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import IconElement from "../../../components/icon";
import ProductVersionsList from "./product-versions-list";
import ProductsList from "./products-list";
import RingAssignedEstablishments from "../establishments/assigned-establishments";

export default function RingProducts() {

    let navigate = useNavigate();
    const { ringId } = useParams();
    const ringIdInt = parseInt(ringId ?? "0");

    const [showAssignProductModal, setShowAssignProductModal] = useState(false);
    const [showAssignVersionModal, setShowAssignVersionModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [ringName, setRingName]= useState('');

    const handleCloseAssignProductModal = () => setShowAssignProductModal(false);
    const handleCloseAssignVersionModal = () => setShowAssignVersionModal(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const [key, setKey] = useState("products");
    const [establishmentData, setEstablishmentsData] = useState([]);

    var canManageRing = false;
    canManageRing = usePermissionCheck(Permissions.CanManageRings);
    var canViewRings = false;
    canViewRings = usePermissionCheck(Permissions.CanViewRings);
    const TooltipTopNavItem = ({ title, eventKey, enable }: any) => {
        return (
            enable ?
                (
                    <Nav.Item>
                        <Nav.Link eventKey={eventKey} disabled={!enable}>{title}</Nav.Link>
                    </Nav.Item>
                )
                :
                (
                    <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                        <Nav.Item className="disabled">
                            <Nav.Link eventKey={eventKey} disabled={!enable}><span className="disabled">{title}</span></Nav.Link>
                        </Nav.Item>
                    </HUBTooltip>
                )
        )
    }


    const columnHelper = createColumnHelper<IRingProduct>();
    const columns = [
        columnHelper.accessor("productName", {
            header: () => <span>Product Name</span>,
            size: 25,
        }),
        columnHelper.accessor("versionNumber", {
            header: () => <span>Versions</span>,
            size: 25,
        }),
        columnHelper.accessor("productID", {
            id: 'productIdDisplay',
            header: () => <span>Product ID</span>,
            size: 25,
        }),
        columnHelper.accessor("productID", {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                canManageRing ? (
                    <>
                        <a href="/#" onClick={(e) => { e.preventDefault(); assignProductVersion(row.original.productID) }} className="me-2"><span className="dark-amber-color fw-bold">Change Version</span></a>
                        |
                        <a href="/#" onClick={(e) => { e.preventDefault(); UnassignProduct(row.original.productID) }} className="ms-2"><span className="red-color fw-bold">Unassign</span></a>
                    </>
                ) : (
                    <div className="disabled">
                        <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <span className="me-2"><span className="dark-amber-color fw-bold li-disabled">Change Version</span></span>

                        </HUBTooltip>|
                        <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <span className="ms-2"><span className="red-color fw-bold li-disabled">Unassign</span></span>

                        </HUBTooltip></div>

                )


            ),
            size: 5
        }),
    ];

    async function fetchData(options: {
        pageSize: number;
        searchValue: string;
    }) {
        const establishmentResponse = await ServiceManager.ProductService.GetProductsByRingId(
            ringIdInt, true, true
        );
        setEstablishmentsData(establishmentResponse.data?.establishments);
        setRingName(establishmentResponse.data?.ringName);
        return establishmentResponse.data?.productVersions;
    }

    const assignProduct = (productId: any) => {
        setSelectedProductId(productId);
        setShowAssignProductModal(true);
    };

    const assignProductVersion = (productId: any) => {
        setSelectedProductId(productId);
        setShowAssignVersionModal(true);
    };

    const UnassignProduct = (productId: any) => {
        setSelectedProductId(productId);
        setShowDeleteModal(true);
    };

    const UnAssignProductConfirmationForm = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.ProductService.UnassignRing(ringIdInt, selectedProductId);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastServer.showError("Unable to unassign product");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastServer.showSuccess("Product unassigned successfully");
                        queryClient.invalidateQueries({ queryKey: [`rign-products-${ringId}`] });
                        handleCloseDeleteModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });

        return (
            <Stack>
                <p className="heading text-center">Unassign Product</p>
                <p className="text-center">Are you sure you want to unassign the product from this Ring? Establishments assigned to this Ring will not be able to update their product any further until the product is re-added, or until they are assigned to a different Ring?</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <HubButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <HubButton text="Confirm" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout
            title={ringName ?? ""}
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
        >
            <Tab.Container id="ringsTab" defaultActiveKey={key}>
                <Row className="mt-2">
                    <Col sm={12}>
                        <Nav variant="tabs" onSelect={(k: any) => setKey(k)}>
                            <TooltipTopNavItem title={'Assigned Products'} eventKey={'products'} enable={canViewRings} />
                            <TooltipTopNavItem title={'Assigned Establishments'} eventKey={'establishments'} enable={canViewRings} />
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="products">{key === "products" &&
                                <div className="ring-detail" style={{ marginTop: "50px" }}>
                                    <PagedFilterTable
                                        tableKey={`rign-products-${ringId}`}
                                        columns={columns}
                                        data={[]}
                                        usePagination={false}
                                        useSearch={false}
                                        pageRecords={20}
                                        fnFetchData={fetchData}
                                        title={
                                            <>
                                                <Stack direction="horizontal">
                                                    <h5 className="ps-3 pt-3 pb-2">
                                                        <b>Assigned Products</b>
                                                        <br />
                                                        <span style={{ fontSize: "14px", fontWeight: "normal" }}>Listed below are the products and their versions which are assigned to this Ring.</span>
                                                    </h5>
                                                    {canManageRing ? (
                                                        <HubButton
                                                            text="Assign New Product"
                                                            className={`btn btn-primary crbc-bg-color ms-auto me-3 `}
                                                            FnOnClick={(e) => assignProduct(e)}
                                                            icon={<FontAwesomeIcon icon={faLayerGroup} type="button" />}
                                                        ></HubButton>
                                                    ) : (
                                                        <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                                                            <div className="disabled ms-auto me-3">
                                                                <HubButton
                                                                    text="Assign New Product"
                                                                    className={`btn btn-primary crbc-bg-color ms-auto me-3 disabled`}
                                                                    FnOnClick={(e) => assignProduct(e)}
                                                                    icon={<FontAwesomeIcon icon={faLayerGroup} type="button" />}
                                                                ></HubButton>
                                                            </div>
                                                        </HUBTooltip>
                                                    )}
                                                </Stack>
                                            </>
                                        }
                                    />
                                </div>
                            }</Tab.Pane>
                            <Tab.Pane eventKey="establishments">{key === "establishments" &&
                                <RingAssignedEstablishments ringID={ringId} establishments={establishmentData}></RingAssignedEstablishments>
                            }</Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

            <CustomModal
                isShow={showAssignVersionModal}
                handleClose={handleCloseAssignVersionModal}
                header={<Stack> <p className="heading"><br /></p> </Stack>}
                size="lg">
                <ProductVersionsList productId={selectedProductId} ringId={ringId} handleClose={handleCloseAssignVersionModal} />
            </CustomModal>

            <CustomModal
                isShow={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />}
                size="md">
                <UnAssignProductConfirmationForm />
            </CustomModal>

            <CustomModal
                isShow={showAssignProductModal}
                handleClose={handleCloseAssignProductModal}
                header={<Stack> <p className="heading"><br /></p> </Stack>}
                size="lg">
                <ProductsList ringID={ringId} handleClose={handleCloseAssignProductModal} />
            </CustomModal>

        </Layout>
    );
};