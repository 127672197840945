import Layout from '../layout';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Container, Button, Stack } from 'react-bootstrap';
import HubButton from '../../components/button';
import { alertTypes, logLevelTypes } from '../../app/common/data/AlertTypes';
import { APIStatusCode, AlertFieldType, AlertType } from "../../app/enums";
import CustomModal from "../../components/modal";
import Card from "react-bootstrap/Card";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";
import IEstablishment from "../../models/Establishment";
import { ServiceManager } from "../../services/ServiceManager";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import PagedFilterTable from "../../components/table";
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from "react-hook-form";
import Select from "react-select";
import HUBSpinner from '../../components/spinner';

export default function CreateNewAlert() {
    let navigate = useNavigate();
    const { alertId, } = useParams();
    const customStyles = {
        control: (baseStyles: any) => ({
            ...baseStyles,
            borderRadius: '8px',
            borderColor: '#D0D5DD'
        }),
    }
    const timeframeList = [{
        label: "1 Hour",
        value: "1"
    },
    {
        label: "4 Hours",
        value: "4"
    },
    {
        label: "12 Hours",
        value: "12"
    },
    {
        label: "1 Day",
        value: "24"
    },
    {
        label: "3 Days",
        value: "72"
    },
    {
        label: "7 Days",
        value: "168"
    },
    {
        label: "14 Days",
        value: "336"
    },
    {
        label: "31 Days",
        value: "744"
    }];

    const [selectedAlertType, setSelectedAlertType] = useState<any>({
        value: '',
        label: 'Select'
    });
    const [selectedLogLevelType, setSelectedLogLevelType] = useState<any>({
        value: '',
        label: 'Select'
    });
    const [selectedTimeframe, setSelectedTimeframe] = useState<any>({
        value: '',
        label: 'Select'
    });
    const [establishmentRadioValue, setEstablishmentRadioValue] = useState('all');
    const [showEstablishmentSelectModal, setShowEstablishmentSelectModal] = useState(false);
    const [selectedEstablishmentId, setSelectedEstablishmentId] = useState('');
    const [selectedEstablishmentName, setSelectedEstablishmentName] = useState('');
    const [isEstablishmentId, setIsEstablishmentId] = useState(false);
    const [isSpinner, setSpinner] = useState<boolean>();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: "onChange" });

    const { isLoading: isAlertsLoading, data: alertData } = useQuery([`alert-${alertId ?? 0}`], () => {
        if (alertId) {
            return ServiceManager.AlertService.GetById(Number(alertId));
        }
    },
        { refetchOnWindowFocus: false, cacheTime: 0 }
    );

    const { isLoading: isEstLoading, data: estData } = useQuery([`Establisment-${selectedEstablishmentId ?? 0}`], () => {
        if (alertId) {
            return ServiceManager.EstablishmentService.GetById(selectedEstablishmentId, false);
        }
    },
        { refetchOnWindowFocus: false, cacheTime: 0 }
    );

    useEffect(() => {
        if (!isAlertsLoading && alertData !== null) {
            let alertName = alertData?.data?.alertName;
            if (alertName) {
                setValue("alertName", alertName);
            }
            let aletType = alertData?.data?.alertType
            if (aletType) {
                let aletTypeValue = alertTypes.filter((x: any) => x.value === aletType)[0]
                setSelectedAlertType(aletTypeValue);
                setValue("alertType", aletTypeValue);
            }
            var logLevel = alertData?.data?.alertFields?.filter((x: any) => x.fieldType === AlertFieldType.LogLevel);
            if (alertData?.data?.alertType === AlertType.LogLevel && logLevel.length > 0) {
                let options = {
                    value: logLevel[0].fieldValue,
                    label: logLevelTypes.filter((x: any) => x.value === Number(logLevel[0].fieldValue))[0].label
                }
                setSelectedLogLevelType(options);
                setValue("logLevelType", options);
            }
            var logText = alertData?.data?.alertFields?.filter((x: any) => x.fieldType === AlertFieldType.LogText);
            if (alertData?.data?.alertType === AlertType.LogText && logText.length > 0) {
                setValue("logText", logText[0].fieldValue);
            }
            var count = alertData?.data?.alertFields?.filter((x: any) => x.fieldType === AlertFieldType.Count);
            if (count && count.length > 0) {
                setValue("count", count[0].fieldValue);
            }
            setValue("email", alertData?.data?.emailAddress);

            var timeFrame = alertData?.data?.alertFields?.filter((x: any) => x.fieldType === AlertFieldType.TimeFrame);
            if (timeFrame && timeFrame.length > 0) {
                let options = {
                    value: timeFrame[0].fieldValue,
                    label: timeframeList.find(x => x.value === timeFrame[0].fieldValue)?.label
                }
                setSelectedTimeframe(options);
                setValue("timeframe", options);
            }
            var establishment = alertData?.data?.alertFields?.filter((x: any) => x.fieldType === AlertFieldType.Establishment);
            if (establishment && establishment.length > 0) {
                if (!isEstablishmentId && establishment[0].fieldValue !== "all") {
                    setEstablishmentRadioValue("some");
                    setSelectedEstablishmentId(establishment[0].fieldValue);
                    setIsEstablishmentId(true);
                }
            }
        }
        if (!isEstLoading && estData !== null) {
            if (estData?.data?.name) {
                setSelectedEstablishmentName(estData?.data?.name);
            }
        }
        // eslint-disable-next-line
    }, [isAlertsLoading, alertData, alertId, isEstLoading, estData]);

    const handleAlertTypeChange = (option: any) => {
        setSelectedAlertType(option);
        setValue('alertType', option, { shouldValidate: true });
    };

    const handleTimeframeChange = (option: any) => {
        setSelectedTimeframe(option);
        setValue('timeframe', option, { shouldValidate: true });
    }

    const handleLogTypeChange = (option: any) => {
        setSelectedLogLevelType(option);
        setValue('logLevelType', option, { shouldValidate: true });
    }

    const handleEstablishmentRadioChange = (event: any) => {
        setSelectedEstablishmentId("")
        setSelectedEstablishmentName("")
        setEstablishmentRadioValue(event.target.value);
    }

    const handleCloseEstablishmentModal = () => {
        setShowEstablishmentSelectModal(false);
    }

    async function handleEstablishmentFilter(establishmentId: any, establishmentName: any) {
        setSelectedEstablishmentId(establishmentId);
        setSelectedEstablishmentName(establishmentName)
    }

    function addAlertFields(formData: any) {
        let alertFields: any[] = [];
        switch (selectedAlertType.value) {
            case 1:
                addLogLevelFields(alertFields, formData);
                break;
            case 2:
                addLogTextFields(alertFields, formData);
                break;
            default:
                break;
        }

        return alertFields;
    }

    const SelectEstablishmentModal = (props: any) => {
        const [rowSelection, setRowSelection] = useState('')
        const [tableJobs, setTableJobs] = useState([]);
        const [establishmentName, setEstablishmentName] = useState(null);
        const columnHelper = createColumnHelper<IEstablishment>();
        const columns = [
            columnHelper.display({
                id: "select_establishmentID",
                size: 5,
                cell: props => <input type="checkbox" checked={rowSelection === props.row.original.establishmentID}
                    onChange={() => {
                        onRowClick(props.row);
                    }}></input>
            }),
            columnHelper.accessor('name', {
                header: () => <span>Name</span>,

                cell: ({ row }) => (
                    <NavLink to={`/establishment/${row.original.establishmentID}`}>
                        {row.original.name}
                    </NavLink>
                ),
            })
        ];

        function onRowClick(row: any) {
            const selectedValue = row.original.establishmentID;
            setRowSelection((prevValue: any) => (prevValue === selectedValue ? null : selectedValue));
            setEstablishmentName((prevValue) => (prevValue === row.original.name ? null : row.original.name));
        }

        async function fetchData(options: {
            pageIndex: number;
            pageSize: number;
            searchValue: string;
        }) {
            const establishmentResponse = await ServiceManager.EstablishmentService.GetAllWithChildern(false, options.pageIndex + 1, options.pageSize, options.searchValue);
            setTableJobs(establishmentResponse.data);
            return establishmentResponse.data;
        }

        function onClose() {
            try {
                props.handleClose();
            } catch (e) {
                console.error(e);
            }
        }

        function handleApply() {
            try {
                props.handleEstablishmentFilter(rowSelection, establishmentName);
                props.handleClose();
            } catch (e) {
                console.error(e);
            }
        }

        return (
            <Card>
                <Card.Body>

                    <Card.Title className="text-muted">
                        Please Select Establishments</Card.Title>
                    <Card.Text>
                        <React.Fragment>
                            <PagedFilterTable
                                tableKey={"establishments-filter"}
                                columns={columns}
                                data={tableJobs}
                                usePagination={true}
                                useSearch={true}
                                pageRecords={11}
                                fnFetchData={fetchData}
                            />
                            <div className="mt-4">
                                <Button
                                    variant="popup-btn right-margin10 btn-outline-secondary"
                                    onClick={() => onClose()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="popup-btn btn btn-primary crbc-bg-color "
                                    className={` ${rowSelection === null ? "disabled" : ""}`}
                                    onClick={() => handleApply()}
                                >
                                    Apply
                                </Button>
                            </div>
                        </React.Fragment>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

    function getEstablishmentValue() {
        let establishmentValue: any;
        if (establishmentRadioValue === "all") {
            establishmentValue = "all";
        } else {
            establishmentValue = selectedEstablishmentId;
        }
        return establishmentValue;
    }

    function addLogLevelFields(alertFields: any[], formData: any) {
        alertFields.push({ fieldType: AlertFieldType.LogLevel, fieldValue: selectedLogLevelType.value.toString() });
        alertFields.push({ fieldType: AlertFieldType.Establishment, fieldValue: getEstablishmentValue() });
        alertFields.push({ fieldType: AlertFieldType.Count, fieldValue: formData.count });
        alertFields.push({ fieldType: AlertFieldType.TimeFrame, fieldValue: selectedTimeframe.value });
    }

    function addLogTextFields(alertFields: any[], formData: any) {
        alertFields.push({ fieldType: AlertFieldType.LogText, fieldValue: formData.logText });
        alertFields.push({ fieldType: AlertFieldType.Establishment, fieldValue: getEstablishmentValue() });
        alertFields.push({ fieldType: AlertFieldType.Count, fieldValue: formData.count });
        alertFields.push({ fieldType: AlertFieldType.TimeFrame, fieldValue: selectedTimeframe.value });
    }

    function resetForm() {
        setSelectedAlertType({
            value: '',
            label: 'Select'
        });
        setSelectedLogLevelType({
            value: '',
            label: 'Select'
        });
    }

    const mutation = useMutation({
        mutationFn: (alert: any) => {
            if (!alertId) {
                return ServiceManager.AlertService.SaveAlert(alert);
            } else {
                return ServiceManager.AlertService.UpdateAlert(Number(alertId), alert);
            }

        },
        onError: (error, variables, context) => {
            console.log(error);
        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastServer.showError("Unable to create alert");
            } else {
                setSpinner(false);
                if (data.status === APIStatusCode.Created) {
                    ServiceManager.ToastServer.showSuccess("Alert created successfully");
                    resetForm();
                    navigate(-1);
                } else if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastServer.showSuccess("Alert Updated successfully");
                    resetForm();
                    navigate(-1);
                } else {
                    let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                }
            }
        }
    });

    async function OnSubmit(data: any) {
        setSpinner(true);
        const alertRequest = {
            alertName: data.alertName,
            alertType: selectedAlertType.value,
            fields: addAlertFields(data),
            emailAddress: data.email
        };

        mutation.mutate(alertRequest);
    }

    function handleCancel() {
        try {
            resetForm();
            navigate(-1)

        } catch (e) {
            console.error(e);
        }
    }

    const positiveNumberValidation = (value: any) => {
        let number = parseInt(value);
        return (number >= 0 && number < 99999) || 'Enter a number from 1 to 99999.';
    };

    return (
        <Layout title={alertId ? 'Edit Alert' : 'Create New Alert'}
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)}
                    className="me-4 fs-5 cursor-pointer" />
            }
        >
            <>
                {
                    (isAlertsLoading === true || isSpinner === true)
                        ?
                        <Stack>
                            <HUBSpinner />
                        </Stack>
                        :
                        <Form onSubmit={handleSubmit(OnSubmit)} className='add-form-vertical-scroll'>
                            <Container className="mt-3" style={{ float: 'left' }}>
                                <Row className='mt-4'>
                                    <Col sm={12} md={3} lg={2}>
                                        <Form.Label>Alert Name</Form.Label>
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <Form.Control
                                            type="text"
                                            maxLength={50}
                                            placeholder="Alert Name"
                                            className={`form-control mt-1 ms-0 w-100  ${(errors.alertName) ? 'is-invalid' : ''}`}
                                            {...register("alertName", {
                                                required: 'Alert Name is required',
                                                maxLength: {
                                                    value: 50,
                                                    message: 'Alert Name must be at most 50 characters long'
                                                }
                                            })}
                                        />
                                        {errors.alertName && (
                                            <div className="invalid-feedback">
                                                {(errors.alertName as any).message}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col sm={12} md={3} lg={2}>
                                        <Form.Label>Alert Type</Form.Label>
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <input
                                            type="hidden"
                                            {...register("alertType", { required: 'Alert Type is required' })}
                                            value={selectedAlertType ? selectedAlertType.value : ''}
                                        />
                                        <Select className={`w-100   ${(errors.alertType) ? 'is-invalid' : ''}`}
                                            options={alertTypes}
                                            value={selectedAlertType}
                                            onChange={(option) => handleAlertTypeChange(option)}
                                            styles={customStyles}
                                            isDisabled={!!alertId}
                                        />
                                        {errors.alertType && <div className="invalid-feedback">
                                            Alert Type is required
                                        </div>}
                                    </Col>
                                </Row>
                                {
                                    selectedAlertType.value === AlertType.LogLevel ? (
                                        <Row className='mt-4'>
                                            <Col sm={12} md={3} lg={2}>
                                                <Form.Label>Log Level</Form.Label>
                                            </Col>
                                            <Col sm={12} md={4} lg={4}>
                                                <input
                                                    type="hidden"
                                                    {...register("logLevelType", { required: 'Log Level is required', })}
                                                    value={selectedLogLevelType ? selectedLogLevelType.value : ''}
                                                />
                                                <Select
                                                    className={`w-100   ${(errors.logLevelType) ? 'is-invalid' : ''}`}
                                                    options={logLevelTypes}
                                                    value={selectedLogLevelType}
                                                    onChange={(option) => handleLogTypeChange(option)}
                                                    styles={customStyles}
                                                    isDisabled={false}
                                                />
                                                {errors.logLevelType && <div className="invalid-feedback">
                                                    Log Level is required
                                                </div>}
                                            </Col>
                                        </Row>
                                    ) : (<span></span>)
                                }
                                {
                                    selectedAlertType.value === AlertType.LogText ? (
                                        <Row className="mt-4">
                                            <Col sm={12} md={3} lg={2}>Log Text</Col>
                                            <Col sm={12} md={4} lg={4}>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2}
                                                    maxLength={500}
                                                    className={`form-control mt-1 ms-0 w-100  ${(errors.logText) ? 'is-invalid' : ''}`}
                                                    placeholder="Log Text"
                                                    {...register("logText", {
                                                        required: 'Log Text is required',
                                                        maxLength: {
                                                            value: 500,
                                                            message: 'Log Text must be at most 500 characters long'
                                                        }
                                                    })}
                                                />
                                                {errors.logText && (
                                                    <div className="invalid-feedback">
                                                        {(errors.logText as any).message}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    ) : (<span></span>)
                                }
                                <Row className='mt-4'>
                                    <Col sm={12} md={3} lg={2}>
                                        <Form.Label>Establishment(s)</Form.Label>
                                    </Col>
                                    <Col sm={12} md={4} lg={4} style={{ marginLeft: "10px" }}>
                                        <Form.Check type="radio" aria-label="radio 1" label="All" name="establishment"
                                            value="all"
                                            onChange={handleEstablishmentRadioChange}
                                            checked={establishmentRadioValue === "all"} />
                                        <Form.Check type="radio" aria-label="radio 2" label="Specific Establishment"
                                            value="some"
                                            name="establishment" onChange={handleEstablishmentRadioChange}
                                            style={{ marginTop: "12px" }}
                                            checked={establishmentRadioValue === "some"} />
                                        <div className="mt-3">
                                            {
                                                establishmentRadioValue === "some" ? (
                                                    <div>
                                                        <Button variant="secondary"
                                                            onClick={() => setShowEstablishmentSelectModal(true)}>Select</Button>
                                                        <br />
                                                        {
                                                            selectedEstablishmentName !== "" ? (
                                                                <Button style={{ marginTop: "5px" }} variant="light"
                                                                    size={"sm"}>{selectedEstablishmentName}
                                                                    <FontAwesomeIcon style={{ marginLeft: "10px" }}
                                                                        icon={faClose}
                                                                        onClick={() => {
                                                                            setSelectedEstablishmentName("");
                                                                            setSelectedEstablishmentId("");
                                                                        }} />
                                                                </Button>
                                                            ) : (
                                                                <div>
                                                                    <input
                                                                        type="hidden"
                                                                        {...register("establishment", { required: 'Log Level is required', })}
                                                                        value={selectedEstablishmentName ? selectedEstablishmentName : ''} />
                                                                    <span className={`  ${(errors.establishment) ? 'is-invalid' : ''}`}>

                                                                    </span>
                                                                    {errors.establishment && <div className="invalid-feedback">
                                                                        Establishment is required
                                                                    </div>}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <span></span>)
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    selectedAlertType.value === AlertType.LogLevel || selectedAlertType.value === AlertType.LogText ? (
                                        <div>
                                            <Row className='mt-4'>
                                                <Col sm={12} md={3} lg={2}>
                                                    <Form.Label>Count</Form.Label>
                                                </Col>
                                                <Col sm={12} md={4} lg={4}>
                                                    <Form.Control
                                                        type="number"
                                                        step={1}
                                                        placeholder="Count"
                                                        className={`form-control mt-1 ms-0 w-100  ${(errors.count) ? 'is-invalid' : ''}`}
                                                        {...register("count", {
                                                            required: 'Count is required',
                                                            validate: positiveNumberValidation
                                                        })}
                                                    />
                                                    {errors.count && (
                                                        <div className="invalid-feedback">
                                                            {(errors.count as any).message}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className='mt-4'>
                                                <Col sm={12} md={3} lg={2}>
                                                    <Form.Label>Timeframe</Form.Label>
                                                </Col>
                                                <Col sm={12} md={4} lg={4}>
                                                    <input
                                                        type="hidden"
                                                        {...register("timeframe", { required: 'Timeframe is required', })}
                                                    />
                                                    <Select
                                                        className={`w-100   ${(errors.timeframe) ? 'is-invalid' : ''}`}
                                                        options={timeframeList}
                                                        value={selectedTimeframe}
                                                        onChange={(option) => handleTimeframeChange(option)}
                                                        styles={customStyles}
                                                        isDisabled={false}
                                                    />
                                                    {errors.timeframe && <div className="invalid-feedback" style={{
                                                        display: 'block',
                                                        color: '#DC3245'
                                                    }}>
                                                        Timeframe is required
                                                    </div>}
                                                </Col>
                                            </Row>
                                        </div>
                                    ) : (<span></span>)
                                }
                                <Row className='mt-4'>
                                    <Col sm={12} md={3} lg={2}>
                                        <Form.Label>Email</Form.Label>
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter your email"
                                            className={`form-control mt-1 ms-0 w-100  ${(errors.email) ? 'is-invalid' : ''}`}
                                            {...register("email", {
                                                required: "Please enter your email",
                                                pattern: {
                                                    value: /^[\w.+-]+@crbcunninghams\.co\.uk$/,
                                                    message: "Please enter a valid Email"
                                                }
                                            })}
                                        />
                                        {errors.email && <div className="invalid-feedback"
                                            style={{ display: 'block', color: '#DC3245' }}>
                                            Please enter a valid Email
                                        </div>}
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col sm={12} md={3} lg={2}></Col>
                                    <Col sm={12} md={4} lg={4}>
                                        <HubButton text="Cancel" size={"sm"} style={{ width: "100px" }}
                                            className="btn popup-btn right-margin10 btn-outline-secondary"
                                            type='reset'
                                            FnOnClick={handleCancel} />
                                        {
                                            alertId ? (<HubButton text="Update" size={"sm"} style={{ width: "100px" }}
                                                className="popup-btn btn btn-primary crbc-bg-color"
                                                type="submit" FnOnClick={() => null} />) : (
                                                <HubButton text="Create" size={"sm"} style={{ width: "100px" }}
                                                    className="popup-btn btn btn-primary crbc-bg-color"
                                                    type="submit" FnOnClick={() => null} />)
                                        }

                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                }

            </>
            <CustomModal isShow={showEstablishmentSelectModal} handleClose={handleCloseEstablishmentModal}
                header={"Establishments"}
                size="lg">
                <SelectEstablishmentModal handleClose={handleCloseEstablishmentModal}
                    handleEstablishmentFilter={handleEstablishmentFilter} />
            </CustomModal>
        </Layout>
    );
};

