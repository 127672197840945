
import { useState, } from "react";
import { Button, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TimeFilter from "../TimeFilter/TimeFilter";
import { timeUnit } from "../../app/enums";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"
interface ITimeRangeFilterProps {
    handleClose: any;
    field: any;
    StartDate: any;
    EndDate: any;
    pageIndex: number;
    pageSize: number;
    handleTimeRangeFilter: any;
    handleApplyFilter: any;
}

export default function TimeRangeFilterForm(props: ITimeRangeFilterProps) {

    const { handleSubmit } = useForm();
    const [selectedTimer, setSelectedTimer] = useState(0);
    const [selectedTimeUnit, setSelectedTimeUnit] = useState(timeUnit.minute);
    const [startDate, setStartDate] = useState(new Date());
    var defaultLastSelected = new Date(startDate);
    const [finishDate, setFinishDate] = useState(defaultLastSelected);

    function setTimes(time: any, timeUnit: timeUnit, callBackStartDate: Date, callBackfinishDate: Date, isRefresh?: boolean) {
        if (time === '') {
            return;
        }
        setSelectedTimer(time);
        setSelectedTimeUnit(timeUnit);
        setFinishDate(callBackfinishDate);
        setStartDate(callBackStartDate);
    }

    function handleApply(isApply: boolean) {
        try {
            if (isApply) {
                props.handleTimeRangeFilter(startDate.toISOString(), finishDate.toISOString(), selectedTimer, selectedTimeUnit);
                props.handleClose()
                props.handleApplyFilter();
                AddUrlFilter("startDate", startDate.toISOString());
                AddUrlFilter("finishDate", finishDate.toISOString());
                AddUrlFilter("timer", selectedTimer);
                AddUrlFilter("unit", selectedTimeUnit);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <Stack>
            <form onSubmit={handleSubmit(() => handleApply(false))} className="form">
                <TimeFilter selectedTimer={selectedTimer} selectedTimeUnit={selectedTimeUnit} setTimes={setTimes} isRefresh={false}></TimeFilter>
                <Stack direction="horizontal" className="mt-3">
                    <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" onClick={() => handleApply(true)}>
                        Apply
                    </Button>
                </Stack>
            </form>
        </Stack>
    )
}