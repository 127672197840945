import { useContext } from "react";
import AppContext from "../context/app-context";

export function useSelfRoles(){
    const appContext = useContext(AppContext);
    let permissions: any = appContext.permissions;
    if (permissions) {
        const roleIds = permissions.map((element: any) => element.roleID);
        return roleIds;
    }
    else {
        return null;
    }
}