import { AlertType, LogLevel } from "../../enums"

export const alertTypes = [
    { label: "Log Level", value: AlertType.LogLevel },
    { label: "Log Text", value: AlertType.LogText }
    /*{ label: "Device Heartbeat", value: AlertType.DeviceHeartbeat },
    { label: "Software Heartbeat", value: AlertType.SoftwareHeartbeat },
    { label: "Version Mismatch", value: AlertType.VersionMismatch },
    { label: "Duplicate Device", value: AlertType.DuplicateDevice },
    { label: "IP Address Change", value: AlertType.IPAddressChange }*/,
]

export const logLevelTypes = [
    { label: "Trace", value: LogLevel.Trace },
    { label: "Debug", value: LogLevel.Debug },
    { label: "Information", value: LogLevel.Information },
    { label: "Warning", value: LogLevel.Warning },
    { label: "Error", value: LogLevel.Error },
    { label: "Critical", value: LogLevel.Critical },
    { label: "None", value: LogLevel.None }
];