import React, { useState, useEffect } from "react";
import PagedFilterTable from "../../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { ServiceManager } from "../../../services/ServiceManager";
import IUser from "../../../models/User";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";
import { Permissions } from "../../../app/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Select, { StylesConfig } from "react-select";
import { APIStatusCode } from "../../../app/enums";
import { useQueryClient } from "@tanstack/react-query";
import HUBTooltip from "../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../app/constants";
import { useSelfRoles } from "../../../app/common/helper/SelfRoles";

export default function UsersView() {
    const [tableJobs] = useState([]);
    const [roleList, SetRoleList] = useState<any[]>([]);
    const [isRefresh, setIsRefresh] = useState<boolean>();

    const CanAssignRoles = usePermissionCheck(Permissions.CanAssignRoles);
    
    //Assign Your Own Roles Only Work
    const selfRoleIds = useSelfRoles();
    const isOptionDisabled = (option: any) => !selfRoleIds.includes(option.roleId);
    const styles: StylesConfig<any, true> = {
        multiValue: (base, state) => {
            return !selfRoleIds.includes(state.data.roleId) ? { ...base, backgroundColor: '#c0c0c6' } : base;
        },
        multiValueLabel: (base, state) => {
            return !selfRoleIds.includes(state.data.roleId)
                ? { ...base, fontWeight: 'normal', color: 'white', paddingRight: 6 }
                : { ...base, fontWeight: 'bolder' };
        },
        multiValueRemove: (base, state) => {
            return !selfRoleIds.includes(state.data.roleId) ? { ...base, display: 'none' } : base;
        },
    };

    const queryClient = useQueryClient();
    useEffect(() => {
        fetchRoleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columnHelper = createColumnHelper<IUser>();
    const columns = [
        columnHelper.accessor("displayName", {
            header: () => <span>Name</span>,
            cell: ({ row }) => <span>{row.original.displayName}</span>,
            size: 50,
        }),
        columnHelper.accessor("isActive", {
            header: () => <span>Status</span>,
            cell: ({ row }) => (
                <div className="role-users">
                    <div className={`p-1 ps-2 pe-2 ${row.original.isActive ? "active" : "disabled"}`}>
                        <FontAwesomeIcon icon={faCircle} size="2xs" />
                        <span className="ms-2 ">
                            {row.original.isActive ? "Active" : "Disabled"}
                        </span>
                    </div>
                </div>
            ),
            size: 2,
        }),
        columnHelper.accessor("emailAddress", {
            header: () => <span>Email Address</span>,
            cell: ({ row }) => <span>{row.original.emailAddress}</span>,
            size: 50,
        }),
        columnHelper.accessor("userRoles", {
            header: () => <span>Role</span>,
            cell: ({ row }) => (
                <div>
                    {
                        CanAssignRoles ? (
                            <div className="role-column">
                                <Select
                                    options={roleList.filter((current) => !row.original.userRoles.some((usrRole: any) => current.roleId === usrRole.roleID))}
                                    defaultValue={() => defaultUserRole(row.original.userRoles, row.original.loginID)}
                                    isMulti
                                    styles={styles}
                                    closeMenuOnSelect={false}
                                    menuPosition={'fixed'}
                                    menuPlacement="auto"
                                    minMenuHeight={300}
                                    isOptionDisabled={isOptionDisabled}
                                    isClearable={row.original.userRoles.some((usrRole: any) => selfRoleIds.includes(usrRole.roleID))}
                                    onChange={(option, event) => handleChange(row.original.loginID, option, event)}
                                />
                            </div>
                        ) : (
                            <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                                <div className="role-column disabled">
                                    <Select
                                        options={roleList.filter((current) => !row.original.userRoles.some((usrRole: any) => current.roleId === usrRole.roleID))}
                                        defaultValue={() => defaultUserRole(row.original.userRoles, row.original.loginID)}
                                        isMulti
                                        isDisabled
                                        styles={styles}
                                    />
                                </div>
                            </HUBTooltip>
                        )
                    }
                </div>
            ),
            size: 50,
        }),
    ];

    const defaultUserRole = (userRoles: any, loginId: any) => {
        return userRoles.map((assigRole: any) => ({
            label: assigRole.roleName,
            roleId: assigRole.roleID,
            loginID: loginId,
        }));
    };

    async function handleChange(loginId: any, option: any, event: any) {
        if (option && event.action === "select-option") {
            setIsRefresh(true);
            AssignRole(event.option.roleId, loginId);
        }
        else if (event.action === "remove-value") {
            setIsRefresh(true);
            UnAssignRole(event.removedValue.roleId, loginId);
        }
        else if (event.action === "clear" && event.removedValues) {
            setIsRefresh(true);
            return event.removedValues.map((deselected: any) => {
                if (selfRoleIds.includes(deselected.roleId)) {
                    return UnAssignRole(deselected.roleId, loginId);
                }
                setIsRefresh(false);
                return null;
            });
        }
        else if (event.action === "pop-value" && !selfRoleIds.includes(event.removedValue)) {
            return;
        }
    }

    async function AssignRole(roleId: any, loginId: any) {
        let assignResponse = await ServiceManager.RoleService.AssignUserRoles(roleId, loginId);
        if (assignResponse.data && assignResponse.status === APIStatusCode.Ok) {
            queryClient.invalidateQueries({ queryKey: ["users"] });
        } 
        else {
            let errorMessage = "";
            if (assignResponse.response) {
                errorMessage = assignResponse.response.data.errors.errors;
            } 
            else if (assignResponse.message) {
                errorMessage = assignResponse.message;
            }

            if (errorMessage) {
                ServiceManager.ToastServer.showError(errorMessage.toString());
            }
        }
        if (isRefresh) {
            setIsRefresh(false);
        }
    }

    async function UnAssignRole(roleId: any, loginId: any) {
        const deleteResponse = await ServiceManager.RoleService.UnAssignUserRoles(roleId, loginId);
        if (deleteResponse.data === "" && deleteResponse.status === APIStatusCode.NoContent) {
            queryClient.invalidateQueries({ queryKey: ["users"] });
        } else {
            let errorMessage = "";
            if (deleteResponse.response) {
                errorMessage = deleteResponse.response.data.errors.errors;
            } 
            else if (deleteResponse.message) {
                errorMessage = deleteResponse.message;
            }

            if (errorMessage) {
                ServiceManager.ToastServer.showError(errorMessage.toString());
            }
        }
        if (isRefresh) {
            setIsRefresh(false);
        }
    }

    async function fetchRoleData() {
        const roleResponse = await ServiceManager.RoleService.GetAll();
        let arrayObj: any[] = [];
        roleResponse.data.forEach((role: any) => {
            arrayObj.push({
                value: role.roleName,
                label: role.roleName,
                roleId: role.roleID,
                isSelfFixed: selfRoleIds.includes(role.roleID) ? false : true
            });
        });
        SetRoleList(arrayObj);
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        let IncludeActive = true;
        let IncludeInactive = false;
        let IncludeRoles = true;
        let Username = "";
        let DisplayName = options.searchValue ? options.searchValue : "";
        let EmailAddress = options.searchValue ? options.searchValue : "";
        const response = await ServiceManager.UserService.GetAll(
            options.pageIndex + 1,
            options.pageSize,
            IncludeActive,
            IncludeInactive,
            IncludeRoles,
            Username,
            DisplayName,
            EmailAddress
        );
        await fetchRoleData();
        return response ? response.data.users : null;
    }

    return (
        <div id="userTable" className="mt-3">
            <PagedFilterTable
                tableKey={"users"}
                columns={columns}
                data={tableJobs}
                usePagination={true}
                useSearch={true}
                pageRecords={10}
                fnFetchData={fetchData}
                title={
                    <>
                        {" "}
                        <h5 className="ps-3 pt-3 pb-2">
                            <b>Users</b>
                        </h5>{" "}
                    </>
                }
            />
        </div>
    );
}
