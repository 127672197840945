
import { useQuery } from "@tanstack/react-query";
import AppContext from "../../app/common/context/app-context";
import AppRoutes from "../routes";
import { ServiceManager } from "../../services/ServiceManager";
import { GUID } from "../../app/common/helper/Guid";

interface IContextProvider{
    loginId: GUID
}

export default function AppContextProvider(props: IContextProvider) {

    const { isLoading, data } = useQuery(["permission" + props.loginId], () =>
        ServiceManager.UserService.GetProfileByLoginId(props.loginId), { refetchOnWindowFocus: false }
    );

    return(
        isLoading ? <></> :
        <AppContext.Provider value={{ permissions: data.data === null ? null : data.data.roles }}>
            <AppRoutes />
        </AppContext.Provider>
    );
};