import BaseService from "./BaseService";

export default class EstablishmentService extends BaseService {
  controllerName = "establishments";

  async GetAll(
    includeDisabled: boolean,
    page: number,
    pageSize: number,
    search: string = ''
  ): Promise<any> {
    const resp = await this.Get("", {
      includeDisabled: includeDisabled,
      page: page,
      pageSize: pageSize,
      search: search
    });

    return resp ? resp.data : null;
  }

async GetAllWithChildern(
    includeDisabled: boolean,
    page: number,
    pageSize: number,
    search: string = ''
  ): Promise<any> {
    const resp = await this.Get("/all", {
      includeDisabled: includeDisabled,
      page: page,
      pageSize: pageSize,
      search: search
    });

    return resp ? resp.data : null;
  }

  async GetById(establishmentId: any, includeChildren: boolean): Promise<any> {
    const resp = await this.Get(`${establishmentId}`, {
      includeChildren: includeChildren,
    });

    return resp ? resp.data : null;
  }

  async GetDevicesById(establishmentId: any, search: string = "", productId: any = "", installedVersion: any = "", availableVersion : any = "", dateHeartbeat: any = "", dateLastUpdateAttempt: any = ""): Promise<any> {
    const resp = await this.Get(`${establishmentId}/devices`, { search, productId, installedVersion, availableVersion, dateHeartbeat, dateLastUpdateAttempt })
    return resp ? resp.data : null;
  }

  async GetProductsById(establishmentId: any): Promise<any> {
    const resp = await this.Get(`${establishmentId}/products`, { });
    return resp ? resp.data : null;
  }

  async UpdateDevice(establishmentId: any, deviceId: any,  device: object): Promise<any> {
    const resp = await this.Patch(`${establishmentId}/devices/${deviceId}`, device);

    return resp.data ? resp.data : resp;
  }

  async GetInstalledPackagesByIds(establishmentId: any, deviceId: any): Promise<any> {
    const resp = await this.Get(`${establishmentId}/devices/${deviceId}/packages`, {});

    return resp ? resp.data : null;
  }

  async Synchronise(accountId : any) : Promise<any> {
    const resp = await this.Patch(`account/${accountId}`, {});

    return resp.data ? resp.data : resp;
  }

  async CreateProduct(establishmentId: any, productId : any): Promise<any> {
    const resp = await this.Post(`${establishmentId}/products/${productId}`, {});
    return resp.data ? resp.data : resp;
  }

  async DeleteProduct(establishmentId: any, productId : any): Promise<any> {
    const resp = await this.Delete(`${establishmentId}/products/${productId}`);
    return resp.data ? resp.data : resp;
  }

  async GetByAccountId(accountId : any, includeChildren: boolean): Promise<any> {
    const resp = await this.Get(`account/${accountId}`, {
      includeChildren: includeChildren
    });

    return resp.data ? resp.data : resp;
  }

  async GetClientsById(establishmentId : any): Promise<any> {
    const resp = await this.Get(`${establishmentId}/clients`, { });
    return resp ? resp.data : null;
  }

  async CreateClient(establishmentId : any): Promise<any> {
    const resp = await this.Post(`${establishmentId}/clients`, {});
    return resp.data ? resp.data : resp;
  }

  async DeleteClient(establishmentId : any, clientId : any): Promise<any> {
    const resp = await this.Delete(`${establishmentId}/clients/${clientId}`);
    return resp.data ? resp.data : resp;
  }

  async AssignRing(establishmentId: any, ringId: any): Promise<any> {
    const resp = await this.Put(`${establishmentId}/rings/${ringId}`,{});
    return resp.data ? resp.data : resp;
  }

  async GetInstalledPackagesById(establishmentId: any): Promise<any> {
    const resp = await this.Get(`${establishmentId}/packages`, {});

    return resp ? resp.data : null;
  }
}
