import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Nav, Row, Stack, Tab } from "react-bootstrap";
import ReactSwitch from "react-switch";
import { useState } from "react";
import IRole from "../../../models/Role";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../services/ServiceManager";
import { APIStatusCode, Permissions } from "../../../app/enums";
import HubButton from "../../../components/button";
import { useNavigate, useParams } from "react-router-dom"
import Skeleton from "react-loading-skeleton";
import RoleUsers from "./role-users";
import { permissions } from "../../../app/common/data/Permissions";
import CustomModal from "../../../components/modal";
import IconElement from "../../../components/icon";
import HUBSpinner from "../../../components/spinner";
import HUBTooltip from "../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../app/constants";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";

export default function EditRole() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const { roleId } = useParams();
    const queryClient = useQueryClient();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [defaultForUsers, setDefaultForUsers] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const canViewUsers = usePermissionCheck(Permissions.CanViewUsers);
    const [key, setKey] = useState("permissions");

    const localPermissions = permissions;
    const columnSize = Math.ceil(localPermissions.length / 3);
    
    // Define the ranges for each column
    const firstColumn = localPermissions.slice(0, columnSize);
    const secondColumn = localPermissions.slice(columnSize, columnSize * 2);
    const thirdColumn = localPermissions.slice(columnSize * 2);

    const TooltipTopNavItem = ({ title, eventKey, enable }: any) => {
        return (
            enable ?
                (
                    <Nav.Item>
                        <Nav.Link eventKey={eventKey} disabled={!enable}>{title}</Nav.Link>
                    </Nav.Item>
                )
                :
                (
                    <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                        <Nav.Item className="disabled">
                            <Nav.Link eventKey={eventKey} disabled={!enable}><span
                                className="disabled">{title}</span></Nav.Link>
                        </Nav.Item>
                    </HUBTooltip>
                )
        )
    }

    const mutation = useMutation({
        mutationFn: (role: IRole) => {
            return ServiceManager.RoleService.Update(roleId, role);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastServer.showError("Unable to update role");
            } else {

                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastServer.showSuccess("Role updated successfully");
                    queryClient.invalidateQueries({ queryKey: ["roles-table"] });
                    queryClient.invalidateQueries({ queryKey: [`role-${roleId}`] });
                } else {
                    let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                }
            }
        }
    });

    const { isLoading, data } = useQuery([`role-${roleId}`], () =>
        ServiceManager.RoleService.GetById(roleId), {
        refetchOnWindowFocus: false
    }
    );

    const handlePermissionToggle = (permissionId: string) => {
        setSelectedPermissions((prevSelected: any) => {
            if (prevSelected.includes(permissionId)) {
                return prevSelected.filter((id: any) => id !== permissionId);
            } else {
                return [...prevSelected, permissionId];
            }
        });
    };

    const onSubmit = (data: any) => {
        if (selectedPermissions.length === 0) {
            ServiceManager.ToastServer.showError("Please select at least one permission");
            return;
        }

        const role: IRole = {
            roleName: data.roleName,
            description: data.description,
            defaultForUsers: defaultForUsers,
            canEditEstablishments: selectedPermissions.includes("canEditEstablishments"),
            canViewEstablishments: selectedPermissions.includes("canViewEstablishments"),
            canEditProducts: selectedPermissions.includes("canEditProducts"),
            canViewProducts: selectedPermissions.includes("canViewProducts"),
            canAssignProducts: selectedPermissions.includes("canAssignProducts"),
            canManageRoles: selectedPermissions.includes("canManageRoles"),
            canAssignRoles: selectedPermissions.includes("canAssignRoles"),
            canViewUsers: selectedPermissions.includes("canViewUsers"),
            canManageRings: selectedPermissions.includes("canManageRings"),
            canAssignPrivateRings: selectedPermissions.includes("canAssignPrivateRings"),
            canAssignPublicRings: selectedPermissions.includes("canAssignPublicRings"),
            canViewRings: selectedPermissions.includes("canViewRings"),
            canManageDevices: selectedPermissions.includes("canManageDevices"),
            canManageAlerts: selectedPermissions.includes("canManageAlerts")
        }
        mutation.mutate(role);
    };


    useEffect(() => {
        if (!isLoading && data != null && data.data != null) {
            const rolePermissions = Object.keys(data.data).filter(permission => localPermissions.find(i => i.id === permission) && data.data[permission]);
            setSelectedPermissions(rolePermissions);

            setDefaultForUsers(data.data.defaultForUsers);
        }

    }, [localPermissions, isLoading, data]);

    const RoleDeleteConfirmationForm = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: (roleId: any) => {
                return ServiceManager.RoleService.DeleteById(roleId);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastServer.showError("Unable to delete role");
                } else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastServer.showSuccess("Role deleted successfully");
                        setShowDeleteModal(false);
                        queryClient.invalidateQueries();
                        navigate("/setting?tabKey=roles");
                    } else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });

        const deleteRole = () => {
            mutation.mutate(roleId);
        };

        return (
            <Stack>
                <h5 className="text-center mt-2" style={{ fontSize: '18px' }}>Delete role</h5>
                <p className="text-center text-muted">Are you sure you want to delete this role? This action cannot be
                    undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <Button variant="popup-btn right-margin10 btn-outline-secondary w-50"
                                onClick={handleCloseDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="popup-btn right-margin10 btn-danger w-50" onClick={deleteRole}>
                                Delete
                            </Button>
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <HUBSpinner color="crbc-color" />
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout title="Role & Permissions"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate("/setting?tabKey=roles")}
                    className="me-4 fs-5 cursor-pointer" />
            }
            button={<HubButton text="Delete Role"
                className="btn btn-outline-danger"
                FnOnClick={() => setShowDeleteModal(true)}
                icon={<FontAwesomeIcon icon={faTrashAlt} />}></HubButton>
            }
        >
            {!isLoading && data != null && data.data != null &&
                <form>
                    <label className="mt-1 label-text ms-0">Role name</label>
                    <input {...register("roleName", { required: true })} defaultValue={data.data.roleName}
                        placeholder="Enter role name here" maxLength={16}
                        className={`form-control w-40 mt-1 ms-0 ${(errors.roleName) ? 'is-invalid' : ''}`} />
                    {errors.roleName && <div className="invalid-feedback">The role name field is required</div>}

                    <label className="mt-4 label-text ms-0">Description</label>
                    <input {...register("description")} defaultValue={data.data.description}
                        placeholder="Enter role description here" maxLength={50}
                        className={`form-control w-40 mt-1 ms-0`} />

                    <div className="d-flex align-items-center mt-3 ms-0">
                        <label>Default for User</label>
                        <ReactSwitch height={20} onColor="#10A760" offColor="#E2E2E3"
                            handleDiameter={17} width={40} checkedIcon={false}
                            uncheckedIcon={false} checked={defaultForUsers} className="ms-4"
                            onChange={(event) => setDefaultForUsers(event)} key="defaultForUsers" />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <Tab.Container id="settingTabs" defaultActiveKey={key}>
                            <Row className="mt-2">
                                <Col sm={12}>
                                    <Nav variant="tabs" onSelect={(k: any) => setKey(k)}>
                                        <TooltipTopNavItem title={'Permissions'} eventKey={'permissions'}
                                            enable={true} />
                                        <TooltipTopNavItem title={'Users'} eventKey={'users'} enable={canViewUsers} />
                                    </Nav>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="permissions">{key === "permissions" &&
                                            <Stack direction="horizontal" className="permission-box">
                                                <Stack direction="vertical" gap={3}>
                                                    {firstColumn.map((permission) => (
                                                        <div key={permission.id}
                                                            className="d-flex align-items-center permission-item pb-3">
                                                            <label className="w-50">{permission.name}</label>
                                                            <ReactSwitch className="" height={20} onColor="#10A760"
                                                                offColor="#E2E2E3"
                                                                handleDiameter={17} width={40}
                                                                checkedIcon={false}
                                                                uncheckedIcon={false}
                                                                checked={selectedPermissions.includes(permission.id)}
                                                                onChange={() => handlePermissionToggle(permission.id)} />
                                                        </div>
                                                    ))}
                                                </Stack>
                                                <Stack direction="vertical" gap={3}>
                                                    {secondColumn.map((permission) => (
                                                        <div key={permission.id}
                                                            className="d-flex align-items-center permission-item pb-3">
                                                            <label className="w-50">{permission.name}</label>
                                                            <ReactSwitch className="" height={20} onColor="#10A760"
                                                                offColor="#E2E2E3"
                                                                handleDiameter={17} width={40}
                                                                checkedIcon={false}
                                                                uncheckedIcon={false}
                                                                checked={selectedPermissions.includes(permission.id)}
                                                                onChange={() => handlePermissionToggle(permission.id)} />
                                                        </div>
                                                    ))}
                                                </Stack>
                                                <Stack direction="vertical" gap={3}>
                                                    {thirdColumn.map((permission) => (
                                                        <div key={permission.id}
                                                            className="d-flex align-items-center permission-item-right pb-3">
                                                            <label className="w-50">{permission.name}</label>
                                                            <ReactSwitch className="" height={20} onColor="#10A760"
                                                                offColor="#E2E2E3"
                                                                handleDiameter={17} width={40}
                                                                checkedIcon={false}
                                                                uncheckedIcon={false}
                                                                checked={selectedPermissions.includes(permission.id)}
                                                                onChange={() => handlePermissionToggle(permission.id)} />
                                                        </div>
                                                    ))}
                                                </Stack>
                                            </Stack>}
                                            {
                                                !mutation.isLoading
                                                    ? (<Stack direction="horizontal" className="mt-4 w-25">
                                                        <HubButton text="Cancel"
                                                            className="btn popup-btn right-margin10 btn-outline-secondary w-100"
                                                            FnOnClick={() => navigate("/setting?tabKey=roles")} />
                                                        <HubButton text="Save"
                                                            className="popup-btn btn btn-primary crbc-bg-color w-100"
                                                            type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                                    </Stack>
                                                    ) : (
                                                        <div className="d-flex justify-content-center mt-3 w-25">
                                                            <HUBSpinner color="crbc-color" />
                                                        </div>
                                                    )
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="users">{key === "users" &&
                                            <RoleUsers roleId={roleId} roleName={data.data.roleName} />
                                        }</Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </form>
            }
            {isLoading &&
                <React.Fragment>
                    <div className="w-25">
                        <Skeleton height={20} count={2} />
                    </div>
                    <div className="mt-3 w-25">
                        <Skeleton height={20} count={2} />
                    </div>
                    <div className="mt-5">
                        <Skeleton height={20} count={6} />
                    </div>
                </React.Fragment>
            }

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal}
                header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />}
                size="md">
                <RoleDeleteConfirmationForm />
            </CustomModal>
        </Layout>
    );

}