import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Layout from "../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/button";
import { ServiceManager } from "../../services/ServiceManager";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EstablishmentDevices from "./devices";
import Skeleton from "react-loading-skeleton";
import ChildEstablishments from "./child-establishments";
import { useMutation } from "@tanstack/react-query";
import { APIStatusCode, timeUnit, Permissions } from "../../app/enums";
import EstablishmentProducts from "./products";
import TimeFilter from "../../components/TimeFilter/TimeFilter";
import MessageStats from "../../components/messagestats/messagestats";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED, SYNC_SALESFORCE_BUTTON_TITLE } from "../../app/constants";
import EstablishmentClient from "./client";
import EstablishmentsRing from "./ring";
import { useLocation, NavLink } from 'react-router-dom'

export default function EstablishmentDetail(props: any) {

    let navigate = useNavigate();

    const [salesforceExternalId, setSalesforceExternalId] = useState('');
    const [selectedTimer, setSelectedTimer] = useState(30);
    const [selectedTimeUnit, setselectedTimeUnit] = useState(timeUnit.minute);
    const [finishDate, setFinishDate] = useState(new Date());
    var defaultStartDate = new Date();
    defaultStartDate.setMinutes(defaultStartDate.getMinutes() - 30);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [activeKey, setActiveKey] = useState('devices');
    const [establishmentName, setEstablishmentName] = useState('');

    let location = useLocation();
    let state = location.state;

    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);
    const queryClient = useQueryClient();
    const { EstablishmentId } = useParams();

    const { isLoading, data } = useQuery(["establishmentDetail-" + EstablishmentId], () =>
        ServiceManager.EstablishmentService.GetById(EstablishmentId, true), {
        refetchOnWindowFocus: false
    }
    );

    useEffect(() => {
        if (!isLoading && data.data != null) {
            setSalesforceExternalId(data.data.salesForceExternalID);
            setEstablishmentName(data.data.name);
        }
    }, [isLoading, data]);

    const mutation = useMutation({
        mutationFn: () => {
            return ServiceManager.EstablishmentService.Synchronise(salesforceExternalId);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastServer.showError("Unable to synchronise establishment with Salesforce");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    queryClient.invalidateQueries();
                    ServiceManager.ToastServer.showSuccess("Establishment successfully synced with Salesforce");
                }
                else {
                    if (data.response.status === APIStatusCode.NotFound) {
                        queryClient.invalidateQueries();
                        navigate(-1);
                        ServiceManager.ToastServer.showError("Establishment not found in salesforce, it is now disabled.");
                    }
                    else {
                        let errorMessage = data.response.data.errors.errors
                            ? data.response.data.errors.errors
                            : "Unable to move device";
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        }
    });

    async function synchronise() {
        mutation.mutate();
    }
    function setTimes(time: any, timeUnit: timeUnit, callBackStartDate: Date, callBackfinishDate: Date, isRefresh?: boolean) {
        if (time === '') {
            return;
        }

        setSelectedTimer(time);
        setselectedTimeUnit(timeUnit);
        setFinishDate(callBackfinishDate);
        setStartDate(callBackStartDate);
    }
    return (
        <Layout title={data?.data?.name ?? ""}
            backArrow={
                <span>
                    {(state && state.from && state.from === 'assigned-estb') ?
                        (<NavLink to="/ring" className="establishments-nav-link">
                            <FontAwesomeIcon icon={faArrowLeft} className="me-4 fs-5 cursor-pointer" />
                        </NavLink>) :
                        (<NavLink to="/establishment" className="establishments-nav-link">
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(`/establishment`)} className="me-4 fs-5 cursor-pointer" />
                        </NavLink>)
                    }
                </span>

            }
            button={
                canEditEstablishments
                    ?
                    <div>
                        <Button text="Sync Salesforce"
                            className="btn btn-secondary"
                            title={SYNC_SALESFORCE_BUTTON_TITLE}
                            FnOnClick={synchronise}
                            icon={<FontAwesomeIcon icon={faRefresh} className={`${mutation.isLoading ? "spin" : ""}`} />}></Button>
                    </div>

                    :
                    <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div>
                            <Button text="Sync Salesforce"
                                className="btn btn-secondary"
                                FnOnClick={synchronise}
                                disabled
                                icon={<FontAwesomeIcon icon={faRefresh} />}></Button>
                        </div>
                    </HUBTooltip>
            }
        >
            {!isLoading && data != null && data.data != null &&
                <React.Fragment>
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="row">
                                <TimeFilter selectedTimer={selectedTimer} selectedTimeUnit={selectedTimeUnit} setTimes={setTimes} isRefresh={true}></TimeFilter>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-6">
                                    <MessageStats selectedTimer={selectedTimer} startDate={startDate} finishDate={finishDate} establishmentId={EstablishmentId} navigateTo={`/message?startDate=${startDate.toISOString()}&finishDate=${finishDate.toISOString()}&establishmentId=${EstablishmentId}&establishmentName=${establishmentName}&timer=${selectedTimer}&unit=${selectedTimeUnit}`}></MessageStats>
                                </div>
                                <div className="col-xl-4 col-6">
                                    <EstablishmentsRing establishmentId={EstablishmentId} ringId={data.data.ringID} ringName={data.data.ringName} navigateTo={`/ring`}></EstablishmentsRing>
                                </div>
                                {
                                    data.data.parent === null &&
                                    <div className="col-xl-4 col-6">
                                        <EstablishmentClient establishmentId={EstablishmentId}></EstablishmentClient>
                                    </div>
                                }

                            </div>
                        </div>
                    </React.Fragment>

                    <React.Fragment>
                        <Tabs
                            activeKey={activeKey}
                            onSelect={(key: any) => setActiveKey(key)}
                            className="mt-4"
                        >
                            <Tab eventKey="products" title="Products">
                                {activeKey === 'products' && <EstablishmentProducts establishmentId={EstablishmentId} childEstablishments={data.data.children} isParent={data.data.parent ? false : true} />}
                            </Tab>
                            <Tab eventKey="devices" title="Devices">
                                {activeKey === 'devices' && <EstablishmentDevices establishmentId={EstablishmentId} establishmentData={data.data} startDateTime={startDate} endDateTime={finishDate} selectedTimer={selectedTimer} timeUnit={selectedTimeUnit} />}
                            </Tab>
                            <Tab eventKey="childEstablishments" title="Child Establishments">
                                {activeKey === 'childEstablishments' && <ChildEstablishments childEstablishments={data.data.children} />}
                            </Tab>
                        </Tabs>
                    </React.Fragment>
                </React.Fragment>
            }
            {isLoading &&
                <React.Fragment>
                    <div className="w-25">
                        <Skeleton height={20} count={4} />
                    </div>
                    <div className="mt-5">
                        <Skeleton height={20} count={6} />
                    </div>
                </React.Fragment>
            }
        </Layout>
    );
}