import { useState, } from "react";
import { Button, Dropdown, DropdownButton, Stack } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { LogLevel } from "../../app/enums";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface ILoglevelFilterProps {
    handleClose: any;
    field: any;
    startDate: any;
    endDate: any;
    pageIndex: number;
    pageSize: number;
    handleLogLevelFilter: any;
    handleApplyFilter: any;
}
export default function LoglevelFilter(props: ILoglevelFilterProps) {

    const [Selected, setSelected] = useState("Log Level");
    const [selectedLogLevel, setselectedLogLevel] = useState(0);
    const { register, handleSubmit } = useForm();

    const onSubmit = (data: any) => {
        props.handleLogLevelFilter(selectedLogLevel);
        props.handleApplyFilter();
        props.handleClose(false);
        AddUrlFilter("logLevel", selectedLogLevel);
    };

    const handleSelect = (event: any) => {
        setselectedLogLevel(Number(LogLevel[event]))
        setSelected(event);

    }

    return (
        <Stack>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <DropdownButton
                    {...register(Selected)}
                    title={Selected}
                    variant="btn-outline-secondary border border-secondary"
                    id="dropdown-menu-align-right"
                    onSelect={handleSelect}
                >
                    <Dropdown.Item eventKey={LogLevel[LogLevel.Trace]}>{LogLevel[LogLevel.Trace]}</Dropdown.Item>
                    <Dropdown.Item eventKey={LogLevel[LogLevel.Debug]}>{LogLevel[LogLevel.Debug]}</Dropdown.Item>
                    <Dropdown.Item eventKey={LogLevel[LogLevel.Information]}>{LogLevel[LogLevel.Information]}</Dropdown.Item>
                    <Dropdown.Item eventKey={LogLevel[LogLevel.Warning]}>{LogLevel[LogLevel.Warning]}</Dropdown.Item>
                    <Dropdown.Item eventKey={LogLevel[LogLevel.Error]}>{LogLevel[LogLevel.Error]}</Dropdown.Item>
                    <Dropdown.Item eventKey={LogLevel[LogLevel.Critical]}>{LogLevel[LogLevel.Critical]}</Dropdown.Item>
                    <Dropdown.Item eventKey={LogLevel[LogLevel.None]}>{LogLevel[LogLevel.None]}</Dropdown.Item>
                    <Dropdown.Divider />
                </DropdownButton>
                <Stack direction="horizontal" className="mt-3">
                    <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
                        Apply
                    </Button>
                </Stack>
            </form>
        </Stack>
    )
}