import { Route, Routes } from "react-router-dom";
import Establishment from "../../pages/establishments";
import Message from "../../pages/message";
import Product from "../../pages/products";
import Dashboard from "../../pages/dashboard";
import EstablishmentDetail from "../../pages/establishments/detail";
import Login from "../../pages/login";
import ProtectedRoute from "../../pages/login/protected-routes";
import ProductDetail from "../../pages/products/details";
import PackageDetail from "../../pages/packages/details";
import Unauthorised from "../../pages/error/Unauthorised";
import { Permissions } from "../../app/enums";
import Settings from "../../pages/settings";
import CreateRole from "../../pages/settings/roles/create-role";
import EditRole from "../../pages/settings/roles/edit-role";
import Ring from "../../pages/rings";
import RingProducts from "../../pages/rings/products";
import Alert from "../../pages/alerts";
import CreateNewAlert from "../../pages/alerts/create-alert";

export default function AppRoutes() {

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/establishment" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><Establishment /></ProtectedRoute>} />
      <Route path="/message" element={<ProtectedRoute><Message /></ProtectedRoute>} />
      <Route path="/establishment/:EstablishmentId" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><EstablishmentDetail /></ProtectedRoute>} />
      <Route path="/product" element={<ProtectedRoute permission={Permissions.CanViewProducts}><Product /></ProtectedRoute>} />
      <Route path="/product/:ProductId/:ProductName" element={<ProtectedRoute permission={Permissions.CanViewProducts}><ProductDetail /></ProtectedRoute>} />
      <Route path="/product/package/:PackageId/:PackageName" element={<ProtectedRoute permission={Permissions.CanViewProducts}><PackageDetail /></ProtectedRoute>} />
      <Route path="/setting" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
      <Route path="/role/create" element={<ProtectedRoute permission={Permissions.CanManageRoles}><CreateRole /></ProtectedRoute>} />
      <Route path="/role/:roleId" element={<ProtectedRoute permission={Permissions.CanManageRoles}><EditRole /></ProtectedRoute>} />
      <Route path="/ring" element={<ProtectedRoute permission={Permissions.CanViewRings}><Ring /></ProtectedRoute>} />
      <Route path="/ring/:ringId" element={<ProtectedRoute permission={Permissions.CanViewRings}><RingProducts /></ProtectedRoute>} />
      <Route path="/alert" element={<ProtectedRoute permission={Permissions.CanManageAlerts}><Alert /></ProtectedRoute>} />
      <Route path="/alert/create" element={<ProtectedRoute permission={Permissions.CanManageAlerts}><CreateNewAlert /></ProtectedRoute>} />
      <Route path="/alert/:alertId/edit" element={<ProtectedRoute permission={Permissions.CanManageAlerts}><CreateNewAlert /></ProtectedRoute>} />
      <Route path="/error/unauthorised" element={<Unauthorised />} />
    </Routes>
  );
}
