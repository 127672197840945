import {useForm} from "react-hook-form";
import Layout from "../../layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Stack} from "react-bootstrap";
import ReactSwitch from "react-switch";
import {useState} from "react";
import IRole from "../../../models/Role";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ServiceManager} from "../../../services/ServiceManager";
import {APIStatusCode} from "../../../app/enums";
import HubButton from "../../../components/button";
import {useNavigate} from "react-router-dom"
import {permissions} from "../../../app/common/data/Permissions";
import HUBSpinner from "../../../components/spinner";

export default function CreateRole() {
    const navigate = useNavigate();
    const {register, handleSubmit, reset, formState: {errors},} = useForm();
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [defaultForUsers, setDefaultForUsers] = useState(false);
    const queryClient = useQueryClient();

    const localPermissions = permissions;
    const columnSize = Math.ceil(localPermissions.length / 3);
    
    // Define the ranges for each column
    const firstColumn = localPermissions.slice(0, columnSize);
    const secondColumn = localPermissions.slice(columnSize, columnSize * 2);
    const thirdColumn = localPermissions.slice(columnSize * 2);

    const mutation = useMutation({
        mutationFn: (role: IRole) => {
            return ServiceManager.RoleService.Create(role);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastServer.showError("Unable to create role");
            } else {

                if (data.status === APIStatusCode.Created) {
                    ServiceManager.ToastServer.showSuccess("Role created successfully");
                    reset();
                    setSelectedPermissions([]);
                    queryClient.invalidateQueries({queryKey: ["roles-table"]});
                    navigate("/setting?tabKey=roles");
                } else {
                    let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                }
            }
        }
    });

    const handlePermissionToggle = (permissionId: string) => {
        setSelectedPermissions((prevSelected: any) => {
            if (prevSelected.includes(permissionId)) {
                return prevSelected.filter((id: any) => id !== permissionId);
            } else {
                return [...prevSelected, permissionId];
            }
        });
    };

    const onSubmit = (data: any) => {
        if (selectedPermissions.length === 0) {
            ServiceManager.ToastServer.showError("Please select at least one permission");
            return;
        }

        const role: IRole = {
            roleName: data.roleName,
            description: data.description,
            defaultForUsers: defaultForUsers,
            canEditEstablishments: selectedPermissions.includes("canEditEstablishments"),
            canViewEstablishments: selectedPermissions.includes("canViewEstablishments"),
            canEditProducts: selectedPermissions.includes("canEditProducts"),
            canViewProducts: selectedPermissions.includes("canViewProducts"),
            canAssignProducts: selectedPermissions.includes("canAssignProducts"),
            canManageRoles: selectedPermissions.includes("canManageRoles"),
            canAssignRoles: selectedPermissions.includes("canAssignRoles"),
            canViewUsers: selectedPermissions.includes("canViewUsers"),
            canManageRings: selectedPermissions.includes("canManageRings"),
            canAssignPrivateRings: selectedPermissions.includes("canAssignPrivateRings"),
            canAssignPublicRings: selectedPermissions.includes("canAssignPublicRings"),
            canViewRings: selectedPermissions.includes("canViewRings"),
            canManageDevices: selectedPermissions.includes("canManageDevices"),
            canManageAlerts: selectedPermissions.includes("canManageAlerts")
        };

        mutation.mutate(role);
    };

    return (
        <Layout title="Create New Role"
                backArrow={
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate("/setting?tabKey=roles")}
                                     className="me-4 fs-5 cursor-pointer"/>
                }>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label className="mt-1 label-text ms-0">Role name</label>
                <input {...register("roleName", {required: true})} placeholder="Enter role name here" maxLength={16}
                       className={`form-control w-40 mt-1 ms-0 ${(errors.roleName) ? 'is-invalid' : ''}`}/>
                {errors.roleName && <div className="invalid-feedback">The role name field is required</div>}

                <label className="mt-4 label-text ms-0">Description</label>
                <input {...register("description")} placeholder="Enter role description here" maxLength={50}
                       className={`form-control w-40 mt-1 ms-0`}/>

                <div className="d-flex align-items-center mt-3 ms-0">
                    <label>Default for User</label>
                    <ReactSwitch height={20} onColor="#10A760" offColor="#E2E2E3"
                                 handleDiameter={17} width={40} checkedIcon={false}
                                 uncheckedIcon={false} checked={defaultForUsers} className="ms-4"
                                 onChange={(event) => setDefaultForUsers(event)} key="defaultForUsers"/>
                </div>

                <div className="mt-4">
                    <label className="label-text ms-0">Role permissions</label>
                    <Stack direction="horizontal" className="permission-box mt-1">
                        <Stack direction="vertical" gap={3}>
                            {firstColumn.map((permission) => (
                                <div key={permission.id} className="d-flex align-items-center permission-item pb-3">
                                    <label className="w-50">{permission.name}</label>
                                    <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                                 handleDiameter={17} width={40} checkedIcon={false}
                                                 uncheckedIcon={false}
                                                 checked={selectedPermissions.includes(permission.id)}
                                                 onChange={() => handlePermissionToggle(permission.id)}/>
                                </div>
                            ))}
                        </Stack>
                        <Stack direction="vertical" gap={3}>
                            {secondColumn.map((permission) => (
                                <div key={permission.id} className="d-flex align-items-center permission-item pb-3">
                                    <label className="w-50">{permission.name}</label>
                                    <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                                 handleDiameter={17} width={40} checkedIcon={false}
                                                 uncheckedIcon={false}
                                                 checked={selectedPermissions.includes(permission.id)}
                                                 onChange={() => handlePermissionToggle(permission.id)}/>
                                </div>
                            ))}
                        </Stack>
                        <Stack direction="vertical" gap={3}>
                            {thirdColumn.map((permission) => (
                                <div key={permission.id}
                                     className="d-flex align-items-center permission-item-right pb-3">
                                    <label className="w-50">{permission.name}</label>
                                    <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                                 handleDiameter={17} width={40} checkedIcon={false}
                                                 uncheckedIcon={false}
                                                 checked={selectedPermissions.includes(permission.id)}
                                                 onChange={() => handlePermissionToggle(permission.id)}/>
                                </div>
                            ))}
                        </Stack>
                    </Stack>
                </div>

                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-4 w-25">
                                <HubButton text="Cancel"
                                           className="btn popup-btn right-margin10 btn-outline-secondary w-100"
                                           FnOnClick={() => navigate('/setting?tabKey=roles')}/>
                                <HubButton text="Save" className="popup-btn btn btn-primary crbc-bg-color w-100"
                                           type="submit" FnOnClick={handleSubmit(onSubmit)}/>
                            </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3 w-25">
                                <HUBSpinner color="crbc-color"/>
                            </div>
                        )
                }
            </form>
        </Layout>
    );

}