import { useContext } from "react";
import { Permissions } from "../../enums";
import AppContext from "../context/app-context";

export function usePermissionCheck(permission: Permissions){
    const appContext = useContext(AppContext);
    let permissions: any = appContext.permissions;
    if (permissions) {
        const isPermissionFound = permissions.findIndex((element: any) => element[permission] === true);
        return isPermissionFound > -1;
    }
    else {
        return false;
    }
}