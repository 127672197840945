export const permissions = [
    { id: 'canEditEstablishments', name: 'Create Establishments' },
    { id: 'canEditProducts', name: 'Edit Products' },
    { id: 'canAssignProducts', name: 'Assign Products' },
    { id: 'canAssignRoles', name: 'Assign Roles' },
    { id: 'canViewEstablishments', name: 'View Establishments' },
    { id: 'canViewProducts', name: 'View Products' },
    { id: 'canManageRoles', name: 'Manage Roles' },
    { id: 'canViewUsers', name: 'View Users' },
    { id: 'canManageRings', name: 'Manage Rings' },
    { id: 'canManageAlerts', name: 'Manage Alerts' },
    { id: 'canAssignPrivateRings', name: 'Assign Private Rings' },
    { id: 'canAssignPublicRings', name: 'Assign Public Rings' },
    { id: 'canViewRings', name: 'View Rings' },
    { id: 'canManageDevices', name: 'Manage Devices' }
];