import React, { useEffect, useState } from 'react';
import './assets/css/app.scss'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";
import Toast from './components/toaster';
import { useAuth } from "react-oidc-context";
import CustomModal from "./components/modal";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import IconElement from "./components/icon";
import { Stack, Button } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { setAxiosTokenInterceptor } from './services/ServiceManager';
import AppContextProvider from './components/context/app-context-provider';
import AppRoutes from './components/routes';
import { useIdleTimer } from 'react-idle-timer';

function App() {
  const [showTokenExpireModal, setShowTokenExpireModal] = useState(false);
  const handleTokenExpireModal = () => setShowTokenExpireModal(false);
  const auth = useAuth();
  const [isStateActive, setIsStateActive] = useState<boolean>(true);

  const onIdle = () => {
    setIsStateActive(false);
  }

  const onActive = () => {
    setIsStateActive(true);
  }

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 900000,
    throttle: 500
  });

  var jwt: any;
  if (auth.isAuthenticated) {
    jwt = jwt_decode(auth.user?.access_token ?? '', { header: false });
  }

  useEffect(() => {
    if(auth.user?.access_token) {
      setAxiosTokenInterceptor(auth.user?.access_token);
    }
  }, [auth.user?.access_token])

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      if(isStateActive && !showTokenExpireModal && !auth.isLoading) {
        auth.signinSilent();
        setShowTokenExpireModal(false);
       }
       else if(!isStateActive && !auth.isLoading) {
        setShowTokenExpireModal(true);
       }
    })
  }, [auth, auth.events, auth.signinSilent, isStateActive, showTokenExpireModal]);

  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      auth.signoutRedirect();
    })
  }, [auth, auth.events, auth.signoutRedirect]);

  const TokenRefreshForm = () => {
    const auth = useAuth();
    const handleResetToken = () => { auth.signinSilent(); setShowTokenExpireModal(false); }

    let message = "";

    if(isNaN(auth.user?.expires_in!)) {
      message = `Your session has expired`;
    }
    else {
      message = `Your session will expire in ${Math.ceil(auth.user?.expires_in! / 60)} minute(s)`;
    }

    return (
      <Stack>
        <h5 className="text-center">Oh snap!</h5>
        <p className="text-center">{message}</p>
        <Button variant="popup-btn right-margin10 btn-primary crbc-bg-color w-100 mt-3" onClick={() => handleResetToken()}>
          Reset
        </Button>
      </Stack>
    )
  }

  return (
    <div className="App">
      <Toast />
      {
        auth.isAuthenticated ? (<AppContextProvider loginId={jwt.sub} />) : (<AppRoutes />)
      }    
      <CustomModal isShow={showTokenExpireModal} handleClose={handleTokenExpireModal} header={<IconElement iconType={faExclamationCircle} headerClass="warning-icon ms-6" color="#DC6803" />} size="md">
        <TokenRefreshForm />
      </CustomModal>
    </div>
  );
}

export default App;
