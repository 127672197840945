import CRBCLogo from "../logo";
import AuthenticatedSideBarItems from "./authenticated-sidebar-Items";
import { useAuth } from "react-oidc-context";

export default function Sidebar() {
  const auth = useAuth();

  return (
    <div className="d-flex flex-column flex-shrink-0 p-3 bg-light sidebar d-none d-md-none d-lg-flex">
      <a href="/" className="d-flex align-items-center mb-5 mt-2 text-decoration-none">
        <CRBCLogo />
        <span className="ms-3 logo-text">Hub Central</span>
      </a>
      <AuthenticatedSideBarItems
        isAuthenticated={auth.isAuthenticated}
        logout={null}
      />
    </div>
  );
}