import { ButtonProps } from "react-bootstrap";

interface IButtonProps extends ButtonProps {
  text: string;
  className: string;
  FnOnClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: JSX.Element;
  textClass?:string;
}

export default function HubButton({text, className, FnOnClick, icon, textClass , ...rest}: IButtonProps) {
  return (
    <button {...rest} className={className} onClick={FnOnClick} >
      {icon ? icon : null} &nbsp;
      <span className={textClass}>{text}</span>
    </button>
  );
}
