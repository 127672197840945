import React, { useState } from "react";
import PagedFilterTable from "../table";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import { Button } from "react-bootstrap";
import IEstablishment from "../../models/Establishment";
import { NavLink } from "react-router-dom";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface IEstablishmentListProps {
    handleClose: any;
    field: any;
    StartDate: any;
    EndDate: any;
    pageIndex: number;
    pageSize: number;
    handleEstablishmentFilter: any;
    handleApplyFilter: any;
    selectedEstablishment: any;
}
export default function EstablishmentList(props: IEstablishmentListProps) {
    const [rowSelection, setRowSelection] = useState(props.selectedEstablishment)
    const [tableJobs, setTableJobs] = useState([]);
    const [establishmentName, setEstablishmentName] = useState(null);
    const columnHelper = createColumnHelper<IEstablishment>();
    const columns = [
        columnHelper.display({
            id: "select_establishmentID",
            cell: props => <input type="checkbox" checked={rowSelection === props.row.original.establishmentID} onChange={(e) => { onRowClick(props.row); }}></input>
        }),
        columnHelper.accessor('name', {
            header: () => <span>Name</span>,
            size: 25,
            cell: ({ row }) => (
                <NavLink to={`/establishment/${row.original.establishmentID}`}>
                    {row.original.name}
                </NavLink>
            ),
        }),
        columnHelper.accessor('salesForceExternalID', {
            header: () => <span>External ID</span>,
            size: 25,
            cell: ({ row }) => (
                <a href={row.original.salesForceUrl} className="underline" target="_blank" rel="noreferrer">
                    {row.original.salesForceExternalID}
                </a>
            ),
        }),
        columnHelper.accessor('city', {
            header: () => <span>City</span>,
            size: 25
        }),
        columnHelper.accessor('postCode', {
            header: () => <span>Postcode</span>,
            size: 25
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.establishmentID;
        setRowSelection((prevValue: any) => (prevValue === selectedValue ? null : selectedValue));
        setEstablishmentName((prevValue) => (prevValue === row.original.name ? null : row.original.name));
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        const establishmentResponse = await ServiceManager.EstablishmentService.GetAllWithChildern(false, options.pageIndex + 1, options.pageSize, options.searchValue);
        setTableJobs(establishmentResponse.data);
        return establishmentResponse.data;
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            props.handleEstablishmentFilter(rowSelection, establishmentName);
            props.handleClose();
            props.handleApplyFilter();
            AddUrlFilter("establishmentId", rowSelection);
            AddUrlFilter("establishmentName", establishmentName);

        } catch (e) {
            console.error(e);
        }
    }


    return (
        <React.Fragment>
            <PagedFilterTable
                tableKey={"establishments-filter"}
                columns={columns}
                data={tableJobs}
                usePagination={true}
                useSearch={true}
                pageRecords={8}
                fnFetchData={fetchData}
            />
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color "
                    className={` ${rowSelection === null ? "disabled" : ""}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )
};