import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Spinner, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faAdd, faTrashAlt, faExclamationCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import HubButton from "../../components/button";
import PagedFilterTable from "../../components/table";
import IPackage from "../../models/Package";
import { ServiceManager } from "../../services/ServiceManager";
import { GUID, toGuid } from "../../app/common/helper/Guid";
import CustomModal from "../../components/modal"
import IconElement from "../../components/icon";
import { APIStatusCode, Permissions } from "../../app/enums";
import PackageCreate from "../packages/create";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../app/constants";

export default function ProductDetail(props: any) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { ProductName } = useParams();
    const { ProductId } = useParams();
    const productIdGUID = toGuid(ProductId ?? "");
    const [tableJobs, setTableJobs] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showWorningModal, setShowWarrningModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseWarningModal = () => setShowWarrningModal(false);
    const [currentPackageId, setCurrentPackageId] = useState("");
    const [currentPackageName, setCurrentPackageName] = useState("");
    const [showRenameModal, setShowRenameModal] = useState(false);
    const handleCloseRenameModal = () => setShowRenameModal(false);
    const [isCreatePackage, setCreatePackage] = useState(false);
    const canEditProducts = usePermissionCheck(Permissions.CanEditProducts);
    const canViewProducts = usePermissionCheck(Permissions.CanViewProducts);

    const columnHelper = createColumnHelper<IPackage>();
    const columns = [
        columnHelper.accessor('packageName', {
            header: () => <span>Package Name</span>,
            size: 40,
            cell: ({ row }) => (
                <React.Fragment>
                    {
                        canViewProducts
                            ?
                            <NavLink to={`/product/package/${row.original.packageID}/${row.original.packageName}`}>
                                {row.original.packageName}
                            </NavLink>
                            :
                            <span>{row.original.packageName}</span>
                    }
                </React.Fragment>
            ),
        }),
        columnHelper.accessor('packageID', {
            header: () => <span>Package ID</span>,
            size: 50
        }),
        columnHelper.accessor("productID", {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                <div>
                    {
                        canEditProducts
                        ?
                        <FontAwesomeIcon
                            className="cursor-pointer me-5"
                            icon={faTrashAlt}
                            size="lg"
                            onClick={() => {
                                processDeletePackage(row.original.packageID)
                            }}
                        />
                        :
                        <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="lg"
                                className="disabled me-5"
                            />
                        </HUBTooltip>
                    }

                    {
                        canEditProducts
                        ?
                        <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={faPen}
                            size="lg"
                            onClick={() => {
                                showUpdatePopup(true, row.original.packageID, row.original.packageName);
                            }}
                        />
                        :
                        <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                            <FontAwesomeIcon
                                icon={faPen}
                                size="lg"
                                className="disabled"
                            />
                        </HUBTooltip>
                    }
                </div>
            ),
            size: 10,
        })
    ];
    
    const PackageUpdateForm = ({ productId, PackageId, packageName, handleClose }: any) => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: (updatedPackageName: string) => {
                return ServiceManager.ProductService.UpdatePackage(productId, toGuid(currentPackageId), updatedPackageName);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data == null) {
                    ServiceManager.ToastServer.showError("Unable to update Package.");
                }
                else {

                    if (data.status === APIStatusCode.Ok) {
                        ServiceManager.ToastServer.showSuccess("Package updated successfully.");
                        queryClient.invalidateQueries();
                        handleClose();
                    }
                    else {
                        let errorMessage = data.errors.errors;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });
        const { register, handleSubmit, formState: { errors } } = useForm();
        const onSubmit = (data: any) => {
            mutation.mutate(data.packageName);
        };
        return (
            <Stack>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <p className="heading">Edit Package Name</p>
                    <p className="text-muted">Please enter a name for this Package</p>
                    <label className="mt-1">Package Name</label>
                    <input {...register("packageName", { required: true })} defaultValue={packageName} className={`form-control mt-1 ms-0 w-100 ${(errors.packageName) ? 'is-invalid' : ''}`} />
                    {errors.packageName && <div className="invalid-feedback">Package Name field is required</div>}

                    {
                        !mutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3">
                                <HubButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={handleClose} />
                                <HubButton text="Update" className="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </form>
            </Stack>
        )
    }
    function showUpdatePopup(val: boolean, packageId: GUID, packageName: string) {
        setCurrentPackageId(packageId.toString());
        setCurrentPackageName(packageName);
        setShowRenameModal(true);
    }

   async function createNewPackage() {
    setCreatePackage(true);
  }
    async function navigateBack(isTrue: boolean) {
    setCreatePackage(isTrue);
  }

  function empty(){

  }

    async function fetchData() {
        const packagesResponse = await ServiceManager.ProductService.GetPackagesById(productIdGUID);
        setTableJobs(packagesResponse.data);
        return packagesResponse.data;
    }

    async function getPackageInfo(packageID: GUID) {
        const packageInfoResponse = await ServiceManager.PackageService.GetPackageInfo(packageID);
        return packageInfoResponse.data;
    }

    async function deletePackage() {
        const deleteResponse = await ServiceManager.ProductService.DeletePackageByIds(productIdGUID, toGuid(currentPackageId));
        if (deleteResponse.data === "" && deleteResponse.status === APIStatusCode.NoContent) {
            setShowDeleteModal(false);
            queryClient.invalidateQueries();
            ServiceManager.ToastServer.showSuccess("Package deleted successfully.");
        } else {
            setShowDeleteModal(false);
            queryClient.invalidateQueries();
            let errorMessage = deleteResponse.response.data.errors.errors;
            ServiceManager.ToastServer.showError(errorMessage.toString());
        }
    }

    async function processDeletePackage(packageId: GUID) {
        setCurrentPackageId(packageId.toString());
        const packageInfoResponse = await getPackageInfo(packageId);
        if (packageInfoResponse.versions.length > 0) {
            setShowWarrningModal(true);
        }
        else {
            setShowDeleteModal(true);
        }
    }

    const ProductDeleteWarningForm = () => {
        return (
            <Stack>
                <h5 className="text-center">Oh snap!</h5>
                <p className="text-center">This Package cannot be deleted because it contains multiple versions.</p>
                <Button variant="popup-btn right-margin10 btn-primary crbc-bg-color w-100 mt-3" onClick={handleCloseWarningModal}>
                    OK
                </Button>
            </Stack>
        )
    }

    const ProductDeleteConfirmationForm = () => {
        return (
            <Stack>
                <h5 className="text-center">Delete Package</h5>
                <p className="text-center">Are you sure you want to delete this Package? This action cannot be undone.</p>
                <Stack direction="horizontal" className="mt-3">
                    <Button variant="popup-btn right-margin10 btn-outline-secondary w-50" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="popup-btn right-margin10 btn-danger w-50" onClick={deletePackage}>
                        Delete
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return isCreatePackage === false ? (
        <Layout
            title={ProductName ?? ""}
            subTitle="Packages"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
            button={
                canEditProducts
                ?
                <HubButton text="Create New Package"
                    className="btn btn-primary crbc-bg-color"
                    FnOnClick={() => createNewPackage()}
                    icon={<FontAwesomeIcon icon={faAdd} />}></HubButton>
                :
                <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                    <div className="disabled">
                        <HubButton text="Create New Package"
                            className="btn btn-secondary"
                            FnOnClick={() => empty()}
                            disabled
                            icon={<FontAwesomeIcon icon={faAdd}/>}></HubButton>
                    </div>
                </HUBTooltip>
            }>
            <div className="detail-list">
                <PagedFilterTable
                    tableKey={"productID-" + productIdGUID}
                    columns={columns}
                    data={tableJobs}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={20}
                    fnFetchData={fetchData}
                />
            </div>

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <ProductDeleteConfirmationForm />
            </CustomModal>

            <CustomModal isShow={showWorningModal} handleClose={handleCloseWarningModal} header={<IconElement iconType={faExclamationCircle} headerClass="warning-icon ms-6" color="#DC6803" />} size="md">
                <ProductDeleteWarningForm />
            </CustomModal>

            <CustomModal isShow={showRenameModal} handleClose={handleCloseRenameModal} header={<IconElement iconType={faPen} headerClass="blue-icon" color="#0060DF" />} size="md">
                <PackageUpdateForm handleClose={handleCloseRenameModal} productId={productIdGUID} packageId={currentPackageId} packageName={currentPackageName} />
            </CustomModal>
        </Layout>
    ) : (
    <Layout
      title="Create New Package"
      backArrow={
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => navigateBack(false)}
          className="me-4 fs-5 cursor-pointer"
        />
      }
    >
      <PackageCreate fnOnClose={(val: boolean) => setCreatePackage(val)} />
    </Layout>
  );
}