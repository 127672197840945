import React, { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../../services/ServiceManager";
import { Button } from "react-bootstrap";
import PagedFilterTable from "../../../components/table";
import { useParams } from "react-router-dom";
import IProductVersion from "../../../models/ProductVersion";

interface IEstablishmentInstalledProductsListProps {
    handleClose: any;
    handleInstalledProductFilter: any;
    selectedInstalledProduct: any;
}
export default function EstablishmentInstalledProductsList(props: IEstablishmentInstalledProductsListProps) {

    const { EstablishmentId } = useParams();
    const [rowSelection, setRowSelection] = useState(props.selectedInstalledProduct);
    const columnHelper = createColumnHelper<IProductVersion>();
    const columns = [
        columnHelper.display({
            id: "select_versionNumber",
            cell: props => <input type="checkbox" checked={rowSelection === props.row.original.versionNumber} onChange={(e) => { onRowClick(props.row); }}></input>
        }),
        columnHelper.accessor('versionNumber', {
            header: () => <span>Version</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.versionNumber}</span>
                </div>
            ),
        }),
        columnHelper.accessor('productName', {
            header: () => <span>Product</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.productName}</span>
                </div>
            ),
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.versionNumber;
        setRowSelection((prevValue : any) => (prevValue === selectedValue ? null : selectedValue));
    }

    const filterUniqueObjects = (arr : any) => {
        const uniqueObjects : any = [];
        arr.forEach((obj : any) => {
          if (!uniqueObjects.some((uniqueObj : any) => uniqueObj.productName === obj.productName && uniqueObj.versionNumber === obj.versionNumber)) {
            uniqueObjects.push(obj);
          }
        });
        return uniqueObjects;
      };

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        const establishmentResponse = await ServiceManager.EstablishmentService.GetInstalledPackagesById(EstablishmentId);
        return filterUniqueObjects(establishmentResponse.data);
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            props.handleInstalledProductFilter(rowSelection);
            props.handleClose();

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div id="establishmentFilterTable">
                <PagedFilterTable
                    tableKey={`establishment-installed-products-filter-${EstablishmentId}`}
                    columns={columns}
                    data={[]}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={8}
                    fnFetchData={fetchData}
                />
            </div>
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color "
                    className={` ${rowSelection ? "" : "disabled"}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )

}