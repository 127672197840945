
interface ISpinner{
    color?: string;
}

export default function HUBSpinner(props: ISpinner) {

    return (
        <div className={"spinner-border m-auto " + (props.color ? props.color : "crbc-color")} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}