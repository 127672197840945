import React, { useState, useEffect } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";

interface IProductProps {
  show: boolean;
  title: string;
  description: string;
  value: string;
  placeholder: string;
  fieldname: string;
  btntext: string;
  isLoading: boolean;
  onConfirmFunction: Function;
  onCloseFunction: Function;
  onErrorFunction?: Function;
  icon: any;
  iconStyle: string;
  iconColor: string;
}
export default function ProductPopup(props: IProductProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [productName, setProductName] = useState<string>("");

  useEffect(() => {
    if (props.value) {
      setProductName(props.value);
    } else {
      reset(
        {
          productName: "",
        },
        {
          keepErrors: true,
          keepDirty: true,
        }
      );
    }
  }, [props.value, reset]);

  const onSubmit = (data: any) => {
    try {
      props.onConfirmFunction(productName);
      reset({
        productName: "",
      });
    } catch (e) {
      console.error(e);

      if (props.onErrorFunction) {
        props.onErrorFunction(e);
      }
    }
  };

  function onClose() {
    try {
      setProductName("");
      props.onCloseFunction();
      reset({
        productName: "",
      });
    } catch (e) {
      console.error(e);

      if (props.onErrorFunction) {
        props.onErrorFunction(e);
      }
    }
  }

  function onChange(event: any) {
    try {
      event.preventDefault();
      setProductName(event.target.value);
    } catch (e) {
      console.error(e);

      if (props.onErrorFunction) {
        props.onErrorFunction(e);
      }
    }
  }
  return (
    <Modal show={props.show} backdrop="static" keyboard={false} centered>
      <div className="display-content">
        <Modal.Header className="border-bottom-0">
          <Modal.Title className="display-content">
            <React.Fragment>
              <div className={props.iconStyle}>
                <FontAwesomeIcon
                  icon={props.icon}
                  size="lg"
                  color={props.iconColor}
                />
              </div>
              <div>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faTimes}
                  size="sm"
                  color="#667085"
                  onClick={() => onClose()}
                />
              </div>
            </React.Fragment>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="row">
              <p className="title-text">{props.title}</p>
              <div className="card-body">
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                  {props.description ?? <div className="desc-text">
                    <p>{props.description}</p>
                  </div>}

                  <div className="form-group">
                    <label className="label-text">Product Name</label>
                    <input 
                      {...register("productName", { required: true })}
                      placeholder="Enter name"
                      name="productName"
                      className={`form-control mt-1 ${(!productName && errors.productName) ? 'is-invalid' : ''}`}
                      value={productName}
                      maxLength={50}
                      onChange={(event) => onChange(event)}
                    />
                    {!productName && errors.productName && (
                      <div className="invalid-feedback ms-2">
                        Product Name field is required
                      </div>
                    )}
                  </div>
                  <div className="margin-top20">
                    <Col className="text-center">
                      {props.isLoading ? (
                        <Spinner
                          animation="border"
                          variant="info"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <React.Fragment>
                          <Button
                            variant=" popup-btn right-margin10 btn-outline-secondary"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </Button>
                          <button
                            type="submit"
                            className=" popup-btn btn btn-primary crbc-bg-color"
                          >
                            {props.btntext}
                          </button>
                        </React.Fragment>
                      )}
                    </Col>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer className="border-top-0" />
      </div>
    </Modal>
  );
}
