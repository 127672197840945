import React, { useState } from "react";
import PagedFilterTable from "../../components/table";
import Layout from "../layout";
import { createColumnHelper } from "@tanstack/react-table";
import { useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../services/ServiceManager";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faAdd, faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import HubButton from "../../components/button";
import IProduct from "../../models/Product";
import { toGuid } from "../../app/common/helper/Guid";
import ProductPopup from "./product-popup";
import { NavLink } from "react-router-dom";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import { Permissions } from "../../app/enums";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../app/constants";

export default function Product() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableJobs] = useState([]);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [productId, setProductId] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [popupBtnText, setPopupBtnText] = useState<string>("");
  const [popupIcon, setPopupIcon] = useState<IconDefinition>();
  const [iconStyle, setIconStyle] = useState<string>("");
  const [iconColor, setIconColor] = useState<string>("");
  const queryClient = useQueryClient();
  const canEditProducts = usePermissionCheck(Permissions.CanEditProducts);
  const canViewProducts = usePermissionCheck(Permissions.CanViewProducts);

  const columnHelper = createColumnHelper<IProduct>();
  const columns = [
    columnHelper.accessor("productName", {
      header: () => <span>Name</span>,
      cell: ({ row }) => ( 
        <React.Fragment>
          {
            canViewProducts
              ?
              <NavLink to={`${row.original.productID}/${row.original.productName}`}>
                {row.original.productName}
              </NavLink>
              :
              <span>{row.original.productName}</span>
          }
        </React.Fragment>
      ),
      size: 30,
    }),
    columnHelper.accessor("productID", {
      header: () => <span>ID</span>,
      size: 60,
    }),
    columnHelper.accessor("id", {
      header: () => <span>Actions</span>,
      cell: ({ row }) => (
        <div>
          {
            canEditProducts 
            ? 
            <FontAwesomeIcon
                className="cursor-pointer"
                icon={faPen}
                size="lg"
                onClick={() => {
                  onRowClick(row);
                  showUpdatePopup(true);
                }}
              />
            :
            <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
              <FontAwesomeIcon
                  icon={faPen}
                  size="lg"
                  className="disabled"
                />
            </HUBTooltip>
          }
        </div>
      ),
      size: 5,
    }),
  ];
  
  function showUpdatePopup(val: boolean) {
    setPopupTitle("Edit Product Name");
    setPopupBtnText("Update");
    setPopupIcon(faPen);
    setIconStyle("blue-icons");
    setIconColor("#0060DF");
    setIsShow(val);
  }
  function showCreatePopup(val: boolean) {
    setPopupTitle("Create New Product");
    setPopupBtnText("Confirm");
    setPopupIcon(faMagicWandSparkles);
    setIconStyle("green-icons");
    setIconColor("#039855");
    setProductId("");
    setProductName("");
    setIsShow(val);
  }
  function hideModal(val: boolean) {
    setProductName("");
    setIsShow(val);
  }

  function onRowClick(event: any) {
    setProductId(event.original.productID);
    setProductName(event.original.productName);
  }

  async function productServiceResponse(productResponse: any) {
    if (productResponse.data) {
      if (productResponse.status === 200) {
        showUpdatePopup(false);
        queryClient.invalidateQueries();
        ServiceManager.ToastServer.showSuccess("Sucessfully updated product");
      } else if (productResponse.status === 201) {
        showCreatePopup(false);
        queryClient.invalidateQueries();
        ServiceManager.ToastServer.showSuccess("Sucessfully created product");
      }
      setProductName("");
      setIsLoading(false);
    } else {
      let errorMessage = productResponse.response.data.errors.errors
        ? productResponse.response.data.errors.errors
        : productResponse.response.data.errors.ProductName;
      ServiceManager.ToastServer.showError(errorMessage.toString());
      setIsLoading(false);
    }
  }

  async function onConfirmFunction(productName: string) {
    let prodId = toGuid(productId);
    setIsLoading(true);
    if (prodId) {
      const updateResponse = await ServiceManager.ProductService.Update(
        prodId,
        {
          productId: productId,
          productName: productName,
        }
      );
      await productServiceResponse(updateResponse);
    } else if (productName) {
      const createResponse = await ServiceManager.ProductService.Create(
        productName
      );
      await productServiceResponse(createResponse);
      return true;
    }
  }

  async function fetchData(options: { pageIndex: number; pageSize: number }) {
    const response = await ServiceManager.ProductService.GetAll(
      options.pageIndex + 1,
      options.pageSize
    );
    return response.data;
  }

  return (
    <Layout
      title="Products"
      button={
        canEditProducts
        ?
        <HubButton
          text="Create New Product"
          className="btn btn-primary crbc-bg-color"
          FnOnClick={() => showCreatePopup(true)}
          icon={<FontAwesomeIcon icon={faAdd} />}
        ></HubButton>
        :
        <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
          <div className="disabled">
            <HubButton
              text="Create New Product"
              className="btn btn-secondary"
              FnOnClick={() => showCreatePopup(true)}
              disabled
              icon={<FontAwesomeIcon icon={faAdd} />} ></HubButton>
          </div>
        </HUBTooltip>
      }
    >
      <ProductPopup
        show={isShow}
        title={popupTitle}
        description=""
        value={productName}
        onConfirmFunction={onConfirmFunction}
        onCloseFunction={() => hideModal(false)}
        placeholder="Enter name"
        fieldname="Product Name"
        btntext={popupBtnText}
        isLoading={isLoading}
        icon={popupIcon}
        iconStyle={iconStyle}
        iconColor={iconColor}
      />
      <div className="main-list">
        <PagedFilterTable
          tableKey={"products"}
          columns={columns}
          data={tableJobs}
          usePagination={false}
          useSearch={false}
          pageRecords={20}
          fnFetchData={fetchData}
        />
      </div>
    </Layout>
  );
}
