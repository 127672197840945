import { useState } from "react";
import PagedFilterTable from "../../components/table";
import Layout from "../layout";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import HubButton from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMagicWandSparkles, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import { NavLink } from "react-router-dom";
import IRing from "../../models/Ring";
import Form from 'react-bootstrap/Form';
import CustomModal from "../../components/modal";
import IconElement from "../../components/icon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Spinner, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { APIStatusCode, Permissions } from "../../app/enums";
import ReactSwitch from "react-switch";
import { GUID } from "../../app/common/helper/Guid";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../app/constants";

export default function Ring() {
    const defaultRing: IRing = { ringID: 0, ringName: '', description: '', isDefaultForEstablishments: false, isPublic: false, productID: '' as GUID, productName: '', versionNumber: '' };
    const [selectedRing, setSelectedRing] = useState<IRing>(defaultRing);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [ringToDelete, setRingToDelete] = useState(0);
    const [tableJobs, setTableJobs] = useState([]);

    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseEditModal = () => setShowEditModal(false);
    var canManageRings = false;
    canManageRings = usePermissionCheck(Permissions.CanManageRings);

    const columnHelper = createColumnHelper<IRing>();
    const columns = [
        columnHelper.accessor('ringName', {
            header: () => <span>Name</span>,
            size: 15,
            cell: ({ row }) => (
                <NavLink to={`${row.original.ringID}`}>
                    {row.original.ringName}
                </NavLink>
            ),
        }),
        columnHelper.accessor('description', {
            header: () => <span>Description</span>,
            size: 40,
            cell: ({ row }) => (
                <span className="text-2-lines" title={row.original.description ?? ""}>
                    {row.original.description}
                </span>
            ),
        }),
        columnHelper.accessor('isPublic', {
            header: () => <span>Public</span>,
            cell: ({ row }) => (
                <Form.Check
                    type="checkbox"
                    disabled
                    checked={row.original.isPublic}
                />
            ),
        }),
        columnHelper.accessor('isDefaultForEstablishments', {
            header: () => <span>Default</span>,
            cell: ({ row }) => (
                <Form.Check
                    type="checkbox"
                    disabled
                    checked={row.original.isDefaultForEstablishments}
                />
            ),
        }),
        columnHelper.accessor('ringID', {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                canManageRings ? (
                    <div>
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            size="lg"
                            className="me-4 cursor-pointer"
                            onClick={() => {
                                setShowDeleteModal(true); setRingToDelete(row.original.ringID);
                            }}
                        />
                        <FontAwesomeIcon
                            icon={faPen}
                            className="cursor-pointer"
                            size="lg"
                            onClick={() => {
                                setShowEditModal(true);
                                setSelectedRing(row.original);
                            }}
                        />

                    </div>
                )
                    :

                    (

                        <div className="disabled">
                            <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="lg"
                                    className="me-4 cursor-pointer"
                                    onClick={() => {
                                        setShowDeleteModal(true); setRingToDelete(row.original.ringID);
                                    }}
                                />
                            </HUBTooltip>
                            <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                <FontAwesomeIcon
                                    icon={faPen}
                                    className="cursor-pointer"
                                    size="lg"
                                    onClick={() => {
                                        setShowEditModal(true);
                                        setSelectedRing(row.original);
                                    }}
                                />
                            </HUBTooltip>
                        </div>

                    )

            ),
        })
    ];

    const RingCreateForm = () => {
        const queryClient = useQueryClient();

        const mutation = useMutation({
            mutationFn: (ringData: IRing) => {
                return ServiceManager.ProductService.CreateRing(ringData);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastServer.showError("Unable to create Ring.");
                }
                else {

                    if (data.status === APIStatusCode.Created) {
                        ServiceManager.ToastServer.showSuccess("Ring created successfully.");
                        queryClient.invalidateQueries({ queryKey: ["rings"] });
                        handleCloseCreateModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });
        const { register, handleSubmit, formState: { errors } } = useForm();
        const [isPublic, setIsPublic] = useState(false);
        const [defaultForEstablishment, setDefaultForEstablishment] = useState(false);
        const onSubmit = (data: any) => {
            data.isPublic = isPublic;
            data.isDefaultForEstablishments = defaultForEstablishment;
            mutation.mutate(data);
        };

        return (
            <Stack>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <p className="heading">Create New Ring</p>
                    <p className="text-muted">Please enter details below</p>
                    <label className="mt-1">Ring Name</label>
                    <input {...register("ringName", { required: true })} className={`form-control mt-1 ms-0 w-100 ${(errors.ringName) ? 'is-invalid' : ''}`} />
                    {errors.ringName && <div className="invalid-feedback">Ring Name field is required</div>}

                    <label className="mt-3">Description</label>
                    <textarea className="form-control ms-0 mt-1 w-100" {...register("description")} maxLength={500} />

                    <Stack direction="vertical" gap={2}>
                        <div className="d-flex align-items-center mt-3">
                            <label className="w-100">Public</label>
                            <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                handleDiameter={17} width={40} checkedIcon={false}
                                uncheckedIcon={false} checked={isPublic}
                                onChange={() => setIsPublic(!isPublic)} />
                        </div>
                        <div className="d-flex align-items-center mt-1">
                            <label className="w-100">Default For Establishment</label>
                            <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                handleDiameter={17} width={40} checkedIcon={false}
                                uncheckedIcon={false} checked={defaultForEstablishment}
                                onChange={() => setDefaultForEstablishment(!defaultForEstablishment)} />
                        </div>
                    </Stack>
                    {
                        !mutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3">
                                <HubButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={handleCloseCreateModal} />
                                <HubButton text="Confirm" className="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </form>
            </Stack>
        )
    }
    const RingEditForm = () => {
        const queryClient = useQueryClient();

        const mutation = useMutation({
            mutationFn: (ringData: IRing) => {
                return ServiceManager.ProductService.EditRing(ringData);
            },
            onError: (error, variables, context) => {
                ServiceManager.ToastServer.showError("Something went wrong. Try again.");
            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastServer.showError("Unable to update Ring.");
                }
                else {

                    if (data.status === APIStatusCode.Ok) {
                        ServiceManager.ToastServer.showSuccess("Ring updated successfully.");
                        queryClient.invalidateQueries({ queryKey: ["rings"] });
                        handleCloseEditModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });
        const [editRingName, setEditRingName] = useState(selectedRing?.ringName || "");
        const [editDescription, setEditDescription] = useState(selectedRing?.description || "");
        const onSubmit = () => {
            if (editRingName) {
                let ringData: IRing = defaultRing;
                ringData.ringID = selectedRing.ringID;
                ringData.ringName = editRingName;
                ringData.description = editDescription;
                ringData.isDefaultForEstablishments = selectedRing.isDefaultForEstablishments;
                ringData.isPublic = selectedRing.isPublic;
                mutation.mutate(ringData);
            }
        };

        const handleChange = (newValue: any, fieldName: string) => {
            setSelectedRing((prevSelectedRing: any) => {
                if (prevSelectedRing) {
                    switch (fieldName) {
                        case 'name':
                            return { ...prevSelectedRing, ringName: newValue };
                        case 'description':
                            return { ...prevSelectedRing, ringName: editRingName, description: newValue };
                        case 'isDefaultForEstablishments':
                            return { ...prevSelectedRing, ringName: editRingName, description: editDescription, isDefaultForEstablishments: newValue };
                        case 'isPublic':
                            return { ...prevSelectedRing, ringName: editRingName, description: editDescription, isPublic: newValue };
                        default:
                            return prevSelectedRing;
                    }
                }
            });
        };
        return (
            <Stack>
                <form className="form">
                    <p className="heading">Edit Ring</p>
                    <p className="text-muted">Please enter details below</p>
                    <label className="mt-1">Ring Name</label>
                    <input key="name-input" onChange={x => setEditRingName(x.target.value)} value={editRingName || ""}
                        className={`form-control mt-1 ms-0 w-100 ${(editRingName === '' || editRingName === undefined) ? 'is-invalid' : ''}`} />
                    {editRingName === '' || editRingName === undefined ? <div className="invalid-feedback">Ring Name field is required</div> : ""}

                    <label className="mt-3">Description</label>
                    <textarea className="form-control ms-0 mt-1 w-100" onChange={x => setEditDescription(x.target.value)} value={editDescription || ""} maxLength={500} />

                    <Stack direction="vertical" gap={2}>
                        <div className="d-flex align-items-center mt-3">
                            <label className="w-100">Public</label>
                            <ReactSwitch height={20} onColor="#10A760" offColor="#E2E2E3"
                                handleDiameter={17} width={40} checkedIcon={false}
                                uncheckedIcon={false} checked={selectedRing?.isPublic || false}
                                onChange={(event) => handleChange(event, 'isPublic')} key="Public-input" />
                        </div>
                        <div className="d-flex align-items-center mt-1">
                            <label className="w-100">Default For Establishment</label>
                            <ReactSwitch height={20} onColor="#10A760" offColor="#E2E2E3"
                                handleDiameter={17} width={40} checkedIcon={false}
                                uncheckedIcon={false} checked={selectedRing?.isDefaultForEstablishments || false}
                                onChange={(event) => handleChange(event, 'isDefaultForEstablishments')} key="isDefaultForEstablishments-input" />
                        </div>
                    </Stack>
                    {
                        !mutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3">
                                <HubButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={handleCloseEditModal} />
                                <HubButton text="Confirm" className="popup-btn btn btn-primary crbc-bg-color w-100" type="button" FnOnClick={onSubmit} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </form>
            </Stack>
        )
    }
    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const ringsResponse = await ServiceManager.ProductService.GetAllRings();
        setTableJobs(ringsResponse.data);
        return ringsResponse.data;
    }

    const DeleteRingConfirmationForm = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.ProductService.DeleteRing(ringToDelete);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastServer.showError("Unable to delete Ring.");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastServer.showSuccess("Ring deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["rings"] });
                        handleCloseDeleteModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastServer.showError(errorMessage.toString());
                    }
                }
            }
        });

        return (
            <Stack>
                <p className="heading text-center">Delete Ring</p>
                <p className="text-center">Are you sure you want to delete this Ring? This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <HubButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <HubButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout title="Rings"
            button={
                canManageRings ? (
                    <HubButton text="Create New Ring"
                        className="btn btn-primary crbc-bg-color"
                        FnOnClick={() => setShowCreateModal(true)}
                        icon={<FontAwesomeIcon icon={faAdd} />}></HubButton>
                )
                    :

                    (
                        <HUBTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div className="disabled ms-auto me-3">
                                <HubButton text="Create New Ring"
                                    className="btn btn-primary crbc-bg-color disabled"
                                    FnOnClick={() => setShowCreateModal(true)}
                                    icon={<FontAwesomeIcon icon={faAdd} />}></HubButton>
                            </div>
                        </HUBTooltip>
                    )



            }>
            <div className="main-list">
                <PagedFilterTable
                    tableKey={"rings"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={20}
                    fnFetchData={fetchData}
                />
            </div>

            <CustomModal isShow={showCreateModal} handleClose={handleCloseCreateModal} header={<IconElement iconType={faMagicWandSparkles} headerClass="green-icon" color="#039855" />} size="md">
                <RingCreateForm />
            </CustomModal>

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteRingConfirmationForm />
            </CustomModal>
            <CustomModal isShow={showEditModal} handleClose={handleCloseEditModal} header={<IconElement iconType={faMagicWandSparkles} headerClass="green-icon" color="#039855" />} size="md">
                <RingEditForm />
            </CustomModal>
        </Layout>
    );
};