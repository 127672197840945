import { Stack } from "react-bootstrap";
import PagedFilterTable from "../../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { ServiceManager } from "../../../services/ServiceManager";
import HubButton from "../../../components/button";
import React, { useState } from "react";
import IProduct from "../../../models/Product";
import CustomModal from "../../../components/modal";
import ProductVersionsList from "./product-versions-list";

interface IPopupProducts {
  ringID: any;
  handleClose: any;
}

export default function ProductsList(props: IPopupProducts) {
  const [productId, setProductId] = useState('');
  
  const [showAssignVersionModal, setShowAssignVersionModal] = useState(false);
  const handleCloseAssignVersionModal = () => setShowAssignVersionModal(false);

  const columnHelper = createColumnHelper<IProduct>();
  const columns = [
    columnHelper.accessor('productName', {
      header: () => <span>Product Name</span>,
      size: 50,
      cell: ({ row }) => (
        <div>{row.original.productName}</div>
      ),
    }),
    columnHelper.accessor('productID', {
      header: () => <span>Product ID</span>,
      size: 50,
      cell: ({ row }) => (
        <div>{row.original.productID}</div>
      ),
    })
  ];

  async function fetchData(options: {
    pageIndex: number;
    pageSize: number;
    searchValue: string
  }) {
    const ringsResponse = await ServiceManager.ProductService.GetAll(options.pageIndex + 1, options.pageSize);
    return ringsResponse.data;
  }

  const handleAssign = () => {
    setShowAssignVersionModal(true);
  }

  const handleUserSelection = (row: any) => {
    setProductId(row.original.productID);
  }

  return (
    <React.Fragment>
      <PagedFilterTable
        tableKey={`products-list-${props.ringID}`}
        columns={columns}
        data={[]}
        usePagination={true}
        useSearch={false}
        pageRecords={10}
        fnFetchData={fetchData}
        title={
          <>
            <Stack direction="horizontal">
              <h5 className="ps-3 pt-3 pb-2">
                <b>Products</b>
              </h5>
            </Stack>
          </>
        }
        fnRowClick={handleUserSelection}
      />

      <div className="mt-4">
        <HubButton
          className="btn right-margin10 btn-outline-secondary"
          FnOnClick={() => props.handleClose()}
          text="Cancel">
        </HubButton>
        <HubButton
          type="button"
          variant=""
          className={`btn btn-primary crbc-bg-color ${productId === '' ? "disabled" : ""}`}
          FnOnClick={function () {
            handleAssign();
          }}
          text="Assign">
        </HubButton>
      </div>

      <CustomModal
          isShow={showAssignVersionModal}
          handleClose={handleCloseAssignVersionModal}
          header={<Stack> <p className="heading"><br/></p> </Stack>}
          size="lg">
          <ProductVersionsList productId={productId} ringId={props.ringID} handleClose={handleCloseAssignVersionModal} />
      </CustomModal>

    </React.Fragment>
  );
}