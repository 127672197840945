import { Stack } from "react-bootstrap";
import { ServiceManager } from "../../services/ServiceManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import IMessageStatistics from "../../models/MessageStatistics";
import { LogLevelName } from "../../app/enums";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export interface IMessageStatsProps {
  selectedTimer: number; // currently in hours
  startDate: Date;
  finishDate: Date;
  logLevel?: number;
  establishmentId?: any;
  deviceId?: any;
  productId?: any;
  packageId?: any;
  category?: string
  navigateTo: string;
}

export default function MessageStats(props: IMessageStatsProps) {

  const { isLoading, data } = useQuery([props.selectedTimer + props.startDate.toTimeString() + props.establishmentId], () =>
    ServiceManager.MessageService.GetStatistics(props.startDate.toISOString(), props.finishDate.toISOString(),
      props.logLevel, props.establishmentId, props.deviceId, props.productId, props.packageId, props.category),
    {
      refetchOnWindowFocus: false
    }
  );

  return (
    <div className="establishment-messages-widget-box">
      <Stack direction="horizontal">
        <div className="establishment-messages-widget-box-header">
          Messages
        </div>
        <Link className="establishment-messages-widget-box-header-button" to={props.navigateTo}>
          View
        </Link>
      </Stack>
      <div className="outer-container d-flex justify-content-center align-items-center">
        <div className="establishment-messages-log-count-container">
          <div className="establishment-messages-log-count-upper">
            <FontAwesomeIcon icon={faCircle} size="2xs" color="#D92D20" />
            <p className="establishment-messages-log-count-header">
              Critical
            </p>
          </div>
          <p className="establishment-messages-log-count-text">{!isLoading && data !== null ? data?.data?.find((x: IMessageStatistics) => x.logLevelName === LogLevelName.Critical)?.count ?? 0 : <Skeleton height={20} count={1} />}</p>
        </div>
        <div className="establishment-messages-log-count-container">
          <div className="establishment-messages-log-count-upper">
            <FontAwesomeIcon icon={faCircle} size="2xs" color="#EF6820" />
            <p className="establishment-messages-log-count-header">Error</p>
          </div>
          <p className="establishment-messages-log-count-text">
            {!isLoading && data !== null ? data?.data?.find((x: IMessageStatistics) => x.logLevelName === LogLevelName.Error)?.count ?? 0 : <Skeleton height={20} count={1} />}</p>
        </div>
        <div className="establishment-messages-log-count-container">
          <div className="establishment-messages-log-count-upper">
            <FontAwesomeIcon icon={faCircle} size="2xs" color="#FFB900" />
            <p className="establishment-messages-log-count-header">Warning</p>
          </div>
          <p className="establishment-messages-log-count-text">
            {!isLoading && data !== null ? data?.data?.find((x: IMessageStatistics) => x.logLevelName === LogLevelName.Warning)?.count ?? 0 : <Skeleton height={20} count={1} />}
          </p>
        </div>
      </div>
    </div>
  );
}