import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

interface ISideBarLinkProps {
    icon: any,
    text: string,
    href: string,
    liClass?: string,
    navClass?: string
}

export default function SideBarLink(props: ISideBarLinkProps) {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };
    
    return (
        <li className={props.liClass}>
            <NavLink to={props.href} className={props.navClass + (isActive ? ' active ': ' link-dark ')} onClick={toggleClass}> 
                <FontAwesomeIcon icon={props.icon} className="bi me-2" /> {props.text}
            </NavLink>
        </li>
    );
    
}