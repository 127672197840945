import React, { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../../services/ServiceManager";
import { Button } from "react-bootstrap";
import PagedFilterTable from "../../../components/table";
import { useParams } from "react-router-dom";
import IEstablishmentProduct from "../../../models/EstablishmentProduct";

interface IEstablishmentAvailableProductsListProps {
    handleClose: any;
    handleAvailableProductFilter: any;
    selectedAvailableProduct: any;
}
export default function EstablishmentAvailableProductsList(props: IEstablishmentAvailableProductsListProps) {

    const { EstablishmentId } = useParams();
    const [rowSelection, setRowSelection] = useState(props.selectedAvailableProduct);
    const columnHelper = createColumnHelper<IEstablishmentProduct>();
    const columns = [
        columnHelper.display({
            id: "select_availablePackageVersion",
            cell: props => <input type="checkbox" checked={rowSelection === props.row.original.availablePackageVersion} onChange={(e) => { onRowClick(props.row); }}></input>
        }),
        columnHelper.accessor('availablePackageVersion', {
            header: () => <span>Version</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.availablePackageVersion}</span>
                </div>
            ),
        }),
        columnHelper.accessor('productName', {
            header: () => <span>Product</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.productName}</span>
                </div>
            ),
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.availablePackageVersion;
        setRowSelection((prevValue : any) => (prevValue === selectedValue ? null : selectedValue));
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        const establishmentResponse = await ServiceManager.EstablishmentService.GetProductsById(EstablishmentId);
        return establishmentResponse.data.filter((product : any) => product.availablePackageVersion);
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            props.handleAvailableProductFilter(rowSelection);
            props.handleClose();

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div id="establishmentFilterTable">
                <PagedFilterTable
                    tableKey={`establishment-available-products-filter-${EstablishmentId}`}
                    columns={columns}
                    data={[]}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={8}
                    fnFetchData={fetchData}
                />
            </div>
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color "
                    className={` ${rowSelection ? "" : "disabled"}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )

}