import { createColumnHelper } from "@tanstack/react-table";
import IEstablishment from "../../../models/Establishment";
import PagedFilterTable from "../../../components/table";
import { Stack } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";
import { Permissions } from "../../../app/enums";

interface IAssignedEstablishmentProps {
    ringID: any;
    establishments: IEstablishment[];
}

export default function RingAssignedEstablishments(props: IAssignedEstablishmentProps) {
    const CanViewEstablishments = usePermissionCheck(Permissions.CanViewEstablishments);

    const columnHelper = createColumnHelper<IEstablishment>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => <span>Establishment Name</span>,
            size: 25,
            cell: ({ row }) => (
                <>
                    {CanViewEstablishments ?
                        <NavLink to={`/establishment/${row.original.establishmentID}`} state={{ from: "assigned-estb" }}>
                            {row.original.name}
                        </NavLink>
                        :
                        row.original.name
                    }
                </>
            ),
        }),
        columnHelper.accessor('parent', {
            header: () => <span>Parent</span>,
            size: 25,
            cell: ({ row }) => (
                <>
                    {CanViewEstablishments ?
                        <NavLink to={`/establishment/${row.original.parent?.establishmentID}`} state={{ from: "assigned-estb" }}>
                            {row.original.parent?.name}
                        </NavLink>
                        :
                        row.original.parent?.name
                    }
                </>
            ),
        }),
        columnHelper.accessor('salesForceExternalID', {
            header: () => <span>External ID</span>,
            size: 20,
            cell: ({ row }) => (
                <a href={row.original.salesForceUrl} className="underline" target="_blank" rel="noreferrer">
                    {row.original.salesForceExternalID}
                </a>
            ),
        }),
        columnHelper.accessor("city", {
            header: () => <span>City</span>,
            size: 20,
        }),
        columnHelper.accessor("postCode", {
            header: () => <span>Post Code</span>,
            size: 20,
        })
    ];

    async function showData(options: {
        pageSize: number;
        searchValue: string;
    }) {
        return props.establishments;
    }

    return (
        <div id="assigned-establishment" style={{ marginTop: "50px" }}>
            <PagedFilterTable
                tableKey={`rign-establishments-${props.ringID}`}
                columns={columns}
                data={[]}
                usePagination={false}
                useSearch={false}
                pageRecords={20}
                fnFetchData={showData}
                title={
                    <>
                        <Stack direction="horizontal">
                            <h5 className="ps-3 pt-3 pb-2">
                                <b>Assigned Establishments</b>
                                <br />
                                <span style={{ fontSize: "14px", fontWeight: "normal" }}>Listed below are the Establishments assigned to this Ring.</span>
                            </h5>
                        </Stack>
                    </>
                }
            />
        </div>
    );

}
