import React, { useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    PaginationState
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { Table } from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import SearchBar from "../search";
import Filter from "../filter"
import Skeleton from "react-loading-skeleton";
import { Stack } from "react-bootstrap";

interface IPagedFilterTableProps {
    tableKey: string;
    columns: any;
    data: any;
    usePagination: boolean;
    useSearch: boolean;
    pageRecords: number;
    fnFetchData: Function;
    fnResetContinuationToken: Function;
}

export default function PagedFilterMessageTable(props: IPagedFilterTableProps) {

    const [searchValue, setSearchValue] = useState<string>();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [logslevel, setlogslevel] = useState<number>();
    const [categoryName, setcategoryName] = useState<string>();
    const [establishmentId, setestablishmentId] = useState<string>();
    const [deviceId, setdeviceId] = useState<string>();
    const [productId, setproductId] = useState<string>();
    const [packageId, setpackageId] = useState<string>();
    const [messageId, setMessageId] = useState<string>();

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: props.pageRecords,
        });

    const fetchDataOptions = {
        startDate,
        endDate,
        pageIndex,
        pageSize,
        searchValue,
        logslevel,
        categoryName,
        establishmentId,
        deviceId,
        productId,
        packageId,
        messageId
    };

    const { isLoading, isFetching, data } = useQuery([props.tableKey, fetchDataOptions], () =>
        props.fnFetchData(fetchDataOptions), {
        refetchOnWindowFocus: false,
        retry: 1,
    }
    );

    async function resetPagination() {
        props.fnResetContinuationToken();
        table.resetPageIndex();
    }

    async function callbackLoglevel(Loglevel: number) { setlogslevel(Loglevel); resetPagination(); }
    async function callbackCategory(categoryName: string) { setcategoryName(categoryName); resetPagination(); }
    async function callbackEstablishment(establishmentId: any) { setestablishmentId(establishmentId); resetPagination(); }
    async function callbackDevice(establishmentId: any, deviceid: any) { setestablishmentId(establishmentId); setdeviceId(deviceid); resetPagination(); }
    async function callbackProduct(productId: any) { setproductId(productId); resetPagination(); }
    async function callbackPackage(productId: any, packageId: any,) { setproductId(productId); setpackageId(packageId); resetPagination(); }
    async function callbackTimeRange(startDate: string, finishDate: string) { setStartDate(startDate); setEndDate(finishDate); resetPagination(); }
    async function callbackMsgId(messageId: any) { setMessageId(messageId); resetPagination(); }

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data: data?.data ?? props.data,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        onPaginationChange: setPagination,
        state: {
            pagination
        },
        debugTable: true,
    });

    return (
        <React.Fragment>
            <Stack direction="horizontal" gap={3} className="mb-3">
                <Filter startDate={(startDate: string) => setStartDate(startDate)} endDate={(endDate: string) => setEndDate(endDate)}
                    handleSelect={(searchText: string) => setSearchValue(searchText)} pageIndex={fetchDataOptions.pageIndex} pageSize={fetchDataOptions.pageSize}
                    callbackLoglevel={callbackLoglevel}
                    callbackTimeRange={callbackTimeRange}
                    callbackCategory={callbackCategory}
                    callbackEstablishment={callbackEstablishment}
                    callbackDevice={callbackDevice}
                    callbackProduct={callbackProduct}
                    callbackPackage={callbackPackage}
                    callbackMsgId={callbackMsgId}
                />

                {props.useSearch && <SearchBar placeholder="Search" className="w-25" onChange={(searchText: string) => setSearchValue(searchText)} />}
            </Stack>

            <Table responsive borderless >
                <thead className="sticky">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    style={{
                                        width:
                                            header.getSize()
                                    }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {!isLoading && !isFetching && table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}

                    {(isLoading || isFetching) && table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <td key={header.id}><Skeleton height={30} /></td>
                            ))}
                        </tr>
                    ))}

                    {table.getRowModel().rows.length === 0 && !isLoading && !isFetching && (
                        <tr>
                            <td colSpan={table.getHeaderGroups()[0].headers.length} className="text-center">
                                No records found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {props.usePagination && !isLoading && !isFetching && (
                <Pagination className="float-end mt-2">
                    <Pagination.Item
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Previous
                    </Pagination.Item>
                    <Pagination.Item
                        onClick={() => table.nextPage()}
                        disabled={!data?.continuationToken}
                    >
                        Next
                    </Pagination.Item>
                </Pagination>
            )}
        </React.Fragment>
    );
}
