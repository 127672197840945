import React from "react";
import { Table } from "react-bootstrap";
import {
    flexRender,
    getCoreRowModel,
    useReactTable
  } from "@tanstack/react-table";


  interface ISimpleTableProps {
    tableKey: string;
    columns: any;
    data: any;
  }

export default function SimpleTable(props : ISimpleTableProps) {
    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel()
      });

    return (
        <React.Fragment>
            <Table responsive borderless>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    style={{
                                        width:
                                            header.getSize() !== 150
                                                ? header.getSize() + "%"
                                                : undefined,
                                    }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}

                    {table.getRowModel().rows.length === 0 && (
                        <tr>
                            <td colSpan={4} className="text-center">
                                No records found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </React.Fragment>
    )

}