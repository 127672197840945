import Layout from "../layout";
import jwt_decode from "jwt-decode";
import { useAuth } from "react-oidc-context";
import MessageStats from "../../components/messagestats/messagestats";
import TimeFilter from "../../components/TimeFilter/TimeFilter";
import { useState } from "react";
import React from "react";
import { timeUnit } from "../../app/enums";

export default function Dashboard() {
  const auth = useAuth();

  const [selectedTimer, setSelectedTimer] = useState(30);
  const [selectedTimeUnit, setselectedTimeUnit] = useState(timeUnit.minute);
  const [finishDate, setFinishDate] = useState(new Date());
  var defaultStartDate = new Date();
  defaultStartDate.setMinutes(defaultStartDate.getMinutes() - 30);
  const [startDate, setStartDate] = useState(defaultStartDate);

  var jwt: any;

  if (auth.isAuthenticated) {
    jwt = jwt_decode(auth.user?.access_token ?? '', { header: false });
  }

  function setTimes(time: any, timeUnit: timeUnit, callBackStartDate: Date, callBackfinishDate: Date, isRefresh?: boolean) {
    if (time === '') {
      return;
    }

    setSelectedTimer(time);
    setselectedTimeUnit(timeUnit);
    setFinishDate(callBackfinishDate);
    setStartDate(callBackStartDate);
  }

  return (
    <React.Fragment>
      {auth.isAuthenticated && <Layout title={auth.isAuthenticated && "Welcome " + jwt.name}>
        <div className="container-fluid">
          <div className="row">
            <TimeFilter selectedTimer={selectedTimer} selectedTimeUnit={selectedTimeUnit} setTimes={setTimes} isRefresh={true}></TimeFilter>
          </div>
          <div className="row">
            <div className="col-xl-4 col-6">
              <MessageStats selectedTimer={selectedTimer} startDate={startDate} finishDate={finishDate} navigateTo={`/message?startDate=${startDate.toISOString()}&finishDate=${finishDate.toISOString()}&timer=${selectedTimer}&unit=${selectedTimeUnit}&fromDashboard=${true}`}></MessageStats>
            </div>
          </div>
        </div>
      </Layout>}
    </React.Fragment>
  );
}