import { createColumnHelper } from "@tanstack/react-table";
import IRoleUser from "../../../models/RoleUser";
import PagedFilterTable from "../../../components/table";
import { ServiceManager } from "../../../services/ServiceManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faTrashAlt,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner, Stack } from "react-bootstrap";
import HubButton from "../../../components/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomModal from "../../../components/modal";
import IconElement from "../../../components/icon";
import { useState } from "react";
import { APIStatusCode, Permissions } from "../../../app/enums";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";
import HUBTooltip from "../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../app/constants";
import PopupUsersList from "./popup-users-list";
import { useSelfRoles } from "../../../app/common/helper/SelfRoles";

export default function RoleUsers(props: any) {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showUserListModal, setShowUserListModal] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [existingUsersData, setExistingUsersData] = useState(null);
  const [loadingModalState, setLoadingModalState] = useState(false);

  var canAssignRoles = false;
  canAssignRoles = usePermissionCheck(Permissions.CanAssignRoles);
  const selfRoleIds = useSelfRoles();

  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper<IRoleUser>();
  const columns = [
    columnHelper.accessor("displayName", {
      header: () => <span>Name</span>,
      size: 25,
    }),
    columnHelper.accessor("isActive", {
      header: () => <span>Status</span>,
      size: 20,
      cell: ({ row }) => (
        <span
          className={`p-1 ps-2 pe-2 ${
            row.original.isActive ? "active" : "disabled"
          }`}
        >
          <FontAwesomeIcon icon={faCircle} size="2xs" className="icon" />{" "}
          <span className="ms-1">
            {row.original.isActive ? "Active" : "Disabled"}
          </span>
        </span>
      ),
    }),
    columnHelper.accessor("emailAddress", {
      header: () => <span>Email Address</span>,
      size: 25,
    }),
    columnHelper.accessor("roleName", {
      header: () => <span>Role</span>,
      size: 20,
      cell: ({ row }) => <span>{props.roleName}</span>,
    }),
    columnHelper.accessor("loginID", {
      header: () => <span>Actions</span>,
      cell: ({ row }) => (
        <div>
          {
            (canAssignRoles && selfRoleIds.includes(props.roleId)) ? (
              <FontAwesomeIcon
                className="cursor-pointer me-5"
                icon={faTrashAlt}
                size="lg"
                onClick={() => {
                  setCurrentUserId(row.original.loginID);
                  setShowRemoveModal(true);
                }}
              />
            ) : (
              <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                <FontAwesomeIcon
                    className="me-5 disabled"
                    icon={faTrashAlt}
                    size="lg"
                  />
              </HUBTooltip>
            )
          }
        </div>
      ),
      size: 5
    }),
  ];

  async function fetchData(options: {
    roleId: string;
    pageSize: number;
    searchValue: string;
  }) {
    const establishmentResponse = await ServiceManager.RoleService.GetUsersById(
      props.roleId
    );

    setExistingUsersData(establishmentResponse.data?.users);
    return establishmentResponse.data?.users;
  }

  const assignUser = (e: any) => {
    e.preventDefault();
    setShowUserListModal(true);
  };

  const handleCloseUserListmentModal = () => setShowUserListModal(false);

  const HandleUserSelection = async (selectedUserIds: Array<string>) => {
    // just in case if someone can come here in any way
    if (!canAssignRoles) {
      alert("Permission not found.");
      return false;
    }
    setLoadingModalState(true);
    await Promise.all(
      selectedUserIds.map(async (userId) => {
        await ServiceManager.RoleService.AssignRole(props.roleId, userId);
      })
    );

    setLoadingModalState(false);
    queryClient.invalidateQueries({ queryKey: [`role-users-${props.roleId}`] });
    ServiceManager.ToastServer.showSuccess(
      "User assigned the role successfully"
    );
    handleCloseUserListmentModal();
  };

  const RemoveUserConfirmationForm = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
      mutationFn: (roleId: any) => {
        return ServiceManager.RoleService.DeleteUserById(roleId, currentUserId);
      },
      onError: (error, variables, context) => {},
      onSuccess: (data, variables, context) => {
        if (data === null) {
          ServiceManager.ToastServer.showError("Unable to remove user");
        } else {
          if (data.status === APIStatusCode.NoContent) {
            ServiceManager.ToastServer.showSuccess("User removed successfully");
            setShowRemoveModal(false);
            queryClient.invalidateQueries({ queryKey: [`role-users-${props.roleId}`] });
          } else {
            let errorMessage =
              data.response &&
              data.response.data &&
              data.response.data.errors.errors
                ? data.response.data.errors.errors
                : data.message;
            ServiceManager.ToastServer.showError(errorMessage.toString());
          }
        }
      },
    });

    const deleteRole = () => {
      mutation.mutate(props.roleId);
    };
    
    return (
      <Stack>
        <h5 className="text-center mt-2" style={{ fontSize: "18px" }}>
          Remove user
        </h5>
        <p className="text-center text-muted">
          Are you sure you want to remove this user from the role? This action
          cannot be undone.
        </p>
        {!mutation.isLoading ? (
          <Stack direction="horizontal" className="mt-3">
            <Button
              variant="popup-btn right-margin10 btn-outline-secondary w-50"
              onClick={handleCloseRemoveModal}
            >
              Cancel
            </Button>
            <Button
              variant="popup-btn right-margin10 btn-danger w-50"
              onClick={deleteRole}
            >
              Delete
            </Button>
          </Stack>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" variant="info" role="status"></Spinner>
          </div>
        )}
      </Stack>
    );
  };
  
  return (
    <div className="mt-3 role-users">
      <PagedFilterTable
        tableKey={`role-users-${props.roleId}`}
        columns={columns}
        data={[]}
        usePagination={false}
        useSearch={false}
        pageRecords={10}
        fnFetchData={fetchData}
        title={
          <>
            <Stack direction="horizontal">
              <h5 className="ps-3 pt-3 pb-2">
                <b>Users</b>
              </h5>
              {canAssignRoles && selfRoleIds.includes(props.roleId) ? (
                <HubButton text="Assign New User"
                  className={`btn btn-primary crbc-bg-color ms-auto me-3 `}
                  FnOnClick={(e) => assignUser(e)}
                  icon={<FontAwesomeIcon icon={faUserPlus} type="button" />}
                ></HubButton>
              ) : (
                <HUBTooltip message={PERMISSION_REQUIRED} placement="top">
                  <div className="ms-auto me-3 disabled">
                    <HubButton
                      text="Assign New User"
                      className="btn btn-secondary"
                      FnOnClick={() => null}
                      disabled
                      icon={<FontAwesomeIcon icon={faUserPlus} type="button" />}
                    ></HubButton>
                  </div>
                </HUBTooltip>
              )}
            </Stack>
          </>
        }
      />

      <CustomModal
        isShow={showRemoveModal}
        handleClose={handleCloseRemoveModal}
        header={
          <IconElement
            iconType={faTrashAlt}
            headerClass="error-icon ms-6"
            color="#D92D20"
          />
        }
        size="md"
      >
        <RemoveUserConfirmationForm />
      </CustomModal>
      <CustomModal
        isShow={showUserListModal}
        handleClose={handleCloseUserListmentModal}
        size="lg"
        header={
          <Stack>
            <p className="heading"><br/></p>
          </Stack>
        }
      >
        <PopupUsersList
          handleClose={handleCloseUserListmentModal}
          handleUserSelection={HandleUserSelection}
          existingUsersData={existingUsersData}
          loadingModalState={loadingModalState}
        />
      </CustomModal>
    </div>
  );
}
