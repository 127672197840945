import React from "react";
import { useForm } from "react-hook-form";
import { Button, Spinner, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { ServiceManager } from "../../services/ServiceManager";
import { useParams } from "react-router-dom";
import { GUID } from "../../app/common/helper/Guid";
import { APIStatusCode } from "../../app/enums";
import { useMutation, useQueryClient } from "@tanstack/react-query";
interface IPackageCreate {
  fnOnClose: Function;
}
export default function PackageCreate(props: IPackageCreate) {
  const { register, handleSubmit, reset, formState: { errors }, } = useForm();
  const queryClient = useQueryClient();
  const params = useParams();
  const mutation = useMutation({

    mutationFn: (productPackage: any) => {
      return ServiceManager.ProductService.CreatePackage(params.ProductId as GUID, productPackage.packageId as GUID, productPackage.packageName);
    },
    onError: (error, variables, context) => {
    },
    onSuccess: (data, variables, context) => {
      if (data == null) {
        ServiceManager.ToastServer.showError("Unable to create Package.");
      }
      else {
        if (data.status === APIStatusCode.Created) {
          ServiceManager.ToastServer.showSuccess("Package created successfully.");
          queryClient.invalidateQueries();
          onClose(false);
        }
        else {
          let errorMessage = data.response.data.errors.errors
            ? data.response.data.errors.errors
            : data.response.data.errors.productId;
          ServiceManager.ToastServer.showError(data.response.data.errors.packageDTO ? data.response.data.errors.packageDTO : errorMessage.toString());

        }
      }
    }
  });

  const onSubmit = (data: any) => {
    var productPackage = { packageId: data.packageID, packageName: data.packageName };
    mutation.mutate(productPackage);

  };

  function onClose(value: boolean) {
    try {
      props.fnOnClose(value);
      reset();
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <React.Fragment>
      <div >
        <div className="margin-left10 b-2 margin-bottom20">
          <React.Fragment>
            <Stack direction="horizontal" gap={3} className="align-items-start">
              <div className="d-flex justify-content-center align-items-center establishment-icon blue-bg rounded-4 mt-3">
                <div className="d-flex justify-content-center align-items-center establishment-icon-inner crbc-icon white-background-shadow rounded-3">
                  <FontAwesomeIcon icon={faBox} className="bi" size="lg" />
                </div>
              </div>
              <Stack className="mt-4">
                <h4 className="font-heading mb-1">{params.ProductName}</h4>
                <span className="font-primary font-family14">
                  ID#{params.ProductId}
                </span>
              </Stack>
            </Stack>
          </React.Fragment>
        </div>
        <React.Fragment>
          <div className="package-form-width">
            <form onSubmit={handleSubmit(onSubmit)} >
              <div className="form-group">
                <label className="label-text">Enter Package ID </label>
                <input
                  {...register("packageID", { required: true, pattern: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/ })}
                  placeholder="Enter id here"
                  name="packageID"
                  className={`form-control mt-1 ${errors.packageID ? "is-invalid" : ""
                    }`}
                  maxLength={50}
                />
                {errors.packageID && (
                  <div className="invalid-feedback ms-2">
                    Package ID field is required and mush be valid UUID
                  </div>
                )}
                <label className="label-text mt-3">Enter Package Name </label>
                <input
                  {...register("packageName", { required: true })}
                  placeholder="Enter name here"
                  name="packageName"
                  className={`form-control mt-1 ${errors.packageName ? "is-invalid" : ""
                    }`}
                  maxLength={50}
                />
                {errors.packageName && (
                  <div className="invalid-feedback ms-2">
                    Package Name field is required
                  </div>
                )}
              </div>
              <br />
              <React.Fragment>
                {mutation.isLoading ? (
                  <Spinner animation="border" variant="info" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <React.Fragment>
                    <Button
                      variant=" margin-left10 btn-create right-margin10 btn btn-outline-secondary "
                      onClick={() => onClose(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-create btn-primary crbc-bg-color"
                    >
                      Create
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            </form>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}