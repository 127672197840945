import React, { useState } from "react";
import PagedFilterTable from "../table";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import { Button } from "react-bootstrap";
import IProduct from "../../models/Product";
import { AddUrlFilter } from "../../app/common/helper/MessageFilter"

interface IEstablishmentListProps {
    handleClose: any;
    field: any;
    StartDate: any;
    EndDate: any;
    pageIndex: number;
    pageSize: number;
    handleProductFilter: any;
    handleApplyFilter: any;
    selectedProduct: any;
}
export default function ProductList(props: IEstablishmentListProps) {
    const [rowSelection, setRowSelection] = useState(props.selectedProduct);
    const [tableJobs, setTableJobs] = useState([]);
    const [productName, setProductName] = useState(null);
    const columnHelper = createColumnHelper<IProduct>();
    const columns = [
        columnHelper.display({
            id: "select_productID",
            cell: props => <input type="checkbox" checked={rowSelection === props.row.original.productID} onChange={(e) => { onRowClick(props.row); }}></input>
        }),
        columnHelper.accessor('productName', {
            header: () => <span>Name</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.productName}</span>
                </div>
            ),
        }),
        columnHelper.accessor('productID', {
            header: () => <span>ID</span>,
            size: 50,
            cell: ({ row }) => (
                <div >
                    <span >{row.original.productID}</span>
                </div>
            ),
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.productID;
        setRowSelection((prevValue: any) => (prevValue === selectedValue ? null : selectedValue));
        setProductName((prevValue) => (prevValue === row.original.productName ? null : row.original.productName));
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        const establishmentResponse = await ServiceManager.ProductService.GetAll(options.pageIndex + 1, options.pageSize);
        setTableJobs(establishmentResponse.data);
        return establishmentResponse.data;
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            props.handleProductFilter(rowSelection, productName);
            props.handleClose();
            props.handleApplyFilter();
            AddUrlFilter("productId", rowSelection);
            AddUrlFilter("productName", productName);


        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <PagedFilterTable
                tableKey={"products-filter"}
                columns={columns}
                data={tableJobs}
                usePagination={false}
                useSearch={false}
                pageRecords={20}
                fnFetchData={fetchData}

            />
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary btn-md"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color btn-md"
                    className={` ${rowSelection === null ? "disabled" : ""}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )
};