import { Spinner, Stack } from "react-bootstrap";
import PagedFilterTable from "../../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import IProductVersion from "../../../models/ProductVersion";
import { ServiceManager } from "../../../services/ServiceManager";
import HubButton from "../../../components/button";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIStatusCode, SortOrder } from "../../../app/enums";
import { faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

interface IPopupProductVersions {
  handleClose: any;
  productId: any;
  ringId: any;
}

export default function ProductVersionsList(props: IPopupProductVersions) {
  const [productId, setProductId] = useState('');
  const [versionNumber, setVersionNumber] = useState('');
  const [versionSortOrder, setVersionSortOrder] = useState(SortOrder.DESC);
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC);

  const queryClient = useQueryClient();

  const columnHelper = createColumnHelper<IProductVersion>();
  const columns = [
    columnHelper.accessor('productName', {
      header: (header) => <div style={{ marginBottom: "5px" }}> Name </div>,
      size: 35,
      cell: ({ row }) => (
        <div>{row.original.productName}</div>
      ),
    }),
    columnHelper.accessor('versionNumber', {
      header: (header) => <div className="cursor-pointer" style={{ display: "table" }}  >Version <span id={header.column.id} onClick={() => onVersionClick()} style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "5px" }} ><FontAwesomeIcon icon={versionSortOrder === SortOrder.DESC ? faSortDesc : faSortAsc} className={versionSortOrder === SortOrder.DESC ? "mb-1 cursor-pointer" : "mt-1 cursor-pointer"} /></span></div>,
      size: 25,
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => (
        <div>{row.original.versionNumber}</div>
      ),
    }),
    columnHelper.accessor('dateReleased', {
      header: (header) => <div className="cursor-pointer" style={{ display: "table" }}  >Published <span id={header.column.id} onClick={() => onHeaderClick()} style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "5px" }}><FontAwesomeIcon icon={sortOrder === SortOrder.DESC ? faSortDesc : faSortAsc} className={sortOrder === SortOrder.DESC ? "mb-1 cursor-pointer" : "mt-1 cursor-pointer"} /></span></div>,
      size: 25,
      enableSorting: true,
      cell: ({ row }) => (
        format(new Date(row.original.dateReleased), "dd-MM-yyyy")
      ),
    })
  ];

  function onVersionClick() {
    if (versionSortOrder === SortOrder.ASC) {
      setVersionSortOrder(SortOrder.DESC)
    } else if (versionSortOrder === SortOrder.DESC) {
      setVersionSortOrder(SortOrder.ASC)
    }
  }

  function onHeaderClick() {
    if (sortOrder === SortOrder.ASC) {
      setSortOrder(SortOrder.DESC)
    } else if (sortOrder === SortOrder.DESC) {
      setSortOrder(SortOrder.ASC)
    }

  }

  async function fetchData(options: {
    pageIndex: number;
    pageSize: number;
    searchValue: string,
    sortBy: any,
    sortOrder: any
  }) {
    const ringsResponse = await ServiceManager.ProductService.GetProductVersions(props.productId, options.sortBy, options.sortOrder, options.pageIndex + 1, options.pageSize);
    if (options.sortBy === "versionNumber") {
      setVersionSortOrder(options.sortOrder);
    } else if (options.sortBy === "dateReleased") {
      setSortOrder(options.sortOrder);
    }
    return ringsResponse.data;
  }

  const handleConfirm = () => {
    assignMutation.mutate();
  }

  const handleUserSelection = (row: any) => {
    setProductId(row.original.productID);
    setVersionNumber(row.original.versionNumber);
  }

  const assignMutation = useMutation(async () => {
    const productVersion = { productId: productId, versionNumber: versionNumber };
    return await ServiceManager.ProductService.AssignRing(props.ringId, productVersion);
  }, {
    onSuccess: (data) => {
      if (data && data.data && (data.status === APIStatusCode.Ok || data.status === APIStatusCode.Created)) {
        queryClient.invalidateQueries();
        ServiceManager.ToastServer.showSuccess("Product version assigned successfully");
        props.handleClose();
      }
      else {
        let errorMessage = data.response.data.errors.errors
          ? data.response.data.errors.errors
          : "Unable to assign product version";
        ServiceManager.ToastServer.showError(errorMessage.toString());
      }
    },
  });

  return (
    <React.Fragment>
      <PagedFilterTable
        tableKey={`product-versions-${props.productId}`}
        columns={columns}
        data={[]}
        usePagination={true}
        useSearch={false}
        useSorting={true}
        pageRecords={10}
        fnFetchData={fetchData}
        title={
          <>
            <Stack direction="horizontal">
              <h5 className="ps-3 pt-3 pb-2">
                <b>Select Product Version</b>
              </h5>
            </Stack>
          </>
        }
        fnRowClick={handleUserSelection}
      />

      {!assignMutation.isLoading
        ? (
          <div className="mt-4">
            <HubButton
              className="btn right-margin10 btn-outline-secondary"
              FnOnClick={() => props.handleClose()}
              text="Cancel"
            ></HubButton>
            <HubButton
              type="button"
              variant=""
              className={`btn btn-primary crbc-bg-color  ${productId === ''
                ? "disabled"
                : ""
                }`}
              FnOnClick={function () {
                handleConfirm();
              }}
              text="Confirm"
            ></HubButton>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-4 w-25">
            <Spinner animation="border" variant="info" role="status" ></Spinner>
          </div>
        )
      }
    </React.Fragment>
  );

}