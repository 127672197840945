import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import oidcConfig from './models/config/oidc-config';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

function onSigninCallback() {
  const redirectPath = sessionStorage.getItem('redirectPath');
  if (redirectPath) {
    const queryParams = sessionStorage.getItem('queryParams');
    const redirectUrl = (redirectPath === null || redirectPath === "/") ? "/" : `${redirectPath}/${queryParams}`;
    sessionStorage.removeItem('redirectPath');
    sessionStorage.removeItem('queryParams');
    window.location.href = redirectUrl;
  }
  else {
    window.location.href = "/";
  }
}

const container = document.getElementById("root")!;
const root = createRoot(container);

const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback} automaticSilentRenew={false}>
          <QueryClientProvider client={queryClient}> 
            <App />
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
