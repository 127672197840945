import { GUID, toGuid } from "../app/common/helper/Guid";
import IRing from "../models/Ring";
import BaseService from "./BaseService";

export default class ProductService extends BaseService {
  controllerName = "products";

  async GetAll(page: number, pageSize: number): Promise<any> {
    const resp = await this.Get("", {
      page: page,
      pageSize: pageSize,
    });

    return resp ? resp.data : null;
  }
  async Update(productID: GUID, product: object): Promise<any> {
    const resp = await this.Put(productID, product);
    return resp.data ? resp.data : resp;
  }
  async Create(productName: string): Promise<any> {
    const resp = await this.Post("", {
      productName: productName,
    });
    return resp.data ? resp.data : resp;
  }
  async GetPackagesById(productID: GUID): Promise<any> {
    const resp = await this.Get(`${productID}/packages`, { });
    return resp ? resp.data : null;
  }
  async UpdatePackage(productID: GUID, packageID: GUID, packageName:Object): Promise<any> {
    const resp = await this.Put(toGuid(`${productID}/packages/${packageID}`), {packageName});
    return resp ? resp.data : null;
  }
  async DeletePackageByIds(productID: GUID, packageID: GUID): Promise<any> {
    const resp = await this.Delete(`${productID}/packages/${packageID}`);
    return resp;
  }
  async CreatePackage( ProductID: GUID,packageID: GUID,    packageName: string  ): Promise<any> {
    const resp = await this.Post(`${ProductID}/packages`, {
      packageID: packageID,
      productID: ProductID,
      packageName: packageName,
    });
    return resp.data ? resp.data : resp;
  }
  async GetAllRings() : Promise<any> {
    const resp = await this.Get("/rings", {});

    return resp ? resp.data : null;
  }

  async CreateRing(data: IRing): Promise<any> {
    const resp = await this.Post("rings", {
      ringName: data.ringName,
      isDefaultForEstablishments: data.isDefaultForEstablishments,
      isPublic: data.isPublic,
      description: data.description
    });
    return resp.data ? resp.data : resp;
  }
  
  async EditRing(data: IRing): Promise<any> {
    const resp = await this.Put(`rings/${data.ringID}`, {
      ringName: data.ringName,
      isDefaultForEstablishments: data.isDefaultForEstablishments,
      isPublic: data.isPublic,
      description: data.description
    });
    return resp.data ? resp.data : resp;
  }

  async DeleteRing(ringID: number): Promise<any> {
    const resp = await this.Delete(`rings/${ringID}`);
    return resp.data ? resp.data : resp;
  }

  async GetProductsByRingId(ringID: number, includeProductVersions: boolean = false, includeEstablishments: boolean = false): Promise<any> {
    const resp = await this.Get(`rings/${ringID}`, { includeProductVersions: includeProductVersions, includeEstablishments: includeEstablishments });
    return resp.data ? resp.data : resp;
  }

  async GetProductVersions(productId: any, sortBy: any, sortOrder: any, page : number, pageSize : number) : Promise<any> {
    const resp = await this.Get(`${productId}/versions`, {sortBy: sortBy, sortOrder: sortOrder, page: page, pageSize: pageSize,});

    return resp ? resp.data : null;
  }

  async AssignRing(ringId: any, productVersion: any) : Promise<any> {
    const resp = await this.Patch(`rings/${ringId}`, productVersion);

    return resp.data ? resp.data : resp;
  }

  async UnassignRing(ringId: any, productId: any) : Promise<any> {
    const resp = await this.Delete(`${productId}/rings/${ringId}`);

    return resp.data ? resp.data : resp;
  }
}
