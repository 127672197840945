import { Spinner } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";

export default function Login() {
  const auth = useAuth();

  if (!auth.isAuthenticated && !auth.isLoading) {
    auth.signinRedirect();
  }

  if (auth.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className='text-center loading'>
      <Spinner animation="border" role="status"></Spinner>
    </div>
  )
}