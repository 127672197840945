import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../services/ServiceManager";
import Card from 'react-bootstrap/Card';
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import ReactSwitch from "react-switch";
import { APIStatusCode } from "../../app/enums";
import { NavLink } from "react-router-dom";
import CRBCLogo from "../../components/logo";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import { Permissions } from "../../app/enums";
import HUBTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED, CHANGE_PARENT_LEVEL } from "../../app/constants";
import Skeleton from "react-loading-skeleton";

const ProductCard = ({data , establishmentId, childEstablishments, isParent} : any) => {
    console.log(`isParent : ${isParent}` );
    const [checked, setChecked] = useState(false);
    const queryClient = useQueryClient();
    const canAssignProducts = usePermissionCheck(Permissions.CanAssignProducts);
    const mutation = useMutation({
        mutationFn: (enable : boolean) => {
            if(enable) {
                return ServiceManager.EstablishmentService.CreateProduct(establishmentId, data.productId);
            }
            else {
                return ServiceManager.EstablishmentService.DeleteProduct(establishmentId, data.productId);
            }
        },
        onError: (error, variables, context) => {
            
        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                setChecked(!checked);
                ServiceManager.ToastServer.showError("Unable to synchronise establishment");
            }
            else {
                if(data.status === APIStatusCode.Ok || data.status === APIStatusCode.Created || data.status === APIStatusCode.NoContent) {
                    queryClient.invalidateQueries({ queryKey: [`establishmentProducts-${establishmentId}`] });
                }
                else {
                    let errorMessage = data.response.data.errors.errors
                    ? data.response.data.errors.errors
                    : "Some error occured";
                    ServiceManager.ToastServer.showError(errorMessage.toString());
                    setChecked(!checked);
                }
            }
        }
    });

    const childMutation = useMutation({
        mutationFn: (childData : any) => {
            if(childData.enable) {
                return ServiceManager.EstablishmentService.CreateProduct(childData.establishmentID, data.productId);
            }
            else {
                return ServiceManager.EstablishmentService.DeleteProduct(childData.establishmentID, data.productId);
            }
        },
        onError: (error, variables, context) => {
            
        },
        onSuccess: (data, variables, context) => {
           
        }
    });

    useEffect(() => {
        setChecked(data.allowed);
    }, [data]);

    const handleChange = async (checked : boolean) => {
        setChecked(checked);
        await mutation.mutateAsync(checked);

        await toggleChild(checked, childEstablishments);
    };

    const toggleChild = async (checked : any, childEstablishments : any) => {
        childEstablishments.forEach(async (childEstablishment : any) => {
            await childMutation.mutateAsync({ 'enable': checked, 'establishmentID': childEstablishment.establishmentID });

            await toggleChild(checked, childEstablishment.children);
        });
    }

    return (
        <div className="col-sm col-md col-xl-4 col-xxl-3 mt-3">
        <Card style={{ height: '10em' }} key={data.productId}>
            <Card.Body>
                <Stack direction="horizontal" gap={3}>
                    <div className="d-flex justify-content-center align-items-center blue-bg rounded-3 package-icon">
                        <div className="d-flex justify-content-center align-items-center crbc-icon package-icon-inner rounded-3 white-background-shadow">
                            <CRBCLogo />
                        </div>
                    </div>
                    <Stack>
                        <Card.Title as="h6">{data.productName}</Card.Title>
                        <Card.Subtitle className="text-muted" as="p">{data.latestPackageVersion !== null ? `version ${data.latestPackageVersion}` : ""}</Card.Subtitle>
                    </Stack>
                    <div className="d-flex justify-content-center align-items-center">
                        {canAssignProducts && isParent
                            ? 
                            <ReactSwitch onChange={handleChange} height={20} onColor="#10A760" offColor="#E2E2E3"
                            handleDiameter={17} width={40} checkedIcon={false} uncheckedIcon={false} checked={checked}
                            disabled={mutation.isLoading ? true : false} />
                            :
                            <HUBTooltip message={canAssignProducts ? CHANGE_PARENT_LEVEL : PERMISSION_REQUIRED} placement="bottom">
                                <div>
                                    <ReactSwitch onChange={handleChange} height={20} onColor="#10A760" offColor="#E2E2E3"
                                        handleDiameter={17} width={40} checkedIcon={false} uncheckedIcon={false} checked={checked}
                                        disabled />
                                </div>
                            </HUBTooltip>
                        }
                    </div>
                </Stack>
                <Stack className="mt-4">
                    <p className={`p-1 ps-2 pe-2 ${data.installed ? "installed" : "not-installed"}`}>
                        <FontAwesomeIcon icon={faCircle} size="2xs" className="icon"/> <span className="ms-1">{data.installed ? "Installed" : "Not Installed"}</span>
                    </p>
                    <NavLink className="align-self-end non-link" to={`/product/${data.productId}/${data.productName}`}>
                        View product
                    </NavLink>
                </Stack>
            </Card.Body>
        </Card>
        </div>
    )
}

export default function EstablishmentProducts(props: any) {

    const { isLoading, data } = useQuery([`establishmentProducts-${props.establishmentId}`], () =>
        ServiceManager.EstablishmentService.GetProductsById(props.establishmentId), {
            refetchOnWindowFocus: false
        }
    );

    return (
        <React.Fragment>
            {!isLoading && data.data != null &&
                <div className="products">
                    <React.Fragment>
                        <Tabs
                            defaultActiveKey="all"
                            className="mt-4">
                            <Tab eventKey="all" title="All">
                                <div className="container-fluid">
                                    <div className="row pb-3">
                                        {data.data.map((product: any) => {
                                            return <ProductCard key={product.productId} data={product} establishmentId={props.establishmentId}
                                                        childEstablishments={props.childEstablishments} isParent={props.isParent}></ProductCard>
                                        })}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="allowed" title="Allowed">
                                <div className="container-fluid">
                                    <div className="row pb-3">
                                        {data.data.filter((p: any) => p.allowed).length > 0 ? data.data.filter((p: any) => p.allowed).map((product: any) => {
                                            return <ProductCard key={product.productId} data={product} establishmentId={props.establishmentId}
                                                            childEstablishments={props.childEstablishments} isParent={props.isParent}></ProductCard>
                                        }) : <div className="text-muted mt-3"><p>No allowed products found</p></div>}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="installed" title="Installed">
                                <div className="container-fluid">
                                    <div className="row pb-3">
                                        {data.data.filter((p: any) => p.installed).length > 0 ? data.data.filter((p: any) => p.installed).map((product: any) => {
                                            return <ProductCard key={product.productId} data={product} establishmentId={props.establishmentId}
                                                            childEstablishments={props.childEstablishments} isParent={props.isParent}></ProductCard>
                                        }) : <div className="text-muted"><p>No installed products found</p></div>}

                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </React.Fragment>
                </div>}
            {isLoading &&
                <div className="container-fluid">
                    <div className="row pb-3">
                        <div className="col-sm col-md col-xl-4 col-xxl-3 mt-3">
                            <div className="mt-5">
                                <Skeleton style={{ height: '10em' }} />
                            </div>
                        </div>
                        <div className="col-sm col-md col-xl-4 col-xxl-3 mt-3">
                            <div className="mt-5">
                                <Skeleton style={{ height: '10em' }} />
                            </div>
                        </div>
                        <div className="col-sm col-md col-xl-4 col-xxl-3 mt-3">
                            <div className="mt-5">
                                <Skeleton style={{ height: '10em' }} />
                            </div>
                        </div>
                        <div className="col-sm col-md col-xl-4 col-xxl-3 mt-3">
                            <div className="mt-5">
                                <Skeleton style={{ height: '10em' }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );

}