import { useState, useEffect } from "react";
import React from "react";
import { Button, CloseButton, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../components/modal";
import EstablishmentList from "./establishment-filter-List"
import DeviceFilterList from "./device-filter-List"
import PackageFilterList from "./package-filter-list"
import ProductList from "./product-filter-list"
import DeviceFilterForm from "./text-filter"
import TimeRangeFilterForm from "./time-range-filter"
import LoglevelFilter from "./loglevel-filter";
import { RemoveUrlFilter, GetDateTimer } from "../../app/common/helper/MessageFilter"
import { LogLevel, MessageFilterType } from "../../app/enums";

interface IFilterProps {
    startDate: any;
    endDate: any;
    pageIndex: number;
    pageSize: number;
    handleSelect: any;
    callbackLoglevel: any;
    callbackTimeRange: any;
    callbackCategory: any;
    callbackEstablishment: any;
    callbackDevice: any;
    callbackProduct: any;
    callbackPackage: any;
    callbackMsgId: any;
}

interface IFilterListProps {
    type: any;
    value: any;
}


export default function Filter(props: IFilterProps) {
    const [FilterList, setFilterList] = useState<IFilterListProps[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [establishmentId, setEstablishmentId] = useState("");
    const [productId, setProductId] = useState("");
    const [isFilterTag, setFilterTag] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState("");
    const [selectedPackage, setSelectedPackage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");

    const [showEstablishmentModal, setShowEstablishmentModal] = useState(false);
    const [showDeviceModal, setShowDeviceModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showPackageModal, setShowPackageModal] = useState(false);
    const [showDateTimeModal, setShowDateTimeModal] = useState(false);
    const [showLogLevelModal, setShowLogLevelModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);

    const handleCloseEstablishmentModal = () => setShowEstablishmentModal(false);
    const handleCloseDeviceModal = () => setShowDeviceModal(false);
    const handleClosePackageModal = () => setShowPackageModal(false);
    const handleCloseProductModal = () => setShowProductModal(false);
    const handleCloseDateTimeModal = () => setShowDateTimeModal(false);
    const handleCloseLogLevelModal = () => setShowLogLevelModal(false);
    const handleCloseCategoryModal = () => setShowCategoryModal(false);


    useEffect(() => {
        if (isFilterTag !== true) {
            let queryparams = window.location.search.split('&')
            const params = new URLSearchParams(window.location.search);
            for (var index = 0; index < queryparams.length; index++) {
                let urlParam = queryparams[index].replace("?", "");
                if (urlParam.match(/^establishmentId.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.Establishment) > -1)) {
                        deleteFilter(MessageFilterType.Establishment);
                        const establishmentName = params.get('establishmentName');
                        let filter = { value: establishmentName, type: MessageFilterType.Establishment } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                        setEstablishmentId(params.get('establishmentId') as string)
                    }
                }
                if (urlParam.match(/^deviceId.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.Device) > -1)) {
                        deleteFilter(MessageFilterType.Device);
                        const deviceName = params.get('deviceName');
                        let filter = { value: deviceName, type: MessageFilterType.Device } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                        setSelectedDevice(params.get('deviceId') as string)
                    }
                }
                if (urlParam.match(/^finishDate.*$/) || urlParam.match(/^startDate.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.DateTime) > -1)) {
                        deleteFilter(MessageFilterType.DateTime);
                        const timer = params.get('timer');
                        const unit = params.get('unit');
                        const dateTimer = GetDateTimer(Number(timer), unit)
                        let filter = { value: dateTimer, type: MessageFilterType.DateTime } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                    }
                }
                if (urlParam.match(/^productId.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.Product) > -1)) {
                        deleteFilter(MessageFilterType.Product);
                        const productName = params.get('productName');
                        let filter = { value: productName, type: MessageFilterType.Product } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                        setProductId(params.get('productId') as string)
                    }
                }
                if (urlParam.match(/^packageId.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.Package) > -1)) {
                        deleteFilter(MessageFilterType.Package);
                        const packageName = params.get('packageName');
                        let filter = { value: packageName, type: MessageFilterType.Package } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                        setSelectedPackage(params.get('packageId') as string)
                    }
                }
                if (urlParam.match(/^logLevel.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.LogLevel) > -1)) {
                        deleteFilter(MessageFilterType.LogLevel);
                        const logLevel = params.get('logLevel');
                        let filter = { value: LogLevel[Number(logLevel)], type: MessageFilterType.LogLevel } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                    }
                }
                if (urlParam.match(/^category.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.Category) > -1)) {
                        deleteFilter(MessageFilterType.Category);
                        const category = params.get('category');
                        let filter = { value: category, type: MessageFilterType.Category } as IFilterListProps;
                        setFilterList((prev) => [...prev, filter]);
                        setSelectedCategory(category as string);
                    }
                }
                if (urlParam.match(/^messageId.*$/)) {
                    if (!(FilterList.findIndex(obj => obj.type === MessageFilterType.MessageId) > -1)) {
                        deleteFilter(MessageFilterType.MessageId);
                        const messageId = params.get('messageId');
                        if (messageId) {
                            let filter = { value: `${messageId.slice(0, 4)}***${messageId.slice(-4)}`, type: MessageFilterType.MessageId } as IFilterListProps;
                            setFilterList((prev) => [...prev, filter]);
                        }
                    }
                }
            }
            setFilterTag(true);
        }
        // eslint-disable-next-line
    }, [FilterList, isFilterTag]);
    const handleValueChange = (event: any) => {
        event.preventDefault();
        const data = event.target.value ? event.target.value : event.target.id;
        if (data === MessageFilterType.Establishment) {
            setShowEstablishmentModal(true);
        } else if (data === MessageFilterType.Device) {
            setShowDeviceModal(true);
        }
        else if (data === MessageFilterType.Product) {
            props.callbackProduct(productId);
            setShowProductModal(true);
        }
        else if (data === MessageFilterType.Package) {
            setShowPackageModal(true);
        }
        else if (data === MessageFilterType.DateTime) {
            setShowDateTimeModal(true);
        }
        else if (data === MessageFilterType.LogLevel) {
            setShowLogLevelModal(true);
        }
        else if (data === MessageFilterType.Category) {
            setShowCategoryModal(true);
        }
        setIsOpen(false);
    };
    function deleteFilter(type: string) {
        setFilterList((filterList) => filterList.filter((current) => current.type !== type));
        const index = FilterList.findIndex(obj => obj.type === type);
        if (index !== -1) {
            FilterList.splice(index, 1);
        }
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        const data = event.target.id;
        var newFilterList = [...FilterList];
        var index = newFilterList.findIndex(obj => obj.type === event.target.id)
        if (index !== -1) {
            newFilterList.splice(index, 1);
            setFilterList(newFilterList);
        }
        if (data === MessageFilterType.Establishment) {
            props.callbackEstablishment(null);
            props.callbackDevice(null, null);
            setEstablishmentId("");
            deleteFilter("Device");
            RemoveUrlFilter("establishmentId");
            RemoveUrlFilter("establishmentName");
            RemoveUrlFilter("deviceId");
            RemoveUrlFilter("deviceName");
        } else if (data === MessageFilterType.Device) {
            props.callbackEstablishment(establishmentId);
            props.callbackDevice(establishmentId, null);
            RemoveUrlFilter("deviceId");
            RemoveUrlFilter("deviceName");
            setSelectedDevice("");
        }
        else if (data === MessageFilterType.Product) {
            props.callbackProduct(null, null);
            props.callbackPackage(null, null);
            setProductId("");
            deleteFilter("Package");
            RemoveUrlFilter("productId");
            RemoveUrlFilter("productName");
            RemoveUrlFilter("packageId");
            RemoveUrlFilter("packageName");
        }
        else if (data === MessageFilterType.Package) {
            props.callbackProduct(productId);
            props.callbackPackage(null, null);
            RemoveUrlFilter("packageId");
            RemoveUrlFilter("packageName");
            setSelectedPackage("");
        }
        else if (data === MessageFilterType.DateTime) {
            props.callbackTimeRange(null);

        } else if (data === MessageFilterType.LogLevel) {
            props.callbackLoglevel(null);
            RemoveUrlFilter("logLevel");
        }
        else if (data === MessageFilterType.Category) {
            props.callbackCategory(null);
            setSelectedCategory("");
            RemoveUrlFilter("category");
        }
        else if (data === MessageFilterType.MessageId) {
            props.callbackMsgId(null);
            RemoveUrlFilter("messageId");
        }
        setIsOpen(false);
    };

    const EstablishmentListHeader = () => {
        return (
            <Stack>
                <p className="heading">Establishments</p>
                <p className="text-muted">Please select an establishment.</p>
            </Stack>
        )
    }

    const DeviceListHeader = () => {
        return (
            <Stack>
                <p className="heading">Devices</p>
                <p className="text-muted">Please select a device.</p>
            </Stack>
        )
    }

    const ProductListHeader = () => {
        return (
            <Stack>
                <p className="heading">Products</p>
                <p className="text-muted">Please select a product.</p>
            </Stack>
        )
    }

    const PackageListHeader = () => {
        return (
            <Stack>
                <p className="heading">Packages</p>
                <p className="text-muted">Please select a package.</p>
            </Stack>
        )
    }
    const DateTimeHeader = () => {
        return (
            <Stack>
                <p className="heading">Date/Time</p>
                <p className="text-muted">Please select a time range.</p>
            </Stack>
        )
    }

    const LogLevelHeader = () => {
        return (
            <Stack>
                <p className="heading">LogLevel</p>
                <p className="text-muted">Please select a log Level.</p>
            </Stack>
        )
    }

    const CategoryHeader = () => {
        return (
            <Stack>
                <p className="heading">Category</p>
                <p className="text-muted">Please select a category.</p>
            </Stack>
        )
    }

    async function handleLApplyFilter() {

    }

    async function handleLogLevelFilter(Loglevel: number) {
        deleteFilter(MessageFilterType.LogLevel);
        let filter = { value: LogLevel[Number(Loglevel)], type: MessageFilterType.LogLevel } as IFilterListProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackLoglevel(Loglevel);
    }

    async function handleTimeRange(startDate: string, finishDate: string, selectedTimer: string, selectedUnit: string) {
        if (selectedTimer.toString() !== "0") {
            deleteFilter(MessageFilterType.DateTime);
            props.callbackTimeRange(startDate, finishDate, selectedTimer);
            const dateTimer = GetDateTimer(Number(selectedTimer), selectedUnit)
            let filter = { value: dateTimer, type: MessageFilterType.DateTime } as IFilterListProps;
            setFilterList((prev) => [...prev, filter]);
        }
    }

    async function handleCategoryFilter(categoryName: string) {
        deleteFilter(MessageFilterType.Category);
        let filter = { value: categoryName, type: MessageFilterType.Category } as IFilterListProps;
        setFilterList((prev) => [...prev, filter]);
        props.callbackCategory(categoryName);
        setSelectedCategory(categoryName);
    }

    async function handleEstablishmentFilter(establishmentId: any, establishmentName: any) {
        if (establishmentName !== null) {
            deleteFilter(MessageFilterType.Establishment);
            setEstablishmentId(establishmentId);
            let filter = { value: establishmentName, type: MessageFilterType.Establishment } as IFilterListProps;
            setFilterList((prev) => [...prev, filter]);
            props.callbackEstablishment(establishmentId);
        }
    }

    async function handleDeviceFilter(establishmentId: any, deviceid: any, deviceName: any) {
        if (deviceName !== null) {
            deleteFilter(MessageFilterType.Device);
            setSelectedDevice(deviceid);
            let filter = { value: deviceName, type: MessageFilterType.Device } as IFilterListProps;
            setFilterList((prev) => [...prev, filter]);
            props.callbackDevice(establishmentId, deviceid);
        }
    }

    async function handleProductFilter(productId: any, productName: any) {
        if (productName !== null) {
            deleteFilter(MessageFilterType.Product);
            setProductId(productId);
            let filter = { value: productName, type: MessageFilterType.Product } as IFilterListProps;
            props.callbackProduct(productId, productName);
            setFilterList((prev) => [...prev, filter])
        }
    }

    async function handlePackageFilter(productId: any, packageId: any, packageName: any) {
        if (packageName !== null) {
            deleteFilter(MessageFilterType.Package);
            setSelectedPackage(packageId);
            props.callbackPackage(productId, packageId);
            let filter = { value: packageName, type: MessageFilterType.Package } as IFilterListProps;
            setFilterList((prev) => [...prev, filter])
        }
    }

    return (
        <React.Fragment>
            <Stack direction="horizontal" gap={3} className="w-75">
                <Button className="btn btn-light" onClick={() => { setIsOpen(!isOpen) }} ><FontAwesomeIcon icon={faFilter} /> Filter</Button>
                {FilterList?.map((element, index) => {
                    return (
                        <div key={index} id={element.type}>
                            <div className="btn btn-light" id={element.type}>
                                {

                                    element.type === "Date/Time" ?
                                        <div id={element.type} className="filter-wrap d-flex align-items-center font-14" onClick={handleValueChange}> {element.value}</div>
                                        : <div className="filter-wrap d-flex align-items-center font-14"> <div id={element.type} onClick={handleValueChange}>
                                            {element.value}
                                        </div><CloseButton id={element.type} value={element.value} className="ms-2 p-0" onClick={handleCancel} /></div>
                                }
                            </div>
                        </div>
                    );
                })}
                {isOpen && <div className="filter__dropdown">
                    <input className="form-control mb-1" id="myInput" type="text" placeholder="Filter by..." disabled></input>
                    <div className="margin-left10">
                        <option className="filter-option mb-1" onClick={handleValueChange} style={{ cursor: "pointer" }}>Establishment</option>
                        {establishmentId === "" ?
                            <option className="filter-option mb-1" disabled onClick={handleValueChange}>Device</option> :
                            <option className="filter-option mb-1" onClick={handleValueChange}>Device</option>
                        }
                        <option className="filter-option mb-1" onClick={handleValueChange}>Product</option>
                        {productId === "" ?
                            <option className="filter-option mb-1" disabled onClick={handleValueChange}>Package</option> :
                            <option className="filter-option mb-1" onClick={handleValueChange}>Package</option>
                        }
                        <option className="filter-option mb-1" onClick={handleValueChange}>Date/Time</option>
                        <option className="filter-option mb-1" onClick={handleValueChange}>Log Level</option>
                        <option className="filter-option mb-1" onClick={handleValueChange}>Category</option>
                    </div>

                </div>}
            </Stack>
            <CustomModal isShow={showEstablishmentModal} handleClose={handleCloseEstablishmentModal} header={<EstablishmentListHeader />} size="lg">
                <EstablishmentList handleClose={handleCloseEstablishmentModal} handleEstablishmentFilter={handleEstablishmentFilter} handleApplyFilter={handleLApplyFilter} selectedEstablishment={establishmentId} StartDate={props.startDate} EndDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Device" />
            </CustomModal>
            <CustomModal isShow={showDeviceModal} handleClose={handleCloseDeviceModal} header={<DeviceListHeader />} size="lg">
                <DeviceFilterList establishmentId={establishmentId} handleClose={handleCloseDeviceModal} handleDeviceFilter={handleDeviceFilter} handleApplyFilter={handleLApplyFilter} selectedDevice={selectedDevice} StartDate={props.startDate} EndDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Device" />
            </CustomModal>
            <CustomModal isShow={showProductModal} handleClose={handleCloseProductModal} header={<ProductListHeader />} size="lg">
                <ProductList handleClose={handleCloseProductModal} handleProductFilter={handleProductFilter} handleApplyFilter={handleLApplyFilter} selectedProduct={productId} StartDate={props.startDate} EndDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Product" />
            </CustomModal>
            <CustomModal isShow={showPackageModal} handleClose={handleClosePackageModal} header={<PackageListHeader />} size="lg">
                <PackageFilterList productId={productId} handleClose={handleClosePackageModal} handlePackageFilter={handlePackageFilter} handleApplyFilter={handleLApplyFilter} selectedPackage={selectedPackage} StartDate={props.startDate} EndDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Package" />
            </CustomModal>
            <CustomModal isShow={showDateTimeModal} handleClose={handleCloseDateTimeModal} header={<DateTimeHeader />} customClass="modal-content-custom" size="md">
                <TimeRangeFilterForm handleClose={handleCloseDateTimeModal} handleTimeRangeFilter={handleTimeRange} handleApplyFilter={handleLApplyFilter} StartDate={props.startDate} EndDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Time Range" />
            </CustomModal>
            <CustomModal isShow={showLogLevelModal} handleClose={handleCloseLogLevelModal} header={<LogLevelHeader />} size="md">
                <LoglevelFilter handleClose={handleCloseLogLevelModal} handleLogLevelFilter={handleLogLevelFilter} handleApplyFilter={handleLApplyFilter} startDate={props.startDate} endDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Log Level" />
            </CustomModal>
            <CustomModal isShow={showCategoryModal} handleClose={handleCloseCategoryModal} header={<CategoryHeader />} size="md">
                <DeviceFilterForm handleClose={handleCloseCategoryModal} handleCategoryFilter={handleCategoryFilter} handleApplyFilter={handleLApplyFilter} selectedCategory={selectedCategory} StartDate={props.startDate} EndDate={props.endDate} pageIndex={props.pageIndex} pageSize={props.pageSize} field="Category" />
            </CustomModal>

        </React.Fragment>
    );
}

