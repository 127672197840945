import React, { useState, } from "react";
import { Button, Stack } from "react-bootstrap"
import { timeUnit } from "../../../app/enums"

interface ILastUpdateAttemptProps {
    handleClose: any;
    handleLastUpdateAttemptFilter: any;
    selectedLastUpdateAttemptDescription: any;
}

export default function LastUpdateAttemptFilter(props: ILastUpdateAttemptProps) {

    const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
    const [selectedDescription, setSelectedDescription] = useState(props.selectedLastUpdateAttemptDescription);

    let lastUpdateAttempttimerData: { time: number, timeUnit: timeUnit, description: string }[] = [
        { "time": 30, "timeUnit": timeUnit.minute, "description": "Last 30 minutes" },
        { "time": 1, "timeUnit": timeUnit.hour, "description": "Last hour" },
        { "time": 4, "timeUnit": timeUnit.hour, "description": "Last 4 hours" },
        { "time": 12, "timeUnit": timeUnit.hour, "description": "Last 12 hours" },
        { "time": 1, "timeUnit": timeUnit.day, "description": "Last day" },
        { "time": 3, "timeUnit": timeUnit.day, "description": "Last 3 days" },
        { "time": 7, "timeUnit": timeUnit.day, "description": "Last week" },
        { "time": 14, "timeUnit": timeUnit.day, "description": "Last 2 weeks" },
        { "time": 1, "timeUnit": timeUnit.month, "description": "Last month" }
    ];


    function calculateDateTime(timeData : any) {
        var lastUpdateAttempt = new Date();

        switch (timeData.timeUnit) {
            case timeUnit.minute:
                lastUpdateAttempt.setMinutes(lastUpdateAttempt.getMinutes() - timeData.time);
                break;
            case timeUnit.hour:
                lastUpdateAttempt.setHours(lastUpdateAttempt.getHours() - timeData.time);
                break;
            case timeUnit.day:
                lastUpdateAttempt.setDate(lastUpdateAttempt.getDate() - timeData.time);
                break;
            case timeUnit.month:
                lastUpdateAttempt.setMonth(lastUpdateAttempt.getMonth() - timeData.time);
                break;
            default:
                throw new Error('Time unit invalid!');
        }
        
        setSelectedDescription(timeData.description);
        setSelectedDate(lastUpdateAttempt.toISOString());
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            let lastUpdateAttemptObj = { dateLastUpdateAttempt: selectedDate, description: selectedDescription };
            props.handleLastUpdateAttemptFilter(lastUpdateAttemptObj);
            props.handleClose();

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div id="establishmentFilterTable">
                <Stack>
                    {lastUpdateAttempttimerData.map((data, index) =>
                    <form>
                        <Stack direction="horizontal" className="mt-2">
                                <input type="radio" name="lastUpdateAttempt" checked={selectedDescription === data.description} onChange={() => calculateDateTime(data)} /> <span className="ms-2">{data.description}</span>
                        </Stack>
                        </form>
                    )}
                </Stack>
            </div>
            <div className="mt-4">
                <Button
                    variant="popup-btn btn-outline-secondary"
                    style={{ width: '49%' }}
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn ms-1 btn btn-primary crbc-bg-color"
                    style={{ width: '49%' }}
                    className={` ${selectedDescription ? "" : "disabled"}`}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            </div>
        </React.Fragment>
    )
}