import React from "react";
export default function Unauthorised() {
  return (
    <React.Fragment>      
      <div className="mainErrorContainer">
        <div className="error-container">
          <div className="error-code">Permission Error</div>
          <div className="error-message"></div>
          <p>You do not have the relevant permission to view this content, please contact your line manager if you believe this is a mistake.</p>
          <a className="err-back-button" href="/">Go Back to Home</a>
        </div>
    </div>
    </React.Fragment>
  );
}
