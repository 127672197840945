import React, { useState, useEffect } from "react";
import { Card, OverlayTrigger, Spinner, Stack, Tooltip } from "react-bootstrap";
import HubButton from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExclamationCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ServiceManager } from "../../services/ServiceManager";
import { APIStatusCode, Permissions } from "../../app/enums";
import CustomModal from "../../components/modal";
import IconElement from "../../components/icon";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import HUBSpinner from "../../components/spinner";

export default function EstablishmentClient(props: any) {
    const [revealCredentials, setRevealCredentials] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [showClientIdTooltip, setShowClientidTooltip] = useState(false);
    const [showClientSecretTooltip, setShowClientSecretTooltip] = useState(false);
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const handleCloseResetModal = () => setShowResetModal(false);

    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const { isLoading, data } = useQuery(["establishmentClients-" + props.establishmentId], () =>
        ServiceManager.EstablishmentService.GetClientsById(props.establishmentId), {
            refetchOnWindowFocus: false
        }
    );

    const createMutation = useMutation(async (isFromDelete : boolean) => {
        return await ServiceManager.EstablishmentService.CreateClient(props.establishmentId);
    }, {
        onSuccess: (data, isFromDelete, context) => {
            if(data && data.data && data.status === APIStatusCode.Created) {
                if(isFromDelete) {
                    handleCloseResetModal();
                    ServiceManager.ToastServer.showSuccess("Keys have been reset successfully.");
                }
                else {
                    setRevealCredentials(true);
                }

                setClientId(data.data.clientID);
                setClientSecret(data.data.secretKey);
            }
            else {
                let errorMessage = data.response.data.errors.errors
                            ? data.response.data.errors.errors
                            : "Unable to create keys";
                        ServiceManager.ToastServer.showError(errorMessage.toString());
            }
        },
    });

    const deleteMutation = useMutation(async () => {
        return await ServiceManager.EstablishmentService.DeleteClient(props.establishmentId, clientId);
    }, {
        onSuccess: (data) => {
            if(data && data.status === APIStatusCode.NoContent) {
                createMutation.mutate(true);
            }
            else {
                let errorMessage = data.response.data.errors.errors
                            ? data.response.data.errors.errors
                            : "Unable to reset keys";
                        ServiceManager.ToastServer.showError(errorMessage.toString());
            }
        },
    });

    const checkCredentials = () => {
        if(clientId !== "" && clientSecret !== "") {
            setRevealCredentials(true);
        }
        else {
            createMutation.mutate(false);
        }
    }

    useEffect(() => {
        if (!isLoading) {
            if (data && data.data && data.data.length > 0) {
                setClientId(data.data[0].clientID);
                setClientSecret(data.data[0].secretKey);
            }
        }
    }, [isLoading, data]);

    const ResetConfirmationForm = () => {
        return (
            <Stack>
                <p className="heading text-center">Reset Keys</p>
                <p className="text-center">Generating new keys for this establishment will cause any connected Hub Server to stop communicating until the new keys are configured in that software. Are you sure you want to proceed?</p>
                {
                    !createMutation.isLoading && !deleteMutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <HubButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseResetModal} />
                            <HubButton text="Reset" className="popup-btn right-margin10 btn btn-warning w-50" FnOnClick={() => { deleteMutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <React.Fragment>
        <div className="client">
            <Card style={{ height: '10em' }}>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <Stack direction="horizontal">
                        <Card.Title as="h6">Hub Server</Card.Title>
                        {canEditEstablishments && clientId !== '' && <span className="ms-auto text-danger fw-bold cursor-pointer" onClick={() => setShowResetModal(true)}>Reset</span>}
                    </Stack>
                    
                    {!createMutation.isLoading && !isLoading && !revealCredentials && <HubButton text="Reveal"
                        className="btn btn-light w-100 button-light"
                        FnOnClick={() => checkCredentials()}
                        icon={<FontAwesomeIcon icon={faEye} />}></HubButton>}

                    {(isLoading || createMutation.isLoading) && !revealCredentials && <div className="d-flex justify-content-center w-100">
                                <HUBSpinner color="crbc-color" />
                            </div>}

                        {revealCredentials &&
                            <div className="container">
                                <div className="row my-2">
                                    <div className="ps-0 col-3 font-family14">ID</div>
                                    <div className="ps-0 col-8 font-11">{clientId}</div>
                                    <div className="ps-0 col-1">
                                    <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip">Copied to clipboard</Tooltip>}
                                            show={showClientIdTooltip}
                                        >
                                        <FontAwesomeIcon className="cursor-pointer crbc-color" 
                                        title="Copy to clipboard" icon={faCopy} onClick={() => { 
                                            navigator.clipboard.writeText(clientId);
                                            setShowClientidTooltip(true); 
                                                     setTimeout(() => {
                                                        setShowClientidTooltip(false);
                                                      }, 2000);
                                            
                                        }} />
                                    </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="ps-0 col-3 font-family14">Secret</div>
                                    <div className="ps-0 col-8 font-11">{clientSecret}</div>
                                    <div className="ps-0 col-1">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip">Copied to clipboard</Tooltip>}
                                            show={showClientSecretTooltip}
                                        >
                                            <FontAwesomeIcon className="cursor-pointer crbc-color"
                                                title="Copy to clipboard" icon={faCopy} onClick={() => {
                                                     navigator.clipboard.writeText(clientSecret); 
                                                     setShowClientSecretTooltip(true); 
                                                     setTimeout(() => {
                                                        setShowClientSecretTooltip(false);
                                                      }, 2000);
                                                }} />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        }
                    </Card.Body>
            </Card>
        </div>

        <CustomModal isShow={showResetModal} handleClose={handleCloseResetModal} header={<IconElement iconType={faExclamationCircle} headerClass="warning-icon ms-6" color="#DC6803" />} size="md">
            <ResetConfirmationForm />
        </CustomModal>
        </React.Fragment>
    );
}